import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue } from '../../_services/formateRevenueData';
import { useLocation } from 'react-router-dom';

const VideoRoyalty = ({ hideTotal }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const studioData = useSelector((state) => state.userData.userData);
    const videoData = useSelector((state) => state.royaltyDataReducer.videoData);
    const singlevideoData = useSelector((state) => state.royaltyDataReducer?.singlevideoData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = videoData?.total_count
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state

    useEffect(function () {
        if (isDetailsPage) {
            const monthName = tabDynamic?.name;
            let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
            dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage }, songId + '/video', `SINGLE_VIDEO`))
        } else {
            if (studioData.studio_id) {
                // if (videoData?.total_data?.length == 0 || videoData.length == 0) {
                dispatch(fetchRoyaltyDetail("videoData", { offset: page, limit: rowsPerPage }, studioData.studio_id, `VIDEO`))
                // }
            }
        }

    }, [studioData, dispatch, page, rowsPerPage]);

    const rows = (isDetailsPage ? singlevideoData?.counts_per_video : videoData?.total_data?.length ? videoData.total_data : []) || [];

    const columns = [
        { id: 'song_name', numeric: false, field: 'song_name', disablePadding: true, label: 'Video', },
        { id: 'total_stream_count', field: "total_stream_count", numeric: true, label: 'Streams' },
        { id: 'total_video_downloads', field: "total_video_downloads", numeric: true, label: 'Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'stream', numeric: true, label: videoData.length != 0 ? formatIndianNumber(videoData.overall_total_streams) : 0 },
        { id: 'download', numeric: true, label: videoData.length != 0 ? formatIndianNumber(videoData.overall_video_downloads) : 0 },
        { id: 'earning', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(videoData.overall_video_downloads_earnings + videoData.overall_streams_earning)}</Box> },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Video'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} widthSet={true} total_count={total_count} page={page} setPage={setPage} hideTotal={hideTotal} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default VideoRoyalty;
