import React from 'react'
import { Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import profileImage from '../../img/profileImage.png'
import { useHistory,useParams } from 'react-router-dom/cjs/react-router-dom'

const cardStyle = {
  display: 'flex',
  maxWidth: '18rem',
  maxHeight: '10rem',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
  borderRadius: '6px',
};

function ClickableCardLayout({cardData}) {
    let history = useHistory();
    const params= useParams();
    const assoId=params
    function handleClick(id) {
        history.push(`/associateArtistDetails/${id}`);
      }
    const artistName = cardData && cardData.artist_name&&cardData.artist_name.length > 15 ? `${cardData.artist_name.slice(0, 15)}...` : cardData.artist_name
  return (
    <div 
      onClick={() => handleClick(cardData.artist_code)}
      style={{cursor: 'pointer'}}>
      <Card variant="outlined" sx={cardStyle} >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            sx={{
              background: 'radial-gradient(104.04% 1616.8% at 0% 11.96%, #644343 0%, #675F86 100%)',
              height: '6rem',
              borderRadius: '6px 6px 0 0',
              justifyContent:'center'
            }}
          >
            <div className="p-0">
              <CardMedia
                component="img"
                sx={{ width: '6rem', height: '6rem', borderRadius: '50%' }}
                image={cardData.artist_avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + cardData.artist_avatar : profileImage}
              />
            </div>
          </Stack>
          <CardContent className='p-2' sx={{ flex: '1 0 auto' }}>
            <div>
              <Typography variant="body2" component="div" className="text-center" title={cardData.artist_name}>
                <strong>{artistName}</strong>
              </Typography>
            </div>
          </CardContent>
        </Box>
      </Card>
    </div>
  );
}

export default ClickableCardLayout