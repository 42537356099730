import React, { useState, useEffect } from 'react';
import { getExpertise } from '../../_services/profile/getExpertise.service';
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader, setSnackBar, setSnackBarMessage,setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import {  FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { fetchUserDetail } from '../../redux';
import useMediaQuery from '@mui/material/useMediaQuery';

function Expertise(props) {
    const userData = useSelector((state) => state.userData.userData);
    const [value, setValue] = React.useState('');
    const [expertise, setExpertise] = useState("");
    const dispatch = useDispatch();
    const mediumViewport = useMediaQuery('(min-width:768px)');

    useEffect(function () {
        userData && userData.expertiseLevel && userData.expertiseLevel !=null && setValue(userData.expertiseLevel.id)
        getExpertise().then(function (response) {
        dispatch(setLoader(false))
            setExpertise(response.data.data)
            
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, [userData.id]);

    function onChangeExpertiseHandler(e) {
        let currentSelectedItem = e.target.value
        setValue(e.target.value)
        var postData = {}
        postData['expertiseLevel']=currentSelectedItem
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(formSnackbar.expertiseLink.expertiseLinkUpdate))
        dispatch(setSnackBarVariant("info"))

        updateProfile(postData,userData.id).then(function (response) {
            dispatch(setSnackBarMessage(formSnackbar.expertiseLink.expertiseLinkUpdateSuccess))
            dispatch(setSnackBarVariant("success"))
            setTimeout(() =>  dispatch(setSnackBar(false)), 3000);
            dispatch(fetchUserDetail()) // to get the latest values every time
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
        })
    }

    return (
        <div>
            <h4 className='mb-4'>Expertise Level</h4>
                    <p> What expertise do you have in your category?</p>
                    <div className='pt-3 row px-2'>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={value}
                            onChange={onChangeExpertiseHandler}
                        >
                            {expertise ? expertise.map(item =>{
                                return(
                                <FormControlLabel  
                                sx={{border:'solid 1px #3336',borderRadius: 1, p: 1.5, width:mediumViewport?'30%':'100%',
                                    m: 1,}}s
                                value={item.id} control={<Radio />} label={item.expertiseLevel} />
                                )
                            }):''}
                        </RadioGroup>
                    </FormControl>
                    </div>
    </div>        
    );
}

export default Expertise;