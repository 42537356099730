import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function createData(name, details) {
    return { name, details };
}
const rows = [
    createData('Payout', 'Rs 30.55'),
    createData('Payout account', '**********88'),
    createData('Currency paid in', 'Rs 20'),
    createData('Requested on', 'January 8, 2024'),
    createData('Exchange rate', 3.9),
    createData('Paid on', 'January 8, 2024'),
    createData('Payout type', 'Bank Transfer'),
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function PayoutDetails({ handleClickOpen, handleClose, open }) {

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Payout ID: #292278
                </DialogTitle>
                {/* <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                > */}
                    <Button  sx={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                    }} className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-success text-white'>Paid</Button>
                {/* </IconButton> */}
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="customized table">
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.name} sx={{
                                        '&:nth-of-type(odd)': { backgroundColor: '#F6F5F5', fontWeight: 'bold' }, '& th, & td': { padding: '10px 10px' },
                                    }}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="left">{row.details}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}