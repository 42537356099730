import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxHeight: 100,
    lineHeight: '60px',
  }));

const FileUploader = ({progressCount, fileSize, fileName,removeFile,uploadProgressBytes,isFileUploaded}) => {
  return (
    <div>
        <Paper sx={{padding:'0.8rem',margin:'0.8rem 0'}}>
            <div className='d-flex' sx={{padding:'0.3rem'}}>
                <div style={{flexGrow:'1'}}>
                    <div className='d-flex'>
                        <div style={{minWidth:'50px'}}>
                            <IconButton aria-label="remove" size="large" sx={{backgroundColor:'#d1e3f5'}}>
                                <UploadFileIcon color="primary" sx={{fontSize:'1.5rem'}}/>
                            </IconButton>
                        </div>
                        <div style={{flexGrow:'1',padding:'0px 0.5rem'}}>
                            <h6 title={fileName}>{fileName && fileName.slice(0,80)}{fileName && fileName.length>80 && `...`}</h6>
                            <span sytle={{lineHeight:'1',fontSize:'0.6rem'}}>{(uploadProgressBytes / (1024 * 1024)).toFixed(2)}MB</span>/<span sytle={{lineHeight:'1',fontSize:'0.6rem'}}>{(fileSize / (1024 * 1024)).toFixed(2)}MB</span>
                            <LinearProgress variant="determinate" value={progressCount} /><span className='m-0'>{progressCount}%</span>
                        </div>
                    </div>
                </div>
                <div>
                   {/*  <IconButton aria-label="remove" size="large" sx={{margin:'0rem 1rem'}} onClick={removeFile}>
                        <CloseIcon color="secondary" sx={{fontSize:'1.5rem'}}/>
                    </IconButton> */}
                    {progressCount === 100 && isFileUploaded===true && <CheckCircleIcon color="success" sx={{fontSize:'2rem'}}/>}
                </div>
            </div>
        </Paper>

    </div>
  )
}

export default FileUploader