import { FETCH_STREAMING_PLATFORM_DATA_FAILURE,
    FETCH_STREAMING_PLATFORM_DATA_SUCCESS,
    FETCH_STREAMING_PLATFORM_DATA_REQUEST } from "./streamingPlatformTypes";
import { getStreamingPlatforms } from "../../_services/creater/streamingPlatform.service";

export function fetchStreamingPlatformList()
{
    return (dispatch)=>{
        fetchStreamingPlatformDataRequest()
        getStreamingPlatforms()
        .then((response)=>{
            const streamingPlatform = response?.data?.data;
            dispatch(fetchStreamingPlatformDataSuccess(streamingPlatform))
        })
        .catch(error => {
            dispatch(fetchStreamingPlatformDataFailure(error.message))
          })
    }
}

export function fetchStreamingPlatformDataRequest(){
    return{
        type:FETCH_STREAMING_PLATFORM_DATA_REQUEST
    }
} 

export function fetchStreamingPlatformDataSuccess(streamingPlatform){
    return{
        type:FETCH_STREAMING_PLATFORM_DATA_SUCCESS,
        payload:streamingPlatform
    }
}

export function fetchStreamingPlatformDataFailure(error){
    return{
        type:FETCH_STREAMING_PLATFORM_DATA_FAILURE,
        payload:error
    }
}  