import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, Paper, Rating } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ApiService from '../../../../_services/ApiService';
import Pagination from './Pagination';
import { default_limit } from '../../../../_services/_constants/recordLimitset.constant';
import { useState } from 'react';
import NoResults from '../../../../components/reusableComponents/NoResults';
import { getDateAsPerFormat } from '../../../../_helpers/reusablefunctions/dateSetter';
import { useSelector } from 'react-redux';
import SkeletonCard from '../../../../_helpers/reusablefunctions/SkeletonCard';

export default function Review() {
    const userData = useSelector((state) => state.userData.userData);
    const location = useLocation()
    const history = useHistory()
    const reviewViewDetail = location?.state || [];
    const reviewData = location?.state?.reviewViewDetail || [];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [totalCount, setTotalCount] = useState()
    const [reviewList, setReviewList] = useState([])
    const [loader, setLoader] = useState(true)
    const songPlaylistImg = reviewViewDetail.dialogValue == "Song" ? reviewData?.songPoster : reviewData?.playlist_cover_image;
    const songPlaylistName = reviewViewDetail.dialogValue == "Song" ? reviewData?.songTitle : reviewData?.playlist_name;

    function backtoprevious() {
        history.push(reviewViewDetail?.preUrl)
    }

    useEffect(() => {
        const apiName = reviewViewDetail.dialogValue == "Playlist" ? 'reviewPlaylistList' : 'reviewSongList';
        const songId = reviewViewDetail.dialogValue == "Playlist" ? reviewData?.playlist_id : reviewData?.id

        ApiService.get(apiName, { limit: 25, offset: page }, songId).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setReviewList(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(error => {
            console.log('error')
        })
    }, [])

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 25);
        const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Box className='container-fluid pt-2 pb-5 px-4 creator-playlist-panel'>
            <Button className="text-black text-capitalize fs-5" onClick={backtoprevious} startIcon={<KeyboardBackspaceIcon />}>
                Back
            </Button>
            <Paper>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={3} p={3}>
                        <Typography component='div' >
                            {songPlaylistImg ? (<img alt="songposter" style={{ height: '150px', width: '150px', borderRadius: '10px', border:'1px solid' }}
                                src={`${process.env.REACT_APP_BASEURL_MEDIA}/media/${songPlaylistImg}`} />)
                                : (
                                    <Box
                                        sx={{
                                            height: 150,
                                            width: 150,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#f0f0f0',
                                        }}
                                    >
                                        <Typography variant="h4" className='text-capitalize'>
                                            {songPlaylistName?.charAt(0)}
                                        </Typography>
                                    </Box>
                                )}
                            <Typography variant="h6" component="h6" >{songPlaylistName || '--'}</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={9} p={3}>
                        <Box className='d-flex justify-content-center flex-column'>
                            <Box className='w-50'>
                                <Typography variant="h6" component="h6">
                                    Reviews
                                </Typography>
                                {loader ?
                                    <Box className='Btn'>
                                        {Array.from(new Array(8)).map((item, index) => (
                                            <SkeletonCard />
                                        ))}
                                    </Box>
                                    :
                                    loader === false && reviewList.length === 0 ? (
                                        <NoResults />
                                    ):reviewList.map(data => (
                                            <Box key={data.id} className='my-3'>
                                                <Box>
                                                    <Box className='d-flex gap-2 align-items-center mb-2' >
                                                        <Avatar
                                                            alt={data.user_name}
                                                            src={process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data.user_avatar}
                                                            sx={{ width: 30, height: 30 }}
                                                        />
                                                        <Typography>
                                                            {data.user_name}
                                                        </Typography>
                                                        <Rating
                                                            name="simple-controlled"
                                                            value={data.rating}
                                                            size="large"
                                                            sx={{
                                                                color: 'rgba(237, 28, 36, 1)',
                                                                '& .MuiRating-iconFilled': {
                                                                    fontSize: '1.5rem',
                                                                },
                                                                '& .MuiRating-iconEmpty': {
                                                                    fontSize: '1.5rem',
                                                                },
                                                            }}
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }} component="div">
                                                        Reviewed:- {getDateAsPerFormat(data.created_at, "dd/MM/yyyy")}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body2" className='wordBreak' sx={{ margin: '7px 0px' }}>
                                                    {data.feedback}
                                                </Typography>
                                                {/* <Divider /> */}
                                            </Box>
                                        ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>


        </Box>
    );
}
