import {
  SET_SNACKBAR,
  SET_SNACKBAR_MESSAGE,
  SET_SNACKBAR_VARIANT
} from './snackBarTypes'


export const setSnackBar = toggle => {
  console.debug("snackbar action: toggle",toggle)
  return {
    type: SET_SNACKBAR,
    payload: toggle
  }
}
export const setSnackBarMessage = data => {
  console.debug("snackbar action: data",data)
  return {
    type: SET_SNACKBAR_MESSAGE,
    payload: data
  }
}

export const setSnackBarVariant = data => {
  return {
    type: SET_SNACKBAR_VARIANT,
    payload: data
  }
}
