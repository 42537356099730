import React from 'react';
import { Box, InputAdornment, TextField, Modal, useMediaQuery,Autocomplete, Typography, Divider} from '@mui/material';

function AddStudioModal({open,setIsDropdownOpen,filteredOptions,isDropdownOpen,handleSearch,searchValue, handleClose,createConnection,requestUIDChangeHandler,requestUID,requestUIDError,requestUIDHelperText }) {
  const mediumViewport = useMediaQuery('(max-width:720px)');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: mediumViewport ? '95%' : '50%',
          bgcolor: 'background.paper',
          border: '0px solid #000',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
          maxWidth: 600,
          zIndex: 2,
        }}
      >
        <section className="header mb-4">
          <h4 style={{ fontWeight: '900' }}>Add Studio</h4>
          <p>To add studio, search by Studio Name, Person Name, or GNID.</p>
          </section>
        <section className='mb-4'>
          <Autocomplete
              value={requestUID}
              options={filteredOptions}
              onChange={requestUIDChangeHandler}
              autoHighlight
              placeholder='Search the Studio...'
              getOptionLabel={(option) => `${option.studio_name} ${option.name} (GNID:${option.studio_gnid})`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2} }} {...props}>  
                  <li>{option.studio_name}</li>
                  <li style={{fontSize:'0.8rem'}}>{option.name && `(${option.name})`} (GNID:{option.studio_gnid})</li>
                </Box>
              )}
              freeSolo 
              inputValue={searchValue}
              onInputChange={(event, newInputValue) => {
                handleSearch(newInputValue);
              }}
              open={isDropdownOpen}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  placeholder='Search Studios...'
                  variant="outlined"
                  fullWidth
                  error={requestUIDError}
                  helperText={requestUIDHelperText}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                   
                  }}
              
                />
              )}
            />
          </section>
      
        <section className="footer d-flex justify-content-end mt-4">
          <button className="gn-actionbtn outlined dark me-3" onClick={handleClose}>
            Cancel
          </button>
          <button disabled={requestUIDError || requestUID === null} rea-label="Submit" title="Submit" className="gn-actionbtn" onClick={createConnection}>
            Submit
          </button>
        </section>
      </Box>
    </Modal>
  );
}

export default AddStudioModal;
