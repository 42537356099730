import React,{useState} from 'react'
import { Box, FormControl, IconButton, Stack, TextField, ToggleButton } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
const SwitcherBtn = ({layoutComponent,handleLayoutComponent}) => {  
  const Icon = !layoutComponent ?  <FormatListBulletedIcon fontSize="small" sx={{color:'#000000'}}/> : <WindowIcon fontSize="small" sx={{color:'#000000'}}/> 
  return (
    <div>
        <ToggleButton onClick={()=>{handleLayoutComponent(!layoutComponent)}} value={layoutComponent} variant="outlined" className="switcherButton" size="small" aria-label='switchView' sx={{minWidth:'2.625rem',height:'40px'}}>
            {Icon}
        </ToggleButton>
    </div>
  )
}

export default SwitcherBtn