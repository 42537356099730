import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import artist from '../../img/artist.png'
import defaultImage from '../../img/default image.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useHistory, useParams } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import {
  createTheme,
  ThemeProvider,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import AudioPlayer from './customAudioPlayer';
export default function ResponsiveCardList({cardData,moduleName}) {
    const history = useHistory()
    const showInListView = (module)=>{
        switch(module)
        {
          case 'songList':
            {
              return (
                <div className='my-3 responsive-list' onClick={()=>history.push(`/releases/${cardData.song_id}`)} style={{cursor:'pointer'}}>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                  <div className='d-flex w-100'>
                      <div style={{padding:'0.5rem'}}>        
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        image={cardData?`${process.env.REACT_APP_SONGAPIURL_MEDIA}${cardData.resized_covers.thumbnail100x100}`:artist}
                        onError={(e) => {
                          e.target.src = defaultImage; 
                        }}
                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between'>
                            <strong className='cardheadingFont overflow-text' title={cardData.song_name}>{cardData.song_name}</strong>                
                            <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</div>
                        </div>
                        <div className='cardheadingFont text-secondary cardLine overflow-text' title={cardData.album}>{cardData.album}</div>
                        <div className='cardfont'>{cardData.content_types}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{cardData.genre}</div>
                            <div className='cardfont '>{getDateAsPerFormat(cardData.release_date,"DD Mon, YYYY")}</div>
                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              )
            }
          default:{
            return;
          }
        }
    }
    return (
      showInListView(moduleName)
    )
}