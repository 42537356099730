import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {AlbumEndpoint} from '../../_services/_constants/api.constant'

export async function readyForRelease(dataObj) {
    const response = await axios.post(
        AlbumEndpoint.READY_FOR_RELEASE,dataObj,
        {   
            // headers: {
            //     'Authorization': 'Token ' + getSelfToken(),
            // },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}