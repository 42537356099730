import React, { useState, useEffect } from 'react';
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { getGenre } from '../../_services/profile/getGenre.service'
import { updateGenre } from '../../_services/profile/updateGenre.service'
import { useSelector, useDispatch } from 'react-redux'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant  } from '../../redux';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import Snackbar from '@mui/material/Snackbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Genre(props) {
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);
    const [userGenre, setUserGenre] = useState()
    const [openText, setOpenText] = useState(false)
    const [genre, setGenre] = useState([])
    const dispatch = useDispatch();
    function setOpenTextArea() {
        setOpenText(!openText)
    }

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;

    useEffect(function () {
        setUserGenre(userDetail ? userDetail.genres : "")
    }, [userDetail]);

    useEffect(function () {
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
        }
    }, [userData.id]);
    useEffect(function () {
        // dispatch(fetchUserDetail());
        //dispatch(setLoader(true))
        getGenre().then(function (response) {
            dispatch(setLoader(false))
            setGenre(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, []);

    function UpdateGenreCategory(postData) {
        dispatch(setLoader(true))
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBarMessage(formSnackbar.PRE_GENRE_UPDATE))
        dispatch(setSnackBar(true))
        updateGenre(postData).then(function (response) {
            if(response.status === 200){
                dispatch(setSnackBarVariant('success'))
                dispatch(setSnackBarMessage(formSnackbar.POST_GENRE_UPDATE))
                setTimeout(() =>  dispatch(setSnackBar(false)), 2000);
                dispatch(setLoader(false))
            }
            else{
                dispatch(setLoader(false))
                dispatch(setSnackBarVariant('error'))
                dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
            }
        })/* .catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
        }) */
    }

    function onChangeGenreHandler(e, data) {
        if (userGenre.find(d => e.target.id == d.id)) {
            let currentSelected = userGenre.filter(r => r.id != e.target.id);
            setUserGenre([...currentSelected])
            let selectedIds = currentSelected?currentSelected.length>0?currentSelected.map(obj=>obj.id):[]:[] 
            var postData = {}
            postData['user'] = userData.id
            postData['genre'] = selectedIds.join(',');
            UpdateGenreCategory(postData)
        } else {
            setUserGenre([...userGenre, data])
            let selectedIds = [];
            selectedIds = userGenre?userGenre.length>0?userGenre.map(obj=>obj.id):[]:[]
            selectedIds  = [...selectedIds,data.id]
            var postData = {}
            postData['user'] = userData.id
            postData['genre'] = selectedIds.join(',');
            UpdateGenreCategory(postData)
        }
     
    }
    return (
        <div>
            <h4 className='mb-2'>Genre</h4>
            <div className='text-secondary'>
                    <p>We offer a range of services for musicians, singers, and other audio professionals</p>
                </div>
            {/* <div className="card account-bg">
                <div className="card-body ">
                    <p>What are the type of genre you are into?</p> */}
                    <div className='py-1 row px-4'>
                        {genre ? genre.map(d => (
                            <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id} >
                                <div className="form-check-inline d-inline-flex">
                                <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={(e) => onChangeGenreHandler(e, d)}
                                                id={d.id}
                                                name={d.id}
                                                checked={userGenre ? userGenre.filter(v => v.id == d.id).length ? true : false : false}
                                            />}
                                            label={d.genre} />
                                    </FormGroup>
                                    {/* <input className="form-check-input fs-3 profileCheckbox "
                                        // checked={userData.genre ?userData.genre.filter(v => v.id == d.id).length ? true : false:false}
                                        checked={userDetail.category ? userDetail.category.filter(v => v.id == d.id).length ? true : false : false}

                                        onChange={onChangeGenreHandler}
                                        type="checkbox" id={d.id} value={d.id} />
                                    <label className="form-check-label p-2 overflow-hidden text-truncate" htmlFor={d.id}>{d.genre}</label> */}
                                </div>
                            </div>
                        )) : []}
                    </div>

                {/* </div>
            </div> */}
        </div>
    );
}

export default Genre;