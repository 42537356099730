import axios from "axios";
import {userInfoApi} from '../_constants/api.constant'
import {getSelfToken, getUserId, removeUserToken} from '../../_helpers/auth/secureToken'
export async function getLoginUser(id){
    const response=await axios.get(
     userInfoApi.VIEW+'?id='+id,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     
        if(error.response.status === 401)
        {   window.location.href='/'
            removeUserToken();
        }
/*         console.log(error);
        console.log(error.response); */
        return error.response;
        });
    return response;
}