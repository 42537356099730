import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useLocation, useHistory } from 'react-router-dom';
import TableComponent from './Tablecomponent';
import { trackDynamic } from './TabConstData';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue } from '../../_services/formateRevenueData';
const Track = ({ hideTotal }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const studioData = useSelector((state) => state.userData.userData);
    const trackData = useSelector((state) => state.royaltyDataReducer ?state.royaltyDataReducer.trackData:[]);
    const singleTrackData = useSelector((state) => state.royaltyDataReducer?.singletrackData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = trackData?.total_count

    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state
    const navigate = useHistory();

    const redirectDetails = (data) => {
        navigate.push('/details', { state: { data: trackDynamic, value: [data], pageCall: 'singleDetails', name: data?.song_name, id: data?.song_id } });
    }

    useEffect(function () {
        if (isDetailsPage) {
            // if (singleTrackData?.counts_per_album?.length == 0 || singleTrackData.length == 0) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall,{offset: page, limit: rowsPerPage}, songId + '/song', `SINGLE_TRACK`))
            // }
        } else {
            if (studioData.studio_id) {
                
                // if (trackData?.total_data?.length == 0 || trackData.length == 0) {
                // dispatch(fetchRoyaltyDetail("trackData", { offset: page, limit: rowsPerPage }, studioData.studio_id, `TRACK`))
                dispatch(fetchRoyaltyDetail("trackData", { offset: page, limit: rowsPerPage}, studioData.studio_id, `TRACK`))

                // }
            }
        }

    }, [studioData, dispatch, page,rowsPerPage]);


    const rows = (isDetailsPage ? singleTrackData?.counts_per_song : trackData.total_data?.length ? trackData.total_data : []) || [];


    const columns = [
        { id: 'song_name', field: "song_name", numeric: false, label: 'Track' },
        { id: 'total_stream_count', field: "total_stream_count", numeric: true, label: 'Streams' },
        { id: 'total_track_downloads', field: "total_track_downloads", numeric: true, label: 'Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_track_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }

        },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'stream', numeric: true, label: trackData.length != 0 ? formatIndianNumber(trackData.overall_total_streams) : 0 },
        { id: 'download', numeric: true, label: trackData.length != 0 ? formatIndianNumber(trackData.overall_track_downloads) : 0 },
        { id: 'earning', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(trackData.overall_track_downloads_earnings + trackData.overall_streams_earning)}</Box> },
        { id: 'action', numeric: false, label: '' }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Track'} />
            <TableComponent columns={columns} data={rows} total_count={total_count} headFooter={headFooter} hideTotal={hideTotal} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default Track;
