import React from 'react'

function Appearance() {
  return (
    <div className='p-4'>
    <h1>Appearance</h1>
    </div>
  )
}

export default Appearance