import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { updateSequenceLink } from "../_constants/api.constant";
export async function updateSequence(id,postData){
    const response=await axios.put(
        updateSequenceLink.PUT+id+'/',
       postData,
        {
            headers:{
                'Authorization': 'Token ' + getSelfToken(),

            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}