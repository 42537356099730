import React, { useEffect, useState } from 'react';
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import Footer from './footer';
import Box from "@mui/material/Box";
import { useDispatch } from 'react-redux'
import { setLoader } from '../redux';
import Tooltip from '@mui/material/Tooltip';
import Modal from "@mui/material/Modal";
import { Autocomplete, Avatar, Button, IconButton, TextField } from '@mui/material';
import backgroundImage from '../img/bg_image_texture3.png'
import { connectWithSpotify, searchArtistOnSpotify } from '../_services/connectwithSpotify';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
const CopyTooltip = styled(Tooltip)(({ theme }) => ({
    cursor: 'pointer',
  }));
  
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: 3,
    boxShadow: 24,
    p: 3
};
function ConnectWithSpotify(props) {
    const [claimProfileLink, setClaimProfileLink] = useState(process.env.REACT_APP_CREATOR_PLATFORM_URL+ '/claim-your-profile')
    const [profileUrl, setProfileLink] = useState('')
    const [gnid, setGnid] = useState('')
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const dispatch = useDispatch();
    const LogoApp = process.env.REACT_APP_APPURL == 'https://creator.groovenexus.com' ? logoCreators : logoGntunes
    const [inputValue, setInputValue] = useState('');
    const [artistList, setArtistList] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [errorResponse, setErrorResponse] = useState('')

    useEffect(() => {
        if(selectedArtist != null){
            handleSelection() 
        }
    }, [selectedArtist != null])


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setInputValue('')
        setSelectedArtist(null)
    }
    const handleCloseError = () => {
        setOpenError(false)
    }

    const handleInputChange = (event, value) => {
        setInputValue(value);
        if (value) {
            // Fetch data from API based on input value
            searchArtistOnSpotify(value)
                .then(response => {
                    console.debug('Search Response:', response); // Log response for debugging
                    if (response.data.data.artists && Array.isArray(response.data.data.artists)) {
                        setArtistList(response.data.data.artists);
                    } else {
                        console.error('Invalid response data structure:', response);
                        // Handle invalid response data structure
                    }
                })
                .catch(error => {
                    console.error('Error fetching artist data:', error); // Log error for debugging
                    // Handle error
                });
        } else {
            setArtistList([]); // Clear options if input is empty
        }
    };


    const handleSelection = () => {
        // Make POST request to another API with selected artist data
        console.debug("handleSelection:selctedArtist", selectedArtist)
        if (selectedArtist) {
            let data = new FormData()
            data.append('artist_id',selectedArtist.artist_id);
            dispatch(setLoader(true))
            connectWithSpotify(data).then(function (response) {
                if (response.status == 200) {
                    console.debug("response", response)
                    // console.debug("connectWithSpotify:connectWithSpotify", response)

                    let r = response.data.data
                    if(r.status == 500){ 
                        setOpenError(true)
                        // console.debug("response", response)
                        setErrorResponse(r.error)
                        setSelectedArtist(null)
                    }else{ 
                    setProfileLink(process.env.REACT_APP_CREATOR_PLATFORM_URL+ r.userHandle +"/music")
                    // setProfileLink(`https://gntunes.com/${r.userHandle}/music`)
                    setGnid(r.creator_gnid)
                    handleOpen()
                    }
                } else {
                    setOpenError(true)
                        console.debug("response", response)
                        setErrorResponse("Something went wrong try after some time!!")
                        setSelectedArtist(null)
                }
                dispatch(setLoader(false))
            }).catch(function (error) {
                dispatch(setLoader(false))
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        }
    };

    const [tooltipText, setTooltipText] = useState('Click to copy');

    const handleCopy = () => {
      navigator.clipboard.writeText(profileUrl)
        .then(() => {
          setTooltipText('Copied!');
          setTimeout(() => setTooltipText('Click to copy'), 2000); // Reset tooltip text after 2 seconds
        })
        .catch((err) => console.error('Failed to copy: ', err));
    };


    return (
        <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand p-3" href="https://gntunes.com/"><img src={LogoApp} /></a>
                    </div>
                </nav>
                <div className="text-center mt-4">
                    <h1>Connect With Audio Platform</h1>
                </div>
                <div style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
                    <div className="login-form  mt-4 mb-4 p-4 border rounded shadow bg-white">
                        <form action="" method="" className="row g-3">
                            <h6 className="  text-secondary mx-2">
                                Enter artist name to connect with gntunes
                            </h6>
                            <div>
                                <Autocomplete
                                    id="country-select-demo"
                                    onInputChange={handleInputChange}
                                    size='small'
                                    options={artistList}
                                    value={selectedArtist}
                                    autoHighlight
                                    getOptionLabel={(option) => option.artist_name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                // loading="lazy"
                                                width="55"
                                                className='rounded-circle'
                                                // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={option.artist_image}
                                                alt=""
                                            />
                                            {option.artist_name}
                                        </Box>
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedArtist(newValue ? newValue : "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enter artist name"
                                            inputProps={{
                                                ...params.inputProps,

                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className='text-secondary'>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: 'text.primary',
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <h6>Your profile has been successfully created with us! </h6>
                                    <p>Save these Credentials for Profile Claim</p>
                                    <p>This is your GNID : <strong>{gnid}</strong>.</p>
                                 
                                    <p>This is your Profile link :  <CopyTooltip title={tooltipText} placement="top" onClick={handleCopy}><strong style={{color:'#2979BC'}}>{profileUrl}</strong></CopyTooltip></p>
                                    <p>If you want to claim your profile, click on the link below:<a target="_blank" rel="noopener noreferrer" href={claimProfileLink}><strong>Claim Profile</strong></a></p>
                                </Box>
                            </Modal>

                            <Modal
                                open={openError}
                                onClose={handleCloseError}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className='text-secondary'>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleCloseError}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: 'text.primary',
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <h6 style={{ color: "red" }}>{errorResponse}</h6>
                                </Box>
                            </Modal>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default ConnectWithSpotify;