
import {
  SET_LOADER
} from './loaderTypes'
const initialState = {
  loader: false,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case   SET_LOADER:
        return {
          ...state,
          loader: action.payload,
        }
        default: return state
      }
  }


export default reducer
