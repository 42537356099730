import * as React from 'react';
import { useState, useEffect } from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import { getLanguage, getCountry, getState, getCity } from '../../_services/profile'
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useSelector, useDispatch } from 'react-redux'
import { updateLanguage } from "../../_services/profile/updateLanguage.service"
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { createAddress } from "../../_services/profile/createAddress.service"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

function Region(props) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const [address, setAddress] = useState(userData ? userData.address : "");
    const [cityList, setCityList] = useState("");
    const [zipCode, setZipCode] = useState(userData ? userData.zipCode : "");
    const [stateList, setStateList] = useState("");
    const [countryList, setCountryList] = useState("");
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    const [addressError, setAddressError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [zipCodeError, setZipCodeError] = useState(false)
    const [stateError, setStateError] = useState(false)
    const [countryError, setCountryError] = useState(false)


    useEffect(function () {
        if (userData.id) {
            setAddress(userData.address)
            setZipCode(userData.zipcode)
            userData.city ? setCityId(userData.city) : setCityId(null)
            userData.state ? setStateId(userData.state.id) : setStateId(null)
            userData.state && getAllState()
            userData.country ? setCountryId(userData.country.id) : setCountryId(null)
        }
    }, [userData.id]);

    useEffect(function () {
        getCity().then(function (response) {
            setCityList(response.data ? response.data.data : "")

        }).catch(function (error) {
            console.log(error);
            return error.response;
        })
        getCountry().then(function (response) {
            setCountryList(response.data ? response.data.data : "")
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            return error.response;
        })

    }, [userData.id]);

    function getAllState(country) {
        getState(country)
            .then(function (response) {
                setStateList(response.data ? response.data.data : "")
            })
            .catch(function (error) {
                console.log(error); return error.response;
            })
    }
    function handleCountryChange(e) {
        const country = e.target.value;
        setCountryId(country);
        let cd = countryList && countryList.length > 0 && countryList.find(item => item.id === country)
        getAllState(cd.country_code);
    }

    function handleStateChange(e) {
        const state = e.target.value;
        setStateId(state);
    }
    /* Form validation handler */
    const validateForm = () => {
        if (address.trim() === "" || address.trim() == null) {
            setAddressError(true)
            return false;
        }
        else if (cityId === '' || cityId === null) {
            setCityError(true)
            return false
        }
        else if (zipCode === "" || zipCode === null || zipCode <= 0) {
            setZipCodeError(true)
            return false;
        }
        else if (stateId === '' || stateId === null) {
            setStateError(true)
            return false
        }
        else if (countryId === '' || countryId === null) {
            setCountryError(true)
            return false
        }
        else
            return true
    }

    function onSave(e) {
        e.preventDefault();
        //validate input fields before apending the data
        let isValid = validateForm();
        if (isValid) {
            var data = new FormData();
            if (cityId) {
                data.append("city", cityId)
            }
            if (address) {
                data.append("address", address)
            }
            if (zipCode) {
                data.append("zipcode", zipCode)
            }
            if (stateId) {
                data.append("state", stateId)
            }
            if (countryId) {
                data.append("country", countryId)
            }

            dispatch(setSnackBar(true))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
            dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdate))
            dispatch(setSnackBarVariant("info"))
            createAddress(data, userData.id).then(function (response) {
                if (response.status === 200) {
                    dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdateSuccess))
                    dispatch(setSnackBarVariant("success"))
                    setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
                    dispatch(fetchUserDetail(userData))
                }
                else {
                    dispatch(setSnackBarVariant('error'))
                    dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                    setTimeout(() => { dispatch(setSnackBar(false)) }, 3000)
                }
            }).catch(function (error) {
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        } //Is valid end
    }

    return (
        <div className="py-2">
            <h4 className='mb-2'>Region</h4>
            {/* <div className="card h-auto account-bg"> */}
            {/* <div className="card-body"> */}
            <div className='text-secondary'>
                <p>Fill your address detail</p>
            </div>
            {/* <div className = "mb-3" style={{border:'solid 1px #ced4da', borderRadius:'.375rem'}}> */}

            {/*latest change as per the requirement*/}
            {/* <Autocomplete
                                multiple
                                id="size-small-outlined-multi"
                                size="small"
                                options={languageList.map((option) => {
                                    return option.language
                                })}
                                value={language}
                                onChange={handleLanguageChange}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select your languages" />
                                )}
                                placeholder ='none'
                            />  */}
            {/* </div> */}
            <div>
                {/* <h6 className='mb-2'>Region</h6> */}
                <form onSubmit={onSave}>
                    <div className="row">
                        <div className="form-group mt-3 mb-3">
                            <FormControl
                                name='ADDRESS'
                                noValidate
                                autoComplete="off"
                                fullWidth
                            >
                                <TextField
                                    variant="outlined"
                                    label="Enter Address*"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value) ? setAddressError(true) : setAddressError(false)}
                                    name="address"
                                    size="small"
                                    type="text"
                                    error={addressError}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="form-group mt-3 mb-3">
                            <FormControl fullWidth /* name ="CITY"  error={cityError} size="small" */>
                                {/* <InputLabel id="demo-select-small">City*</InputLabel>
                                                <Select
                                                    value={cityId?cityId:'null'}
                                                    name="cityId"
                                                    label="City"
                                                    sx={{width:'100%'}} 
                                                    onChange={(e) => setCityId(e.target.value)?setCityError(true):setCityError(false)}
                                                    MenuProps={{
                                                        style: {
                                                           maxHeight: 250,
                                                              },
                                                        }}
                                                >
                                                {cityList ? cityList.map(data => (
                                                    <MenuItem value={data.id}>{data.cityName}</MenuItem>
                                                    )) : <MenuItem value='null'></MenuItem>}
                                                </Select> */}
                                <TextField
                                    variant="outlined"
                                    label="City*"
                                    value={cityId}
                                    onChange={(e) => setCityId(e.target.value) ? setCityError(true) : setCityError(false)}
                                    name="cityId"
                                    size="small"
                                    type="text"
                                    error={cityError}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </div>

                        <div className="form-group mt-3 mb-3">
                            <FormControl
                                name="ZIPCODE"
                                error={zipCodeError}
                                // autoComplete="off"
                                fullWidth
                                size='small'
                            >
                                <TextField
                                    type="number"
                                    value={zipCode}
                                    id="outlined-basic"
                                    onChange={(e) => { e.preventDefault(); setZipCode(e.target.value) ? setZipCodeError(true) : setZipCodeError(false); }}
                                    name="zipCode"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        classes: {
                                            notranslate: 'hide-div', // Add class to hide the div
                                        },
                                    }}
                                    label="Enter Zip Code*" variant="outlined" size='small' />
                            </FormControl>
                        </div>

                        <div className="form-group  mt-3 mb-3">
                            <FormControl fullWidth size='small' error={countryError}>
                                <InputLabel >Country*</InputLabel>
                                <Select
                                    value={countryId ? countryId : 'null'}
                                    label="Country"
                                    onChange={handleCountryChange}
                                    //onChange={(e) => setCountryId(e.target.value)?setCountryError(true):setCountryError(false)}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 250,
                                        },
                                    }}
                                >
                                    {countryList ? countryList.map(data => (
                                        <MenuItem value={data.id}>{data.country_name}</MenuItem>
                                    )) : <MenuItem value='null'></MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="form-group mt-3 mb-3">
                            <FormControl fullWidth name="STATE" error={stateError} size="small" >
                                <InputLabel>State*</InputLabel>
                                <Select
                                    value={stateId ? stateId : 'null'}
                                    name="stateId"
                                    label="State"
                                    placeholder="Please select..."
                                    onChange={handleStateChange}
                                    //onChange={(e) => setStateId(e.target.value)?setStateError(true):setStateError(false)}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 250,
                                        },
                                    }}
                                >
                                    {stateList ? stateList.map(data => (
                                        <MenuItem value={data.id}>{data.state_name}</MenuItem>
                                    )) : <MenuItem value='null'></MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="form-group col-md-12 text-end mt-3 mb-3">
                            <button type="submit" title="Save Region" disabled={(address != '' && cityId != null && cityId != '' && zipCode != '' && zipCode != null && stateId != null && countryId != null) ? false : true} className='gn-actionbtn'>Save</button>
                        </div>
                    </div> {/* Row ends here */}
                </form>
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
}

export default Region;