import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Card, CardContent, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, fetchSongReleaseList, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import SnackbarContainer from '../Snackbar';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { default_limit, record_limits } from '../../_services/_constants/recordLimitset.constant';
import { useHistory, useParams } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'song_name',
    label: 'Song Name',
    width: 250,
  },
  {
    id: 'album',
    label: 'Album/File',
    width: 250,
  },
  {
    id: 'genre',
    label: 'Genre',
    width: 250,
  },
  {
    id: 'mood',
    label: 'Mood',
    width: 250,
  },
  {
    id: 'release_date',
    label: 'Release Date',
    width: 250,
  },
  {
    id: 'song_status',
    label: 'Status',
    width: 250,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            onClick={createSortHandler(headCell.id)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default function SongTable({ songListData,handleChangePage,handleChangeRowsPerPage,page,rowsPerPage }) {
  const history = useHistory()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('song_name');
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [rows, setRows] = useState(songListData && songListData.results?songListData.results:[]);
  const [totalCount, setTotalCount] = useState(songListData?songListData.total_count:0)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const removeData = () => {
    if (selected.length !== undefined && selected.length === 1) {
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.submitAsong.SongInfo));
      dispatch(setSnackBar(true));

      const itemId = selected;
      submitSong({ itemId: itemId }, 'DELETE')
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.submitAsong.SongInfoSuccess));
            dispatch(setLoader(false));
            setSelected([]);

            if (userData.id) {
              dispatch(fetchLoginUser(userData.id));
              dispatch(fetchSongReleaseList());
            }
          } else {
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            dispatch(setSnackBarVariant('error'));
          }
        })
        .catch((error) => {
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          return error.response;
        });
    }
  };

/*   useEffect(() => {
    if (userData.id) {
      dispatch(fetchLoginUser(userData.id));
      dispatch(fetchSongReleaseList());
    }
  }, [userData.id, dispatch]); */

  useEffect(() => {
    if (songListData && songListData.results && songListData.results.length > 0) {
      setRows(songListData.results);
      setTotalCount(songListData.total_count)
    }
  }, [songListData]);

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row">
        <Stack style={{ height: '100%' }}>
          <Card sx={{ minWidth: '100%', height: 'auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.18)', borderRadius: '6px' }}>
            <CardContent sx={{ padding: '0px !important' }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                     
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              <div className="d-flex align-items-center">
                                <div>
                                  <img
                                    className="rounded"
                                    src={process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + row.cover_image}
                                    style={{ width: '40px', height: '40px' }}
                                    alt=""
                                  />
                                </div>
                                <div style={{ width: '10px' }}></div>
                                <div>
                                  <span style={{cursor:'pointer'}} onClick={()=>history.push(`/releases/${row.song_id}`)}>{row.song_name}</span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left">{row.album}</TableCell>
                            <TableCell align="left">{row.genre}</TableCell>
                            <TableCell align="left">{row.mood}</TableCell>
                            <TableCell align="left">{getDateAsPerFormat(row.release_date, 'DD Mon, YYYY')}</TableCell>
                            <TableCell align="left">
                              <div className="status text-light" style={{ background: getSongStatusBgColor(row.song_status), minWidth: '80px' }}>
                                {row.song_status}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={record_limits}
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
              />
            </CardContent>
          </Card>
        </Stack>
      </div>
      <SnackbarContainer />
    </div>
  );
}

SongTable.propTypes = {
  songListData: PropTypes.array.isRequired,
};
