import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {playistEndpoint} from '../_constants/api.constant'
export async function getSinglePlaylist(paramsObj) {
    const response = await axios.get(
        playistEndpoint.GET_SINGLE_PLAYLIST,
        {   params:paramsObj,
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}