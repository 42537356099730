import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { streamingPlatformURL } from '../_constants/api.constant'
export async function getStreamingPlatforms(postData){
    const response=await axios.get(
      streamingPlatformURL.GET,//" https://content_creater_gnapi.eela.tech/api/v1/link",
        {
            // params:prarmTempObj,
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}