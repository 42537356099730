import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Link, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PayoutDetails from './modals/PayoutDetails';
import TableComponent from './Tablecomponent';
import Paper from '@mui/material/Paper';
import Search from './Search';

export default function Payout() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const rows = [
        { id: 1, payouttype: '20.88', name: 'Bank Transfer', requestedon: <div className='d-flex gap-2 align-items-end'><DateRangeIcon sx={{ opacity: '50%', fontSize: '22px' }} />January 8, 2024</div>, status: <Button className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-success text-white'>Paid</Button>, action: <Link onClick={handleClickOpen} className='text-decoration-none cursorPointer'><MoreVertIcon /></Link> },
        { id: 2, payouttype: '20.88', name: 'Bank Transfer', requestedon: <div className='d-flex gap-2 align-items-end'><DateRangeIcon sx={{ opacity: '50%', fontSize: '22px' }} />January 8, 2024</div>, status: <Button className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-success text-white'>Paid</Button>, action: <Link onClick={handleClickOpen} className='text-decoration-none cursorPointer'><MoreVertIcon /></Link> },
        { id: 3, payouttype: '20.88', name: 'Bank Transfer', requestedon: <div className='d-flex gap-2 align-items-end'><DateRangeIcon sx={{ opacity: '50%', fontSize: '22px' }} />January 8, 2024</div>, status: <Button className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-success text-white'>Paid</Button>, action: <Link onClick={handleClickOpen} className='text-decoration-none cursorPointer'><MoreVertIcon /></Link> },
        { id: 4, payouttype: '20.88', name: 'Bank Transfer', requestedon: <div className='d-flex gap-2 align-items-end'><DateRangeIcon sx={{ opacity: '50%', fontSize: '22px' }} />January 8, 2024</div>, status: <Button className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-danger text-white'>Failed</Button>, action: <Link onClick={handleClickOpen} className='text-decoration-none cursorPointer'><MoreVertIcon /></Link> },
        { id: 5, payouttype: '20.88', name: 'Bank Transfer', requestedon: <div className='d-flex gap-2 align-items-end'><DateRangeIcon sx={{ opacity: '50%', fontSize: '22px' }} />January 8, 2024</div>, status: <Button className='border rounded-pill text-capitalize ps-4 pe-4 pb-1 pt-1 bg-success text-white'>Paid</Button>, action: <Link onClick={handleClickOpen} className='text-decoration-none cursorPointer'><MoreVertIcon /></Link> },
    ];

    const columns = [
        { id: 'name', numeric: false, field: "name", disablePadding: true, label: 'Payout', },
        { id: 'requestedon', numeric: true, field: "requestedon", disablePadding: false, label: 'Payout Type', },
        { id: 'payouttype', numeric: true, field: "payouttype", disablePadding: false, label: 'Requested On', },
        { id: 'status', numeric: true, field: "status", disablePadding: false, label: 'Status', },
        { id: 'action', numeric: true, field: "action", disablePadding: false, label: 'Action', }
    ];

    return (
        <Box className='container mt-3  mb-5 pb-3 px-4'>
            {/* <Box className="bg-white shadow-lg rounded mt-3 p-4 mb-5"> */}
            <Typography variant='h6' >{'Payout'}</Typography>
            <Typography variant='span' sx={{ opacity: '70%', fontSize: '15px' }}>
                Here you'll find detailed reports on the royalties your music has earned. Some stores report sales to us with up to a 3 month delay, so you might not see recent earnings reflected in these reports straight away.
            </Typography>
            <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
                <Search headValue={'Your History'} />
                <PayoutDetails open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
                <TableComponent columns={columns} data={rows} hideTotal={true} />
            </Paper>
        </Box>
    );
}