import axios from "axios";
import { metapixelEndpoint } from "../../_constants/api.constant";
import { getSelfToken } from "../../../_helpers/auth/secureToken";
export async function updateMetapixel(id, postData) {
    const response = await axios.put(
        metapixelEndpoint.PUT,
        postData,
        {
            params:{
                id:id
            },
            headers: {
                'Authorization': 'Token ' + getSelfToken(),

            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}