import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import AddSong from './AddSong';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { handleSongList } from '../../../../_services/creater/creatorSongList.service';
import { useDispatch, useSelector } from 'react-redux';
import { updateSongSequence } from '../../../../_services/creater/creatorSongSequence';
import SnackbarContainer from '../../../Snackbar';
import {
  setLoader,
  setSnackBar,
  setSnackBarMessage,
  setSnackBarVariant,
} from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { Grid, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import NoResults from '../../../../components/reusableComponents/NoResults';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { useCallback } from 'react';

function SongListComp() {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [songsList, setSongsList] = useState([]);
  const [songListBKP, setSongListBKP] = useState([]);
  const [disableStatus, setDisableStatus] = useState(false); //to handle the add new button status
  const disableHandler = () => {
    setDisableStatus(false);
  };
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState([]);

  const breadcrumbs = [
    { label: 'Song Hub', link: '/songhub' },
    { label: 'Music', link: '/songhub' },
   
  ];


  const getSongList = () => {
    let dataObj = {};
    dataObj.userId = userData.id;
    handleSongList(dataObj, 'GETBYUSER')
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.results.length > 0) {
            setSongListBKP(response.data.results);
            let arrayData = response.data.results;
            const data = arrayData.sort(function (a, b) {
              return parseInt(a.song_order) - parseInt(b.song_order);
            });
            setSongsList(data);
            setDisableStatus(false);
          } else {
            setDisableStatus(true);
            setSongsList([{}, ...songsList]);
          }
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => {
          dispatch(setSnackBar(false));
        }, 2000);
      });
  };

  useEffect(() => {
    if (userData.id) {
      getSongList();
    }
  }, [userData.id]);

  const addNewSongHandler = () => {
    const items = songsList;
    setSongsList([{}, ...items]);
    setDisableStatus(true);
  };

  const deleteSongHandler = (id, currentIndex) => {
    const items = songsList;
    if (items.length > 0) {
      const lastIndex = items.length - 1;
      setSongsList(items.filter((item, index) => item.id !== id));
      setDisableStatus(false);
      if (id != undefined) {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.songPlaylist.songLinkDelete));
        dispatch(setSnackBarVariant('info'));
        if (JSON.stringify(items[0]) === '{}') {
          setDisableStatus(true);
        }
        let dataObj = {};
        dataObj.userId = userData.id;
        dataObj.songId = id;
        handleSongList(dataObj, 'DELETE')
          .then(function (response) {
            if (response.status = 200 || response.status === 204) {
              setTimeout(() => {
                dispatch(setSnackBar(false));
              }, 2000);
              dispatch(setSnackBarMessage(formSnackbar.songPlaylist.songLinkDeleteSuccess));
              dispatch(setSnackBarVariant('success'));
            } else {
              dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
              dispatch(setSnackBarVariant('error'));
              setTimeout(() => {
                dispatch(setSnackBar(false));
              }, 2000);
            }
          })
          .catch((error) => {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => {
              dispatch(setSnackBar(false));
            }, 2000);
          });
      }
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(songsList);
    const draggedItemId = items[result.source.index].id;
    const droppedLocationItemId = items[result.destination.index].id;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSongsList(items);
    const data=items.map(song=>song.id)
    let dataObj={
          song_ids:data.join(',')
    }
    updateSongSequence(userData.id, dataObj)
      .then((response) => {
        if (response.status === 200) {
          renderSongList();
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => {
          dispatch(setSnackBar(false));
        }, 2000);
      });
  }

  const handleInputSave = () => { }

  const renderSongList = () => {
    getSongList();
  };

  const renderCard = useCallback((card, index, provided) => {
    return (
      <AddSong
        key={card.id}
        deleteSongHandler={deleteSongHandler}
        index={index}
        id={card.id}
        data={card}
        songTitle={card.songTitle}
        provided={provided}
        disableHandler={disableHandler}
        renderSongList={renderSongList}
        songsListData={songsList}
      />
    );
  }, [songsList]);

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Song Hub</h2>
            <div className='d-flex'>
            {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={breadcrumb.label}>
                {index > 0 && <div style={{ width: '20px',marginLeft:'10px' }} className='text-secondary'>◆</div>}
                  <div>
                    <Link to={breadcrumb.link} style={{ textDecoration: 'none', color: 'gray', fontSize: '0.8rem' }}>
                      {breadcrumb.label}
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className='pt-4'>
            
              <a target="_blank" href={`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/music`}>{`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/music`}</a>
            </div>
          </div>
          {/* Add Song button */}
          <div className='my-4'>
            <button disabled={disableStatus ? 'disabled' : ''} className="gn-actionbtn" onClick={addNewSongHandler}><AddIcon />ADD NEW </button>
          </div>
          {/* Inner card */}
          <div className="row pb-5">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="characters creator-songs-list col-sm-12 col-md-12 col-lg-8 col-xl-6">
                    {songsList.length > 0 && songsList.map((item, index) => {
                      return (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided) => (
                            renderCard(item, index, provided)
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
      <SnackbarContainer />
    </div>
  );
}

export default SongListComp;
