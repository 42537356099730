import { Box, Button, FormControl, Grid, Icon, InputAdornment, InputLabel, makeStyles, Menu, MenuItem, OutlinedInput, Select, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import LinkIcon from '@mui/icons-material/Link';
import { useEffect } from 'react';
 import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
// import { getSocialPlatform } from '../../_services/SocialPlatform/socialPlatform.services';
// import { updateSocialPlatform } from '../../_services/SocialPlatform/updateSocialPlatform.services';
import { DeleteOutline } from '@mui/icons-material';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare,faTwitter,faLinkedin,faYoutubeSquare,faInstagramSquare,faSpotify } from '@fortawesome/free-brands-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
// import { fetchUserDetail, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
// import { formSnackbar } from '../../_constants/snackbar.constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchUserDetail, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { updateSocial } from '../../_services/profile/updateSocial.service';
import { getSocial } from '../../_services/profile';

// const useStyles = makeStyles(theme => ({
//     select: {
//       width: '100%',
//     },
//     inputAdornment: {
//       marginRight: theme.spacing(1),
//       color: theme.palette.grey[500],
//     },
//   }));

function isValidUrl(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }
  const TextFieldGrid = styled(Grid)(({ theme }) => ({
    maxHeight: '50vh',
    overflowY: 'auto',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  }));
  const InputIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));
         // Define the menu items and their corresponding icons
         const menuItems = [
            { icon: faFacebookSquare, label: 'Facebook',value:'facebook' },
            { icon: faTwitter, label: 'Twitter',value:'twitter' },
            { icon: faLinkedin, label: 'LinkednIN',value:'linkedin' },
            { icon: faYoutubeSquare, label: 'Youtube',value:'youtube' },
            { icon: faInstagramSquare, label: 'Instagram',value:'instagram' },
            { icon: faSpotify, label: 'Spotify',value:'spotify' },
            { icon: faGlobe, label: 'Other',value:'other' }
        ];
        
        // Define the menu item style
        const menuItemStyle = {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            cursor: 'pointer',
        };
        
        // Define the menu item icon style
        const menuItemIconStyle = {
            marginRight: '10px',
        };
        
        // Define the menu item label style
        const menuItemLabelStyle = {
            flexGrow: 1,
        };
  
function SocialPlatform(props) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);
    const [anchorEl, setAnchorEl] = useState(null);
    const [url, setUrl] = React.useState("");
    const [Link, setLink] = React.useState('');
    //const classes = useStyles();
    const [socialPlatformList, setSocialPlatformList] = useState("");

    const [socialPlatformId, setSocialPlatformId] = useState("");
    const [platforms, setPlatforms] = useState([]);
    const [socialPlatformError, setSocialPlatformError] = useState(false)

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
      const handleMenuClose = () => {
       setAnchorEl(null);
       
      };
      const handlePlatformClick = (platform) => {
        setPlatforms((prevPlatforms) => [...prevPlatforms, platform]);
        handleMenuClose();
        console.debug("platforms",platforms);
      };
    const setIcon = (platform) => {
        const platformIcons = {
            'facebook': faFacebookSquare,
            'twitter': faTwitter,
            'linkedin': faLinkedin,
            'youtube': faYoutubeSquare,
            'instagram': faInstagramSquare,
            'spotify': faSpotify,
            'default': faGlobe,
          };
          return platformIcons[platform] || platformIcons['default'];
    }
    useEffect(function () 
    {
        if (userData.id) 
        {
            userData.state?setSocialPlatformId(userData.state.id):setSocialPlatformId(null)
        }
    }, [userData.id]);

    function handleInputChange(event) {
        setUrl(event.target.value);
      }
      function handleSubmit(event) {
        event.preventDefault();
        if (isValidUrl(url)) {
            alert('success')
          // do something with the valid URL
        } else {
            alert('enter valid url')
          // show an error message or prevent form submission
        }
      }
    useEffect(function () 
    {
        getSocial().then(function (response) 
        {
            setSocialPlatformList(response.data ? response.data.data : "")
        }).catch(function (error) 
        {
            console.log(error);
            return error.response;
        })

    }, [])
    const validateForm = () =>{
        if(socialPlatformId === '' || socialPlatformId ===null) 
        {
            setSocialPlatformError(true)
            return false
        } 
        else
        return true  
    }
    function onSave(e) {
        e.preventDefault();
        //validate input fields before apending the data
        let isValid = validateForm();
        if(isValid){
            var data = new FormData();
           
            if (socialPlatformId) 
            {
                data.append("state", socialPlatformId)
            }
           

        dispatch(setSnackBar(true))
        setTimeout(()=>{dispatch(setSnackBar(false))}, 2000)
        dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdate))
        dispatch(setSnackBarVariant("info"))
            updateSocial(data, /*userData.id*/).then(function (response)
            {
                if(response.status===200) 
                {
                   dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdateSuccess))
                   dispatch(setSnackBarVariant("success"))
                   setTimeout(()=>{dispatch(setSnackBar(false))}, 2000)
                   dispatch(fetchUserDetail(userData))
                alert('yes')
                }
                else
                {
                  dispatch(setSnackBarVariant('error'))
                  dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                  setTimeout(()=>{dispatch(setSnackBar(false))}, 3000)
                alert('no')
                }
            }).catch(function (error) 
            {
                console.log(error);
                console.log(error.response);
                return error.response;
            }) 
        } //Is valid end
    }
    const handleChange = (event) => {
      setLink(event.target.value);
    };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <h4 className="mb-2">Social platform</h4>
        <p className="mb-2 text-secondary">In which social media do you have your profile?</p>
      </Grid>
      <Grid item xs={12} sm={6} sx={{textAlign:'end'}}>
        <button title='Add Platform' className='gn-actionbtn' onClick={handleButtonClick} >
          ADD PLATFORM
        </button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {menuItems.map((menuItem) => (
          <MenuItem
              key={menuItem.label}
              style={menuItemStyle}
              onClick={() => handlePlatformClick(menuItem.value)}
              value={menuItem.value}
          >
              <FontAwesomeIcon
              icon={menuItem.icon}
              style={menuItemIconStyle}
              />
              <div style={menuItemLabelStyle}>{menuItem.label}</div>
          </MenuItem>
          ))}
        </Menu>
      </Grid>
      <TextFieldGrid container item xs={12} sx={{padding:'1rem'}}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="Facebook Url"
            placeholder='Facebook Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faFacebookSquare} size='lg' />
                </InputIcon>
              ),
            }}
          />
        </Grid> 
        <Grid item xs={12}>
            <TextField
              fullWidth
              shrink
              size="small"
              variant="outlined"
              label="Twitter Url"
              placeholder='Twitter Url'
              InputProps={{
                startAdornment: (
                  <InputIcon>
                    <FontAwesomeIcon icon={faTwitter}  size='lg' />
                  </InputIcon>
                ),
              }}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="LinkedIn Url"
            placeholder='LinkedIn Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faLinkedin} size='lg' />
                </InputIcon>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="Youtube Url"
            placeholder='Youtube Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faYoutubeSquare} size='lg'/>
                </InputIcon>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="Instagram Url"
            placeholder='Instagram Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faInstagramSquare} size='lg' />
                </InputIcon>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="Spotify Url"
            placeholder='Spotify Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faSpotify} size='lg' />
                </InputIcon>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            shrink
            size="small"
            variant="outlined"
            label="Website Url"
            placeholder='Website Url'
            InputProps={{
              startAdornment: (
                <InputIcon>
                  <FontAwesomeIcon icon={faGlobe} size='lg' />
                </InputIcon>
              ),
            }}
          />
        </Grid>
        {platforms.map((platform, index) => (
        <Grid item xs={12}>
        <TextField
          fullWidth
          shrink
          key={index}
          size="small"
          variant="outlined"
          label="Website Url"
          placeholder='Website Url'
          InputProps={{
            startAdornment: (
              <InputIcon>
                <FontAwesomeIcon icon={setIcon(platform)} size='lg' />
              </InputIcon>
            ),
          }}
        />
        </Grid>  
        ))}
      </TextFieldGrid>
    </Grid>
  )
}

export default SocialPlatform