import React,{useState} from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

const ProfileSocial = (props) => {

  console.log('11===',props.socialTypeId);  
  var icon = null
/*   const [icons, setIcon] = useState();  */
  switch(props.socialTypeId){
    case "1":
        icon = <FacebookIcon/>;
        break;
    case '2':
        icon = <InstagramIcon/>;
        break;
    case '3':
        icon = <LinkedInIcon/>;
        break;  
    case '4':
        icon = <YouTubeIcon/>;
        break;   
    case '5':
        icon = <TwitterIcon/>;
        break;  
    case '6':
        icon = <LibraryMusicIcon/>;
        break;         
    deafult:
        break;
  }
  return (
    <span>{icon}</span>
  )
}

export default ProfileSocial;