import React from 'react';
import { FormControl, InputLabel, MenuItem, Radio, Select, TextField } from '@mui/material';

const NewRelease = ({ data, handleInputChange, errors, genre, moodList, subGenreList, selectedValue, instruValue, language,titleVersionList }) => {
    return (
        <>
            <div>
                <p className='cardheading'>Add Basic Information</p>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <FormControl
                            name='song_name'
                            noValidate
                            autoComplete="off"
                            fullWidth={true}>
                            <TextField
                                size="small"
                                value={data.song_name}
                                onChange={handleInputChange}
                                error={errors.song_name}
                                inputProps={{ maxLength: 255 }}
                                helperText={errors.song_name_helperTxt}
                                name="song_name"
                                label="Song Name*" variant="outlined" className="w-100 rounded "
                            />
                        </FormControl>
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <FormControl fullWidth={true} name="mood" size="small" >
                            <InputLabel>Mood*</InputLabel>
                            <Select
                                value={data.mood}
                                name="mood"
                                label="Mood"
                                onChange={handleInputChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {moodList && moodList.map((item, index) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.mood}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <p>Explicit content(Adult/strong)*</p>
                        <Radio
                            checked={selectedValue === 'True'}
                            onChange={handleInputChange}
                            value="True"
                            name='explicit_content'
                            inputProps={{ 'aria-label': 'Yes' }}
                        />
                        Yes
                        <Radio
                            checked={selectedValue === 'False'}
                            onChange={handleInputChange}
                            value="False"
                            name='explicit_content'
                            inputProps={{ 'aria-label': '0' }}
                        />
                        No
                    </div>
                    <div className='col-md-6'>
                    <p>Instrumental*</p>
                    <Radio
                        checked={instruValue === 'True'}
                        onChange={handleInputChange}
                        value="True"
                        name="instrumental"
                        inputProps={{ 'aria-label': 'True' }}

                    />
                    Yes
                    <Radio
                        checked={instruValue === 'False'}
                        onChange={handleInputChange}
                        value="False"
                        name="instrumental"
                        inputProps={{ 'aria-label': 'False' }}
                    />
                    No
                   
                    </div>
                </div>
                <div className='row'>
                       <div className='col-md-6 mt-4'>
                        <FormControl fullWidth={true} name="data.genre" size="small" >
                            <InputLabel>Genre*</InputLabel>
                            <Select
                                value={data.genre}
                                name="genre"
                                label="Genre"
                                onChange={handleInputChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {genre ? genre.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.genre}</MenuItem>
                                )) : <MenuItem value='null'></MenuItem>}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-6 mt-4'>
                        <FormControl fullWidth={true} name="title_version" size="small" >
                            <InputLabel>Title Version(optional)</InputLabel>
                            <Select
                                value={data.title_version}
                                name="title_version"
                                label="Title Version(optional)"
                                onChange={handleInputChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {titleVersionList && titleVersionList.map((item, index) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.title_version}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="form-group col-md-12 mt-3 mb-3">
                <FormControl fullWidth={true} name="data.genre" size="small" >
                            <InputLabel>Sub Genre*</InputLabel>
                            <Select
                                value={data.sub_genre}
                                name="sub_genre"
                                label="Sub Genre"
                                onChange={handleInputChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                                error={errors.sub_genre}
                                helperText={errors.sub_genre_helperTxt}
                            >
                                {subGenreList ? subGenreList.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.sub_genre}</MenuItem>
                                )) : <MenuItem value='null'></MenuItem>}
                            </Select>
                        </FormControl>
                </div>
                <div className="form-group col-md-12 mt-3 mb-3">
                    <FormControl fullWidth={true} name="data.language" size="small" >
                        <InputLabel>Language*</InputLabel>
                        <Select
                            value={data.language}
                            name="language"
                            label="Language"
                            onChange={handleInputChange}
                            MenuProps={{
                                style: {
                                    maxHeight: 250,
                                },
                            }}
                        >
                            {language ? language.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.language}</MenuItem>
                            )) : <MenuItem value='null'></MenuItem>}
                        </Select>
                    </FormControl>
                </div>
                <div className='mt-3'>
                    <FormControl
                        name='song_lyrics'
                        noValidate
                        autoComplete="off"
                        fullWidth={true}
                    >
                        <TextField
                            variant="outlined"
                            label="Song Lyrics(Optional)"
                            name='song_lyrics'
                            value={data.song_lyrics}
                            onChange={handleInputChange}
                            size="small"
                            type="text"
                            multiline={true}
                            rows={3}
                            error={errors.song_lyrics}
                            helperText={errors.song_lyrics_helperTxt}
                        />
                    </FormControl>
                </div>
                <div className='mt-3'>
                    <FormControl
                        name='song_description'
                        noValidate
                        autoComplete="off"
                        fullWidth={true}
                    >
                        <TextField
                            variant="outlined"
                            label="Content Description(Optional)"
                            name='song_description'
                            value={data.song_description}
                            onChange={handleInputChange}
                            size="small"
                            type="text"
                            multiline={true}
                            rows={4}
                            error={errors.song_description}
                            helperText={errors.song_description_helperTxt}
                        />
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default NewRelease