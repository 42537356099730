import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { createrLink } from '../_constants/api.constant'
export async function postUpdateDetail(postData,dataId){ 
    const response=await axios.put(
        createrLink.POST+'?id=' +dataId,//'http://gncreatorsdev.gncreators.com/content_creator/link?id='+dataId,// " https://content_creater_gnapi.eela.tech/api/v1/link/"+dataId,
       postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}