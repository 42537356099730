import axios from "axios";
import { verifyArtistURL } from '../../_constants/api.constant'
import { getSelfToken } from '../../../_helpers/auth/secureToken'
export async function verifyServiceHandler(data,requestType) {
    switch(requestType)
    {
        case 'POST':{
            let localDataObj = data;
            const response = await axios.post(
                verifyArtistURL.POST,localDataObj,
                /* {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                } */
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
        
        
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        default:{
            return;
        }
    }
}