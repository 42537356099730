import { regexValidator } from '../../../_helpers/reusablefunctions/regexValidator';
export const handleInputChange = (event, data, setData, errors, setErrors, formSnackbar, setContentTypeValue, setInstruValue, setSong_File, setSong_Status, setSelectedValue, setReleasehistory, setCopyrighttype, setCopyrights_c,setCopyrights_p,setCopyrightc_name,setCopyrightp_name) => {
    const { name, value } = event.target;
    setData({
        ...data,
        [name]: value
    });
    switch (event.target.name) {
        case 'song_name': {
            const isValid = regexValidator(value, 'alphanumericEndSpace')
            if (!isValid)
                setErrors({ ...errors, song_name: true, song_name_helperTxt: formSnackbar.errors.validInput });
            else if (value.length > 254)
                setErrors({ ...errors, song_name: true, song_name_helperTxt: formSnackbar.errors.characterError });
            else
                setErrors({ ...errors, song_name: false, song_name_helperTxt: false });
            break;
        }
        case 'album': {
            const isValid = regexValidator(value, 'alphanumericEndSpace')
            if (!isValid)
                setErrors({ ...errors, album: true, album_helperTxt: formSnackbar.errors.validInput });
            else if (value.length > 254)
                setErrors({ ...errors, album: true, album_helperTxt: formSnackbar.errors.characterError });
            else
                setErrors({ ...errors, album: false, album_helperTxt: false });
            break;
        }
        case 'isrc_code': {
            const isValid = value.length > 0 ? regexValidator(value, 'alphanumeric') : true;
            if (!isValid)
                setErrors({ ...errors, isrc_code: true, isrc_code_helperTxt: formSnackbar.errors.validInput });
            else
                setErrors({ ...errors, isrc_code: false, isrc_code_helperTxt: false });
            break;
        }
        case 'upc_code': {
            const isValid = value.length > 0 ? regexValidator(value, 'upc') : true;
            if (!isValid)
                setErrors({ ...errors, upc_code: true, upc_code_helperTxt: formSnackbar.errors.validInput });
            else
                setErrors({ ...errors, upc_code: false, upc_code_helperTxt: false });
            break;
        }
        case 'catalog': {
            const isValid = value.length > 0 ? regexValidator(value, 'alphanumeric') : true;
            if (!isValid)
                setErrors({ ...errors, catalogId: true, catalogId_helperTxt: formSnackbar.errors.validInput });
            else
                setErrors({ ...errors, catalogId: false, catalogId_helperTxt: false });
            break;
        }
        case 'song_lyrics': {
            const isValid = value.length > 0 ? regexValidator(value, 'descriptionText') : true;
            if (!isValid)
                setErrors({ ...errors, song_lyrics: true, song_lyrics_helperTxt: formSnackbar.errors.validInput });
            else if (value.length > 5000)
                setErrors({ ...errors, song_lyrics: true, song_lyrics_helperTxt: formSnackbar.errors.lyricscharacterCountErrorMax });
            else
                setErrors({ ...errors, song_lyrics: false, song_lyrics_helperTxt: false });
            break;
        }
        case 'song_description': {
            const isValid = value.length > 0 ? regexValidator(value, 'descriptionText') : true;
            if (!isValid)
                setErrors({ ...errors, song_description: true, song_description_helperTxt: formSnackbar.errors.validInput });
            else if (value.length > 4000)
                setErrors({ ...errors, song_description: true, song_description_helperTxt: formSnackbar.errors.characterCountErrorMax });
            else
                setErrors({ ...errors, song_description: false, song_description_helperTxt: false });
            break;
        }
        case 'explicit_content': {
            setSelectedValue(value)
            break;
        }
        case 'release_history': {
            setReleasehistory(value)
            break;
        }
        case 'copyright_type': {
            setCopyrighttype(value)
            break;
        }
        case 'content_types': {
            setContentTypeValue(value)
            break;
        }
        case 'instrumental': {
            setInstruValue(value)
            break;
        }
        case 'song_file': {
            setSong_File(value)
            break;
        }
        case 'song_status': {
            setSong_Status(value)
            break;
        }
        default:
            {
                break;
            }
    }
}
export const handleArtistData = (name, dataList, setDataList, dataKey, isNewArtist, artistData, artistCode, data, setData) => {
    console.debug("handleArtistData:handleArtistData:name",name)
    console.debug("handleArtistData:handleArtistData:dataList",dataList)
    console.debug("handleArtistData:handleArtistData:setDataList",setDataList)
    console.debug("handleArtistData:handleArtistData:artistData",artistData)
    console.debug("handleArtistData:handleArtistData:artistData",artistData)
    console.debug("handleArtistData:handleArtistData:artistCode",artistCode)
    console.debug("handleArtistData:handleArtistData:data",data)
    console.debug("handleArtistData:handleArtistData:setData",setData)
    




    const updatedList = isNewArtist ? [...dataList, artistData[0]] : artistData;
    const updatedData = isNewArtist
        ? { ...data, [dataKey]: `${data[dataKey]},${artistData.map(artist => artist.artist_code)}` }
        : { ...data, [dataKey]: artistData.map(artist => artist.artist_code).join(',') };

    switch (name) {
        case artistCode.primary_artist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.feature_artist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.lyricist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.producer:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.composer:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.actor:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.remixers:
            setDataList(updatedList);
            setData(updatedData);
            break;
        default:
            break;
    }
};
