import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from '../../../components/reusableComponents/customAudioPlayer';
import FileUploader from '../../../components/reusableComponents/FileUploader';
import { setLoader } from '../../../redux';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, Avatar, Box, Link, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const AddMedia = ({ data, errors, handleInputChange, audioFile, handleDrop, addAudio, handleFileInputChange, fileInputRef,
    handleFileInputRemove, songPoster, handleDropSongPoster, addPoster, handleChangeSongPoster, fileInputPosterRef, handlePosterRemove,
    progress, selectedFile, uploadProgressBytes, isFileUploaded }) => {
    const mdScreen = useMediaQuery('(max-width:1024px)');
    let smScreen = useMediaQuery('(max-width:768px)')
    const dispatch = useDispatch()
    const [file, setFile] = React.useState(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + data.song_file)
    const [fileDetails, setFileDetails] = React.useState(selectedFile ? selectedFile : { name: '', size: '' })
    let fileName;
    React.useEffect(() => {
        setFile(data.song_file ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + data.song_file : null)
        if (selectedFile === undefined || selectedFile === null || selectedFile === '') {
            if (data.song_file !== undefined && data.song_file !== null && data.song_file !== '') {
                fileName = data.song_file.substring(data.song_file.lastIndexOf('/') + 1);
                setFileDetails({ ...fileDetails, name: fileName })
                getFileDetails(file)
            }
        }

    }, [data])

    async function getFileDetails(url) {
        // Extract the file size (assuming the URL points to the actual file)
        try {
            const response = await fetch(url);
            const fileSize = response.headers.get('content-length');
            setFileDetails(preObj => ({ ...preObj, size: fileSize }))
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    /*   React.useEffect(()=>{
    
      },[data]) */
    const deleteFile = () => {
        dispatch(setLoader(true))
        setFile('')
        setFileDetails({ name: '', size: '' })
        //handleFileInputRemove()
        setTimeout(() => {
            handleFileInputRemove()
        }, 1500)
    }

    return (
        <div>
            <h4 className="cardheading">File Upload</h4>
            <div>
                <div>
                    <Box className="position-relative">
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: "0.5rem",
                                border: "2px dashed rgba(0, 0, 0, 0.12)",
                                borderRadius: "12px",
                                backgroundColor: "rgba(201, 201, 201, 0.1)",
                                ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                            }}
                        >
                            <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                                <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                                    <UploadFileIcon color="primary" />
                                </Avatar>
                            </Box>
                            <Box className="upload-btn-wrapper text-center">
                                <span>
                                    <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                                        Click to upload
                                    </Link>
                                    <input
                                        key={0}
                                        type="file"
                                        name="myfile"
                                        multiple
                                        accept=".wav"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef}
                                        className='fileDragDrop'
                                    />

                                </span>
                                <span className='d-block'>or Drag and drop audio file</span>

                            </Box>
                            <Box mb='1rem' mt='0.5rem'>
                                <Typography variant="caption" color="text.secondary" display="block" gutterBottom>Use (.wav) file of max 250MB</Typography>
                            </Box>
                        </Stack>
                    </Box>
                </div>
                {selectedFile && <FileUploader progressCount={progress} fileSize={selectedFile ? selectedFile.size : fileDetails.size} fileName={selectedFile ? selectedFile.name : fileDetails.name} uploadProgressBytes={uploadProgressBytes}
                    isFileUploaded={isFileUploaded} /*  removeFile={deleteFile} */ />}
                {!selectedFile && fileDetails.name != '' && <div className="mt-2"><strong>File Name : </strong><span className="text-truncate" title={fileDetails.name}>{fileDetails.name}</span></div>}

                <div style={{ position: 'relative' }}>
                    <div className="tempSection">{progress === 100 && isFileUploaded === false && <span><CircularProgress /></span>}</div>
                    <div className="d-flex align-items-center mt-3" style={{ opacity: file ? '1' : '0.5', pointerEvents: file ? 'auto' : 'none' }}>
                        <div className='flex-grow-1'>
                            {smScreen ? <AudioPlayer type='small' audioSrc={file === '' ? '' : file} /> : <AudioPlayer type='large' audioSrc={file === '' ? '' : file} />}
                        </div>
                        <div>
                            <button title="Delete" className='gn-btn-icononly text-secondary' onClick={deleteFile}><DeleteIcon /></button>
                        </div>
                    </div>
                </div>


            </div>
          
        </div>
    )
}

export default AddMedia