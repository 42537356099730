import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';

const FinalReviews = ({ audio_clip, data, genre, language, selectedLanguage, selectedGenre }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  return (
    <>
      <div className="pb-5">
        <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : ''}`}>
          <div className='d-flex align-items-center'>
            <span className="fs-6 text-secondary">General</span>
            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
          </div>
          <div className={`d-flex ${smScreen ? 'flex-column flex-wrap' : 'row'}`}>
            <div style={{ paddingRight: '0.7rem' }} className='col-md-6'>
              <div className="py-2 color-secondory "><strong>Song Name: </strong>
                <span className='truncate text-secondary text-capitalize'>{data.demo_name}</span>
              </div>
              <div className="py-2 color-secondory "> <strong>Language: </strong>
                <span className='text-secondary text-capitalize'>
                  {selectedLanguage.length > 0
                    ? selectedLanguage.map((lang) => {
                      const languageObject = language.find((item) => item.id === lang.id);
                      return languageObject ? languageObject.language : 'Unknown Language';
                    }).join(', ')
                    : 'Unknown Language'}
                </span>

              </div>
            </div>
            <div className='col-md-6'>
              <div className="py-2 color-secondory "><strong>Genre: </strong>
                <span className='truncate text-secondary text-capitalize'>
                  {selectedGenre.length > 0
                    ? selectedGenre.map((gen) => {
                      const genreObject = genre.find((item) => item.id === gen.id);
                      return genreObject ? genreObject.genre : 'Unknown Genre';
                    }).join(', ')
                    : 'Unknown Genre'}

                </span>
              </div>
              <div className="py-2 color-secondory "><strong>Demo Version: </strong>
                <span className='text-secondary text-capitalize'>
                  {data.demo_type == "True" ? 'Scratch' : 'Final'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={`d-flex align-items-center ${smScreen ? 'pt-3' : ''}`}>
          <span className="fs-6 text-secondary">Demo Composition</span>
          <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
        </div>
        <div className='col-12 row m-0'>
          <div className='col-md-6 p-0'>
            <div className="py-2 color-secondory "><strong> Is Video Available: </strong>
              <span className='text-secondary text-capitalize'>
                {data.is_video_available == "True" ? 'Yes' : 'No'}
              </span>
            </div>
          </div>

          {/* <div className='col-md-6 p-0'>
            <div className="py-2 color-secondory "><strong> Submit To: </strong>
              {data?.receiver.map((d, i) => (
                < span key={i} className='text-secondary text-capitalize'>
                  {d.receiver_types + ','}
                </span>
              ))}
            </div>
          </div> */}
        </div>

        <div className='mt-2'>

          {audio_clip ? (
            smScreen ? <AudioPlayer type='small' audioSrc={audio_clip} /> : <AudioPlayer type='large' audioSrc={audio_clip} />
          ) : (
            <>
              {data.video_link && (<div className='py-2 color-secondory'>
                <strong>Link Drive: </strong>
                <a href={data.video_link} target="_blank" rel="noopener noreferrer">
                  {data.video_link}
                </a>
              </div>)}
              {data.external_link && (
                <div className='py-2 color-secondory'>
                  <strong>Link Drive: </strong>
                  <a href={data.external_link} target="_blank" rel="noopener noreferrer">
                    {data.external_link}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        <div className='col-md-12'>
          <div className="py-2 color-secondory detailText"><strong>Description: </strong><span>
            <p className='detailText'>
              {data.demo_description}
            </p>
          </span></div>
        </div>
      </div >
    </>
  );
}

export default FinalReviews;
