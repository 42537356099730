import React from 'react'

const LockLink = () => {
  return (
    <div>
      <h1>Coming Soon</h1>
    </div>
  )
}

export default LockLink
