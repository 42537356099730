import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Box, styled, useMediaQuery } from '@mui/material';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { getSingleDemoDetails } from '../../_services/demo/GetSingleDemoDetails';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const arrowStyle = {
    fontWeight: 700,
};

const DemoDetail = ({ cardData }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { demoId } = useParams();
    const userData = useSelector(state => state.userData.userData)
    const mdScreen = useMediaQuery('(max-width:768px)');
    const smScreen = useMediaQuery('(max-width:600px)');
    const widthValue = !mdScreen ? '50%' : '100%';
    const [demoInfo, setDemoInfo] = React.useState([])
    const [demoProgress, setDemoProgress] = React.useState([])

    function getDemoDetails(userId, id) {
        dispatch(setLoader(true))
        getSingleDemoDetails({ 'userId': userId, 'id': id }, 'GET_SPECIFIC_DISPLAY').then(function (response) {
            dispatch(setLoader(false))
            if (response.status === 200) {
                setDemoInfo(response.data.data[0])
            }
            else {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
            }
        }).catch(function (error) {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return error.response;
        })

        getSingleDemoDetails({ 'userId': userId, 'id': id }, 'GET_DEMO_PROGRESS').then(function (response) {
            dispatch(setLoader(false))
            if (response.status === 200) {
                setDemoProgress(response.data.results)
            }
            else {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
            }
        }).catch(function (error) {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return error.response;
        })

    }

    React.useEffect(() => {
        if (userData.id) {
            if (demoId) {
                getDemoDetails(userData.id, demoId)
            }
        }
    }, [userData])


    //to handle the back navigation
    const handleRouteBack = () => {
        history.push('/submitdemo')
    }
    //to create the song entry from the released inside the song hub with the available credits

    if (!demoInfo) {
        return <h1>Loading ...</h1>;
    }
    return (
        <>
            {demoInfo && Object.keys(demoInfo).length > 0 &&
                <div className="container" style={{ padding: '1.2rem 1.2rem 3.5rem 1.2rem' }}>
                    <div>
                        <button className="icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
                    </div>
                    <div className='d-flex pb-3' style={{ flexWrap: mdScreen ? 'wrap' : 'nowrap' }}>
                        <Box
                            className='shadow-sm bg-white  border rounded-2 mt-4'
                            sx={{
                                width: widthValue,
                                height: 'calc(50% - 10px)',
                            }}
                        >
                            <div className='p-3'>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <span className="fs-6 text-secondary">General</span>
                                        <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                    </div>
                                    <div>
                                        <div className="value-pair color-secondory "><strong>Song Name: </strong>
                                            <span className='text-capitalize'>{demoInfo.demo_name}</span>
                                        </div>
                                        <div className="value-pair color-secondory "><strong>Genre: </strong>
                                            {demoInfo.genre.map((genreItem) => (
                                                <span className='text-capitalize' key={genreItem.id}>{genreItem.genre}, </span>
                                            ))}
                                        </div>
                                        <div className="value-pair color-secondory "><strong>Language: </strong>
                                            {demoInfo.language.map((languageItem) => (
                                                <span className='text-capitalize' key={languageItem.id}>{languageItem.language}, </span>
                                            ))}
                                        </div>
                                        {demoInfo.demo_file ?
                                            <div className="value-pair color-secondory ">
                                                {/* <strong>Audio: </strong> */}
                                                <AudioPlayer type='small'
                                                    audioSrc={demoInfo.demo_file ?
                                                        `${process.env.REACT_APP_BASEURL}gndemo/media/${demoInfo.demo_file}`
                                                        : ''}
                                                />
                                            </div> :
                                            <div>
                                                {demoInfo.video_link ?
                                                    <div className="value-pair color-secondory ">
                                                        <strong>Video Link: </strong>
                                                        <a href={demoInfo.video_link} target="_blank" rel="noopener noreferrer">
                                                            {demoInfo.video_link}
                                                        </a>
                                                    </div> :
                                                    <div className="value-pair color-secondory ">
                                                        <strong>External Link: </strong>
                                                        <a className='wordBreak' href={demoInfo.external_link} target="_blank" rel="noopener noreferrer">
                                                            {demoInfo.external_link}
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <div style={{ paddingLeft: mdScreen ? '' : '2rem', width: '100%' }}>
                            <Box
                                className='shadow-sm bg-white  border rounded-2 w-100 overflow-hidden mt-4'
                                sx={{
                                    maxHeight: 'calc(100% - 100px)',
                                }}
                            >
                                <div className='p-3'>
                                    <div className='d-flex align-items-center'>
                                        <span className="fs-6 text-secondary">Demo Progress</span>
                                        <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                    </div>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <div className={`${smScreen ? 'w-100' : 'w-100'}`}>
                                            {/* // Check if receiver_types  is an array and each element is a string */}
                                            {/* {Array.isArray(demoProgress.receiver_types) ? ( */}
                                                {demoProgress.map((submittedToItem, index) => (
                                                    <div className='py-2' key={index}>
                                                        <strong className='color-secondory text-capitalize'>{userData.name} <span className="rotate-icon mx-3"><CallMadeIcon style={{ transform: 'rotate(45deg)' }} /></span>
                                                            {submittedToItem.receiver_types +" "}
                                                            {submittedToItem.submission_date && getDateAsPerFormat(submittedToItem.submission_date, "dd/MM/yyyy")}
                                                        </strong>
                                                        <br />
                                                    </div>
                                                ))}
                                     

                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <Box
                                className='shadow-sm bg-white  border rounded-2 w-100 mt-4 overflow-x-hidden'
                                sx={{
                                    maxHeight: 'calc(100vh - 200px)',
                                }}
                            >
                                <div className='p-3'>
                                    <div className='d-flex align-items-center'>
                                        <span className="fs-6 text-secondary">Addition Information</span>
                                        <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                    </div>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <div className="value-pair color-secondory "><strong>Description: </strong>
                                            <p className='detailText'>
                                                {demoInfo.demo_description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Box>

                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default DemoDetail