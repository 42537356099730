
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber } from '../../_services/formateRevenueData';
import Paper from '@mui/material/Paper';

export default function Overview() {

    const studioData = useSelector((state) => state.userData.userData);
    const loader = useSelector((state) => state.royaltyDataReducer.loading);
    const overviewData = useSelector((state) => state.royaltyDataReducer.overviewData?.overall_data);
    const data = useSelector((state) => state.royaltyDataReducer.overviewData);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = overviewData?.total_count

    useEffect(function () {
        if (studioData.studio_id && data == "") {
            dispatch(fetchRoyaltyDetail("overviewData", { page: page, limit: rowsPerPage }, studioData.studio_id, `OVERVIEW`))
        }

    }, [studioData])
    
    const rows = [
        { id: 1, name: 'Release Downloads', totalsale: formatIndianNumber(overviewData?.overall_release_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_release_downloads_earnings)}` },
        { id: 2, name: 'Video Downloads', totalsale: formatIndianNumber(overviewData?.overall_video_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_video_downloads_earnings)}` },
        { id: 3, name: 'Track Downloads', totalsale: formatIndianNumber(overviewData?.overall_track_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_track_downloads_earnings)}` },
        { id: 4, name: 'Streams', totalsale: formatIndianNumber(overviewData?.overall_total_streams), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_streams_earning)}` }
    ];
    const columns = [
        { id: 'name', numeric: false, field: 'name', label: 'Overview' },
        { id: 'totalsale', numeric: true, field: 'totalsale', label: 'Total Sales' },
        { id: 'totalearning', numeric: true, field: 'totalearning', label: 'Earnings' },
    ]

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'totalsale', numeric: true, label: formatIndianNumber(overviewData?.overall_sales) },
        { id: 'totalearning', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(overviewData?.overall_earnings)}</Box> },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Overview'} />
            <TableComponent footerHide={true} columns={columns} data={rows} headFooter={headFooter} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}