import axios from "axios";
import { userPostSocialLinkApi } from '../_constants/api.constant'
import {getSelfToken } from '../../_helpers/auth/secureToken'
export async function updateSocial(postData,id) {
    const response = await axios.put(
        userPostSocialLinkApi.UPDATE,//+'?id='+id,
        postData,
        {   params:{'id':id},
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}