import React, { useState } from 'react';
import { Email } from '@mui/icons-material'
import { Lock } from '@mui/icons-material'
import { useHistory, useLocation, Redirect,useParams} from 'react-router-dom';
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import Footer from './footer';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import google from '../img/google.svg'
import { login } from '../_services/auth/auth.service';
import { resetPassword } from "../_services/resetPassword"
import {
    setSsoToken,
    setSelfToken,
} from '../_helpers/auth/secureToken';
import { setLoader } from '../redux';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux'
import {
    setAppToken,
    setUserAuthType,
    getSelfToken,
    setTempSsoToken,
    getAppToken,
    getUserAuthType
} from '../_helpers/auth/secureToken';
import { isLogin } from '../_helpers/auth/secureToken';

import { authentication } from '../config/auth/AppConstants'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { TextField } from '@mui/material';
import backgroundImage from '../img/bg_image_texture3.png'


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius:3,
    boxShadow: 24,
    p: 3
  };
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    let {rowId, token} = useParams();
    const LogoApp = process.env.REACT_APP_APPURL == 'https://creator.groovenexus.com' ? logoCreators : logoGntunes

    let { from } = location.state || { from: { pathname: "/" } };
    const [open, setOpen] = React.useState(false); //to show and hide the modal
    const handleOpen = () => setOpen(true);
    const handleClose=()=>setOpen(false)
    const [modalText, setModalText] = useState('')
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassError, setNewPassError] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [conPassError, setConPassError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);



    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var regexp =  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
     
        if (email.match(mailformat)) {
            data.append('email', email);
            setEmailError("")
        } else {
            setEmailError(
                <label className="text-danger mt-1 ">Please enter valid Email</label>
            )
        }

        if (password.match(regexp) || format.test(password)) {
            data.append('password', password);
            setPassError("")
        } else {
            setPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid password </span>
            )
        }
        if (conPassword.match(password)) {
            data.append('c_password', conPassword);

            setConPassError("")
        } else {
            setConPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid confirm password </span>
            )
        }
        data.append("token",rowId+'/'+token)
        if (email.match(mailformat) && password.match(regexp) || format.test(password) && conPassword.match(password)) {
            dispatch(setLoader(true))
            resetPassword(data,rowId+'/'+token)
            .then(function (response) {
                if(response.status === 200){
                    dispatch(setLoader(false))
                    setModalText('You have successfully reset your password.')
                    handleOpen();
                    setTimeout(()=>{history.push('/login')},3000)
                }
                else
                {
                    setModalText(`There is an error. ${response.data.error[0]}`)
                    dispatch(setLoader(false))
                    handleOpen();
                }
            }).catch(function (error) {
                dispatch(setLoader(false))
                console.log(error.response);
                return error.response;
            })
        }
    }

    return (
        <div className="container-fluid p-0" style={{height:'100vh',backgroundImage:`url(${backgroundImage})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0 ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src={LogoApp} /></a>
                    </div>
                </nav>
                <div className="text-center mt-4">
                    <h1>Reset Password</h1>
                </div>
                <div className='p-4' style={{maxWidth:'600px',width:'100%',margin:'auto'}}>
                    <div className="mt-4 mb-4 p-4 border rounded shadow bg-white">
                        <form action="" method="" className="row g-3">
                            {/* <h2 className="text-center">Reset Your Password</h2> */}
                            <h5 className=" fw-400 text-secondary mt-4">
                                Enter the new password
                            </h5>
                                <div className="col-md-12">
                                <input type="hidden" name="token" value="{{ $token }}"/>
                                <div>
                                <TextField 
                                        id="outlined-basic"
                                        label="Password*"
                                        size='small'
                                        variant="outlined" 
                                        type={showPassword ? "text" : "password"}
                                        className="w-100"
                                        placeholder=" Password *"
                                        name="password"
                                        onChange={e => { setPassword(e.target.value); setPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />  
                                    </div>
                             
                                {passError}
                            </div>
                            <div>
                            <TextField 
                                        id="outlined-basic"
                                        label="Confirm Password*"
                                        variant="outlined" 
                                        type={showConPassword ? "text" : "password"}
                                        className="w-100"
                                        placeholder=" Confirm  Password *"
                                        name="password"
                                        size='small'
                                        sx={{marginBottom:'1rem'}}
                                        onChange={e => { setConPassword(e.target.value); setConPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowConPassword(!showConPassword)}
                                                    edge="end"
                                                >
                                                    {showConPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                       {conPassError}
                            </div>
                            <div className='col-md-12 px-2 m-0'>
                                <span className='mt-4 text-secondary'>Password must contain:</span>
                                <ul className='ps-3 mt-2'>
                                    <li className='small'> At least 8 characters,one special characters, one number. </li>
                                    <li className='small'> At least one uppercase letter ,one lowercase letter .</li>
                                </ul>
                            </div>
                            {/* <div className="col-md-12 m-0">
                                <span>Go to the:</span>  <Link to="/login" className="text-decoration-none">Login</Link>
                                <button type="submit" className="gn-actionbtn float-end px-4 py-2" onClick={onClickHandler}>Submit</button>
                            </div> */}
                             <div className="col-md-12 m-0">
                                <button type="submit" className="gn-actionbtn float-end py-2 mt-4" onClick={onClickHandler}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <h6>{modalText}.</h6>
            </Box>
        </Modal>
        </div>
    );
}



export default ResetPassword;