import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Card, CardContent, CardMedia, IconButton, Modal, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import imgThumbnail from '../../../../img/Frame 48096170.png'
import { useLocation } from 'react-router-dom';
import { getStrPlatformImg } from '../../../../_helpers/reusablefunctions/getStreamingPlatform';

function PlaylistPlatforms({ playlistSongLibrary }) {
  const [streamingLink, setStreamingLink] = useState()
  const [platformLogo, setPlatformLogo] = useState(imgThumbnail)

  useEffect(() => {
    if (playlistSongLibrary) {
      playlistSongLibrary.platformName = playlistSongLibrary?.streamingPlatformName
      setStreamingLink(playlistSongLibrary?.streamingPlatformlink)
      setPlatformLogo(getStrPlatformImg(playlistSongLibrary?.streamingPlatformKey))
    }
  }, [playlistSongLibrary])

  return (
    <Box width={'100%'} >
      <Card className='m-2'>
        <CardContent className='p-2'>
          <Box display={'flex'} alignItems={'center'} gap={2} >
            <CardMedia
              component="img"
              sx={{ width: '50px', height: '50px', borderRadius: '100%' }}
              image={platformLogo}
            />
            <Box width={'90%'} >
              <Typography>{playlistSongLibrary?.streamingPlatformName}</Typography>
              <TextField id="platforms-id"
                className='w-100'
                value={streamingLink}
                variant="filled"
                disabled
                size="small" />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PlaylistPlatforms;
