import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, ListItem } from '@mui/material';
import FAQslist from './FAQslist.json';

export default function FAQs() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='p-4 mb-5'>
            <Typography variant='h5' className='mb-4' >
                Frequently Asked Questions
            </Typography>
            {FAQslist.map((category, i) => (
                <Box key={i}>
                    <Typography  sx={{ background: 'rgba(0, 0, 0, .03)' }} className=' p-3 fw-bold rounded-2 shadow-sm'>{category.category + ":-"}</Typography>
                    {category.faqs.filter(f => f.section == "inside app").map((faq, j) => (<Accordion key={j} expanded={expanded === faq.panel} onChange={handleChange(faq.panel)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${faq.id}bh-content`}
                            id={`panel${faq.id}bh-header`}
                            sx={{ background: 'rgba(0, 0, 0, .03)' }}
                        >
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {faq.answer.length ? <ul>
                            {faq.answer.map((d, index) => (
                                <li key={index}>{d}</li>
                            ))}
                        </ul>:<Typography variant="body1">{faq.answer}</Typography>}
                      
                        </AccordionDetails>
                    </Accordion>
                    ))}
                </Box>
            ))
            }

        </div >
    );
}
