import React from 'react';
function Footer(props) {

  return (
    <footer className="main-footer text-center fixed-bottom  border footer z-1" >
      <div className='d-flex justify-content-center'>
        <div className="pt-3" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_COPYRIGHT }} />
      </div>
    </footer>
  );
}

export default Footer;