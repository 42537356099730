import React, { useState, useEffect } from 'react';
import SearchBox from '../../components/reusableComponents/SearchBox';
import StudioAssociateListView from '../../components/reusableComponents/StudioAssociateListView';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudioAssociates, fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import AddStudioModal from './AddStudioModal';
import { studioAssociation } from '../../_services/studioAssociation/studioAssociation.service';
import NoResults from '../../components/reusableComponents/NoResults';
import { verifyRequestUID } from '../../_services/studioAssociation/verifyuid.service';
import SnackbarContainer from '../Snackbar';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import { associateStudioSearch } from '../../_services/AssociateStudioSearch/associateStudio.service';
import { SearchList } from '../../_services/studioAssociation/search.service';
import { Grid, TablePagination, useMediaQuery } from '@mui/material';
import { default_limit, record_limits } from '../../_services/_constants/recordLimitset.constant';

function StudioAssociateList() {
  const userData = useSelector(state => state.userData.userData);
  const associatesData = useSelector(state=>state.studioAssociatesStore.studioAssociatesStore)
  const [open, setOpen] = useState(false); //to open and close modal
  const [associatedStudioList, setAssociatedStudioList] = useState(associatesData?associatesData:[]); //all associated list
  const [requestUID, setRequestUID] = useState(null) //request uid
  const [requestUIDError, setRequestUIDError] = useState(false)
  const [requestUIDHelperText, setRequestUIDHelperText] = useState('')
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const handleOpen = () =>{ setOpen(true)}//modal handlers
  const handleClose = () => {setOpen(false);setSearchValue('') ;setRequestUID(null)}//modal handlers
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [totalCount,setTotalCount] = useState(associatesData?associatesData.total_count:0)
  const isNextButtonDisabled = associatesData.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const [quickSearch,setQuickSearch]=useState('')
  let smScreen = useMediaQuery('(max-width:768px)')
  const isPreviousButtonDisabled = page === 0;


  useEffect(() => {
    dispatch(setLoader(true))
    dispatch(fetchUserDetail());
  }, [userData.id]);

  useEffect(() => {
    if (userData.id && !quickSearch ) {
      //to immediate dispatch if search value set to null
      dispatch(fetchStudioAssociates(userData.id,rowsPerPage,page));
      const getStudioAssociated = setInterval(() => {
        dispatch(fetchStudioAssociates(userData.id,rowsPerPage,page));
      }, 30000);
      return () => clearInterval(getStudioAssociated);
    }
    if(quickSearch && quickSearch.length>=1)
    {     
          quickSearchApiHandler(quickSearch)
    }
  }, [userData.id, quickSearch, rowsPerPage, page]);

  useEffect(()=>{
    const getResult= setTimeout(()=>{
      if (searchValue === '') {
        setIsDropdownOpen(false);
        setFilteredOptions([]);
      } else {
          if(requestUID===null)   
             getSearchData(searchValue)
      }
    },400

    )
    return ()=>clearTimeout(getResult)
  },[searchValue])

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // This Search is inside the  modal
  const getSearchData = (value) => {
    dispatch(setLoader(true));
    associateStudioSearch({ searchValue: value }, 'SEARCH')
      .then(function (response) {
        dispatch(setLoader(false));
        if (response.status === 200) {
          setFilteredOptions(response.data.data);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  

  useEffect(() => {
    // Update the associatedStudioList state whenever associatesData changes in the Redux store
    setAssociatedStudioList(associatesData ? associatesData.results : []);
    setTotalCount(associatesData ? associatesData.total_count : 0);
  }, [associatesData]);

  const fetchAssociatedStudiosList = (id, limit, offset) => {
    dispatch(fetchStudioAssociates(id, limit, offset));
  };

  const handleSearch = (value) => {
    setSearchValue(value); 
  };

  const requestUIDChangeHandler = (e,newValue) =>{
    setRequestUID(newValue)
      if(e.target.value.length===0){
        setRequestUIDError(false)
        setRequestUIDHelperText('')
      }
      else if(e.target.value.length>0){
        const isValid = regexValidator(e.target.value,'alphanumeric')
        if(!isValid)
        {
          setRequestUIDError(true)
          setRequestUIDHelperText('Enter a valid GNID')
        }
      }
  }

  const verifyUID = (value) => {
    return new Promise((resolve, reject) => {
      verifyRequestUID({ uid: value })
        .then(function (response) {
          if (response.status === 200) {
            if (response && response.data && response.data.data === true)
              resolve(true);
            else{
              resolve(false);
              dispatch(setLoader(false));
              dispatch(setSnackBar(true));
              dispatch(setSnackBarVariant('error'));
              dispatch(setSnackBarMessage(formSnackbar.errors.InvalidGNID));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBar(true));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            reject(new Error('API call failed')); // Reject the promise with an error if the API call fails
          }
        })
        .catch(function (err) {
          dispatch(setLoader(false));
          dispatch(setSnackBar(true));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          reject(err); // Reject the promise with the error caught from the API call
        });
    });
  };
  const createConnection = async () => {
    dispatch(setLoader(true))
    const isValid = await verifyUID(requestUID.studio_gnid)
    if(!isValid){
      return
      }
    else
    { 
      const senderId = parseInt(userData.id); //current logged in user id
      const postData = {
        sender_id: senderId,
        receiver_id: requestUID.ID,
        sender_type: 'creator',
        receiver_type: 'studio',
        status: 'pending',
      };
      studioAssociation(postData, 'POST')
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch(setSnackBar(true));
          if (response && response.data && response.data.hasOwnProperty('isConnected')) 
          {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('warning'));
            dispatch(setSnackBarMessage(formSnackbar.studioAssociates.duplicateEntry));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            handleClose();
            setRequestUID(null)
            return;
          }
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('success'));
          dispatch(setSnackBarMessage(formSnackbar.studioAssociates.connectionRequestSuccess));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
          handleClose();
          setRequestUID(null)
          fetchAssociatedStudiosList(userData.id,rowsPerPage,page)
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  };
    
  const quickSearchApiHandler = (quickSearch) => {
   if(quickSearch && quickSearch.length !==''){
    const postData = {
      creator: userData.id,
      search:  quickSearch,
    };
    const fixedLimit = default_limit; // Fixed limit value
    const fixedOffset = 0; // Fixed offset value
    setRowsPerPage(default_limit)
    setPage(0)
    dispatch(setLoader(true));
    SearchList(postData, 'SEARCH', fixedLimit, fixedOffset)
      .then(function (response) {
        dispatch(setLoader(false));
        if (response.status === 200) {
          setAssociatedStudioList(response.data.results);
          setTotalCount(response.data.total_count);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
   }
  };
  
  const handleSearchInput = (value) => {
    setQuickSearch(value)
  };

  return (
    <div className={'p-4'}>
      <h2>Studio Associate</h2>
      <div className="d-flex justify-content-between mt-4">
        <div className="flex-grow-1" style={{ paddingRight: '1.5rem', maxWidth: '18rem' }}>
          <SearchBox  data={associatedStudioList} handleSearchInput={handleSearchInput}/>
        </div>
        <div>
          <button className="gn-actionbtn" onClick={handleOpen}>
            <div className='d-flex '>
              <AddIcon />
              <span style={{ marginLeft: '1px' }}>
                Associate
              </span>
            </div>
          </button>
        </div>
      </div>
      
      <div className='my-4'>
    
      {associatedStudioList && associatedStudioList.length > 0 ? (
        !smScreen ? (
          <Grid >
            {associatedStudioList.map((item, index) => (
            <div key={item.uid}>
            <StudioAssociateListView cardData={item} moduleName="studioList" fetchAssociatedStudiosList={fetchAssociatedStudiosList} />
          </div>
            ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              nextIconButtonProps={{ disabled: isNextButtonDisabled }} 
            />
          </Grid>
        ) : (
          <React.Fragment>
             {associatedStudioList.map((item, index) => (
               <div key={item.uid}>
               <StudioAssociateListView cardData={item} moduleName="studioList" fetchAssociatedStudiosList={fetchAssociatedStudiosList} />
             </div>
            ))}
                  <div
                className="d-flex justify-content-between mb-5"
                style={{ width: '100%', marginTop: '20px' }}
              >
                <div style={{width:'50%'}}>
                  <button
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={isPreviousButtonDisabled}
                    className={`pagination-button ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Previous
                  </button>
                </div>
                <div style={{ width: '20px' }}></div> {/* This creates a gap */}
                <div style={{width:'50%'}}>
                  <button
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={isNextButtonDisabled}
                    className={`pagination-button ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Next
                  </button>
                </div>
              </div>
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
        
        {/* {associatedStudioList && associatedStudioList.length > 0 ? (
          associatedStudioList.map((item, index) => (
          <div key={item.uid}>
              <StudioAssociateListView cardData={item} moduleName="studioList" fetchAssociatedStudiosList={fetchAssociatedStudiosList} />
            </div>
          ))
        ) : (
          <NoResults />
        )}
        <TablePagination
          rowsPerPageOptions={record_limits}
          colSpan={3}
          sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px', marginBottom: '2rem' }}
          className="cardPagination"
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          nextIconButtonProps={{ disabled: isNextButtonDisabled }}
        /> */}
      </div>

      <AddStudioModal
        open={open}
        handleClose={handleClose}
        requestUID = {requestUID}
        createConnection={createConnection}
        requestUIDChangeHandler={requestUIDChangeHandler}
        requestUIDError = {requestUIDError}
        requestUIDHelperText = {requestUIDHelperText}
        filteredOptions={filteredOptions}
        searchValue={searchValue}
        handleSearch={handleSearch}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        setRequestUID={setRequestUID}
      />
      <SnackbarContainer />
    </div>
  );
}

export default StudioAssociateList;
