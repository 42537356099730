import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { createrLink } from '../_constants/api.constant'
export async function postCreaterDetail(postData){
    const response=await axios.post(
        createrLink.POST,//" https://content_creater_gnapi.eela.tech/api/v1/link",
       postData,
        {
            // params:prarmTempObj,
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}