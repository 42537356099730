import React, {useEffect, useState} from 'react'
import { getLinkThumbnails } from "../../../_services/creater/addThumbnail.service."
import { useSelector, useDispatch } from "react-redux";;

const AddThumbnail = () => {
  const userData = useSelector((state) => state.userData.userData);
  const [getThumbnails, setGetThumbnails] = useState([]);
  useEffect(()=>{
    if (userData.id) {
      getLinkThumbnails(userData.id)
        .then(function (response) {
          if (response.data) {
              console.log('13', response.data)
              setGetThumbnails(response.data.data)
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          return error.response;
        });
        //setLinks(userDetail.link ? userDetail.link : []);
    }
  },[])

  const allthumbnails = getThumbnails.map((item)=>{ 
    return <div key={item.id}><img src={item.image} /></div>
  })

  return (
    <div>
       <div style={{display:'flex'}}>
            {allthumbnails}
       </div>
    </div>
  )
}

export default AddThumbnail
