import React, { useState, useEffect, useCallback } from 'react';
import SocialCard from './socialCard';
/* import { getSocial } from "../../_services/profile/social.service" */
import { getSocialTypes } from "../../_services/profile/socialType.service"
import { deleteSocial } from "../../_services/profile/deleteSocial.service"
import { useSelector, useDispatch } from 'react-redux'
import { getSocial } from "../../_services/profile/social.service"
import SnackbarContainer from '../Snackbar';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { faFacebookSquare, faTwitter, faLinkedin, faYoutubeSquare, faInstagramSquare, faSpotify, faImdb } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormControl, Grid, Icon, InputAdornment, InputLabel, makeStyles, Menu, MenuItem, OutlinedInput, Select, styled, TextField, Typography } from '@mui/material'
import { type } from '@testing-library/user-event/dist/type';

// Define the menu items and their corresponding icons
const menuItems = [
  { icon: faFacebookSquare, label: 'Facebook', value: 'facebook' },
  { icon: faTwitter, label: 'Twitter', value: 'twitter' },
  { icon: faLinkedin, label: 'LinkednIN', value: 'linkedin' },
  { icon: faYoutubeSquare, label: 'Youtube', value: 'youtube' },
  { icon: faInstagramSquare, label: 'Instagram', value: 'instagram' },
  { icon: faSpotify, label: 'Spotify', value: 'spotify' },
  { icon: faGlobe, label: 'Other', value: 'other' }
];

// Define the menu item style
const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px 20px',
  cursor: 'pointer',
};

// Define the menu item icon style
const menuItemIconStyle = {
  marginRight: '10px',
};

// Define the menu item label style
const menuItemLabelStyle = {
  flexGrow: 1,
};

function Social() {
  const userData = useSelector(state => state.userData.userData)
  const dispatch = useDispatch();
  const [socialLinks, setSocialLinks] = useState([])
  const [socialTypes, setSocialTypes] = useState([{
    "id": '',
    "socialType": {
      "id": 1,
      "socialType": "face",
      "socialTypeCode": "twitter_3",
      "created_at": "2023-05-05T14:11:45.251898Z",
      "updated_at": "2023-05-05T14:11:45.251945Z"
    },
    "image": null,
    "name": "My Link",
    "url": "http://www.teitter.com/test",
    "status": null,
    "user": 193
  }])
  const [disable, setDisable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setselectedType] = useState();
  const addNewLinkHandler = (socialTypeId) => {
    /* setDisable(true) */
    setSocialLinks([{}, ...socialLinks])
    let selectecType = socialTypes && socialTypes.find(social => social.id === socialTypeId)
    setselectedType(selectecType)
    handleMenuClose();
  }
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const linkDeleteHandler = (linkId, index) => {

    const items = [...socialLinks]
    if (linkId) {
      if (items.length > 0) {
        setSocialLinks(items.filter((item, index) => item.id !== linkId));
        setDisable(false)
        if (linkId != undefined) {
          dispatch(setSnackBar(true))
          dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkDelete))
          dispatch(setSnackBarVariant('info'))
          if (JSON.stringify(items[0]) === '{}') {
            setDisable(true)
          }
          let dataObj = {}
          dataObj.userId = userData.id;
          dataObj.linkId = linkId
          deleteSocial(dataObj.userId, dataObj.linkId).then(function (response) {
            //setSocialData(response.data ? response.data.data : [])
            if (response.status === 200 || response.status === 204) {
              setTimeout(() => dispatch(setSnackBar(false)), 2000)
              dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkDeleteSuccess))
              dispatch(setSnackBarVariant('success'))
            }
            else {
              //alert(`The service has beed responded with the error code ${response.status}`)
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
              dispatch(setSnackBarVariant('error'))
            }
          })
        }
      }
    } else {
      setSocialLinks(items.filter((item, i) => i !== index));
    }
  }

  const disableHandler = (arg, type) => {
    if (type === 'SAVE')
      setDisable(false)
    else {
      if (JSON.stringify(socialLinks[0]) === '{}')
        setDisable(true)
      else
        setDisable(false)
    }
  }

  const renderCard = useCallback((link, index) => {
    return (
      <SocialCard
        key={link.id}
        linkId={link.id}
        linkData={link} index={index}
        linkDeleteHandler={linkDeleteHandler}
        disableHandler={disableHandler}
        socialTypes={socialTypes}
        currentUserId={userData.id}
        reRenderSocialLinks={reRenderSocialLinks}
        selectedSocialType={link.id === undefined ? selectedType : link.socialType}
      /* selectedIcon = {getSocialTypeIcon(selectedType.socialTypeCode)} */
      />
    );
  }, [socialLinks]);

  const renderSocialTypes = () => {
    getSocialTypes(userData.id).then((response) => {
      console.debug("hjqhjjhqjh", response)
      if (response.status === 200) {
        dispatch(setLoader(false))
        setSocialTypes(response.data.data ? response.data.data : [])
        reRenderSocialLinks();
      }
      else {
        alert(`The service has been responded with the error code ${response.status}`)
      }
    })
  }

  const reRenderSocialLinks = () => {
    getSocial(userData.id)
      .then((response) => {
        if (response.status === 200) {
          setSocialLinks(response && response.data.data ? response.data.data.reverse() : {})
        }
        else {
          alert(`The service has been responded with the error code ${response.status}`)
        }
      })
  }

  useEffect(() => {
    //dispatch(setLoader(true))
    userData.id && renderSocialTypes()
    //userData.id && reRenderSocialLinks();
  }, [userData.id])
  const getSocialTypeIcon = (socialTypeC) => {
    switch (socialTypeC) {
      case 'facebook_1': {
        return faFacebookSquare
      }
      case 'instagram_2': {
        return faInstagramSquare
      }
      case 'twitter_3': {
        return faTwitter
      }
      case 'linkedin_4': {
        return faLinkedin
      }
      case 'youtube_5': {
        return faYoutubeSquare
      }
      case 'spotify_6': {
        return faSpotify
      }
      case 'website': {
        return faGlobe
      }
      case 'imdb': {
        return faImdb
      }
      default:
        return;
    }
  }

  return (
    <div>
      <div>
        <div className='p-0'>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h4 className="mb-2">Social platform</h4>
              <p className="mb-2 text-secondary">In which social media do you have your profile?</p>
            </Grid>
            {/* action button to add new social link */}
            {/* <button disabled={disable?'disabled':''} className='gn-actionbtn mb-3' onClick={addNewLinkHandler}>Add Link</button> */}
            <Grid item xs={12} sm={6} sx={{ textAlign: 'end' }}>
              <button className='gn-actionbtn' onClick={handleButtonClick} >
                ADD PLATFORM
              </button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {socialTypes && socialTypes.map((menuItem) => (
                  <MenuItem
                    key={menuItem.id}
                    style={menuItemStyle}
                    onClick={() => addNewLinkHandler(menuItem.id)}
                    value={menuItem.id}
                  >
                    <FontAwesomeIcon
                      icon={getSocialTypeIcon(menuItem.socialTypeCode)}
                      style={menuItemIconStyle}
                    />
                    <div style={menuItemLabelStyle}>{menuItem.socialType}</div>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
              {
                socialLinks && socialLinks.length > 0 && socialLinks.map((currentItem, index) => renderCard(currentItem, index))
              }
            </Grid>
          </Grid>
        </div>
        {/* social link container:end */}
      </div>
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </div>
  )
}

export default Social