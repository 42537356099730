import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import {
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import AudioPlayer from './customAudioPlayer';
import DeleteModal from './DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
export default function DemoResponsiveList({cardData,moduleName,handleDeleteItem}) {
    const ref = React.useRef();
    const showInListView = (module)=>{
        switch(module)
        {
          case 'demoList':{
            return(
              <>
                <div className='my-3 responsive-list'>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                  <div className='d-flex w-100 align-items-center'>
                      <div style={{padding:'0.5rem'}} title="Play audio">        
                        <AudioPlayer type="x-small" audioTile={cardData.audio_name} audioDescription={cardData.description} audioSrc={cardData.audio_clip?`${process.env.REACT_APP_BASEURL_MEDIA }/media/${cardData.audio_clip}`:''}/>     
                      </div>
                      <div style={{padding:'0.5rem',width:'calc(100% - 90px)'}}>
                         <Typography style={{maxWidth:'400px'}} component="div" variant="h6" noWrap overflow="hidden" textOverflow="ellipsis">
                          {cardData.audio_name}
                        </Typography>
                        <Typography style={{maxWidth:'350px'}} variant="subtitle1" color="text.secondary" noWrap overflow="hidden" textOverflow="ellipsis" component="div">
                          {cardData.description}
                        </Typography>
                      </div>
                      <div>
                        <div style={{position:'relative', bottom:'-20px', right:'7px'}}>
                            <button className='icononly-btn' onClick={()=>ref.current.handleOpen()}><DeleteIcon /></button>
                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              </>
            )
          }  
          default:{
            return;
          }
        }
    }
    return (
        <>
            {showInListView(moduleName)}
            <DeleteModal cardId={cardData.id} ref={ref} deleteHandler={handleDeleteItem}/>
        </>
    )
}