import axios from "axios";
import { getStateApi } from '../_constants/api.constant'
import {  getSelfToken } from '../../_helpers/auth/secureToken'

export async function getState(country_code) {

    const response = await axios.get(
        getStateApi.GET,
        {   params:{'search':country_code},
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}