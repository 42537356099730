import axios from "axios";
import { createrLink } from '../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function getCreaterLink(dataId,limit,offset) {
    const response = await axios.get(
        createrLink.GET + '?search='+dataId, //+ dataId,
        //    " https://content_creater_gnapi.eela.tech/api/v1/link/"+dataId,
        {
            // params:{
            //     limit:limit,offset:offset
            // },
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}