import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import zIndex from '@mui/material/styles/zIndex';

const ConfirmModal = React.forwardRef(({confirmHandler},ref) => {
const [open, setOpen] = React.useState(false);
const handleClose = () => setOpen(false);
const mediumViewport = useMediaQuery('(max-width:720px)');
const handleOpen = () =>setOpen(true)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport?'95%':'50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:'8px',
    boxShadow: 24,
    p: 4,
    maxWidth:600,
    zIndex:2
};

/* React.useImperativeHandle(ref, () => ({
    handleOpen(){setOpen(true)}
  })); */
  React.useImperativeHandle(ref, () => ({
    handleOpen: handleOpen
  }));

return (
    <div>
    <Modal
        open={open}
        /* onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
    >
        <Box sx={style}>
            <div className="bg-white">
                <section className="header mb-4">
                    <h4 style={{fontWeight:'900'}}>Warning</h4>
                </section>
                <section className="body">
                        <p style={{fontWeight: '500',marginBottom:'0.5rem'}}>Are you sure? Your changes will not be saved.</p>
                        <p style={{color: '#3333337a',fontWeight: '500'}}>This action cannot be undone and any changes you've made will be lost.</p>
                </section>
                <section className="footer d-flex justify-content-end">
                        <button className='gn-actionbtn outlined dark me-3' onClick={handleClose}>Cancel</button>
                        <button rea-label="Confirm" title="Confirm" className='gn-actionbtn' onClick={()=>confirmHandler()}>Confirm</button>
                </section>
            </div>
        </Box>
    </Modal>
    </div>
);
}) //forward ref ends here

export default ConfirmModal
