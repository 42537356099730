import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import playlist from '../../../../img/Frame 48096170.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import group from '../../../../img/Group.png'
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
export default function PlaylistCard({ playlistInfo }) {
  let history = useHistory();

  function handleClick(id) {
    history.push(`/playlistdetail/${playlistInfo.playlist_id}`);
  }
  const limitPlaylistName = (name, limit) => {
    if (name.length > limit) {
      return name.substring(0, limit) + '...';
    }
    return name;
  };
  return (
    <div className="box-card shadow-custom rounded-3" onClick={handleClick} role="button" tabIndex="0">
      <div style={{ position: 'relative' }}>
        {/* <div style={{ position: 'absolute', bottom: '0', left: '0', background: 'rgba(0,0,0,0.6)', color: '#fff', padding: '5px', fontSize: '12px' }}>
          {playlistInfo.playlist_song_count > 0 ? `${playlistInfo.playlist_song_count} Songs` : 'No Songs'}
        </div> */}
        <CardMedia
          component="img"
          style={{
            height: '120px',
          }}
          className="card-media"
          image={playlistInfo.playlist_cover_image ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + playlistInfo.playlist_cover_image : playlist}
        />
        <img
          src={group}
          alt=""
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            width: '30px',
            height: 'auto',
          }}
        />
      </div>
      <CardContent style={{ padding: '10px 0px' }}>
        <h6 title={playlistInfo.playlist_name}>{limitPlaylistName(playlistInfo.playlist_name, 26)}</h6>
        <div className='text-secondary'>
          {playlistInfo.privacy_status ? <span><PublicIcon />  Public</span> :

            <span> <LockIcon /> Private</span>}
    </div>
      </CardContent >
    </div >
  );
}