import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, Button, FormHelperText } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddAssociatedArtist from '../associatedArtist/AddAssociatedArtist';
import Autocomplete from '@mui/material/Autocomplete';
import { artistCode } from '../associatedArtist/artistStaticCodes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SongCredits = ({ data, handleInputChange, errors, releaseDate, releaseTime, releaseTimeChangeHandler,
    goLiveDate, goLiveDateChangeHandler, language, primaryArtList, remixerArtList, artistSelectChangeHandler, featureArtList, lyricistList,
    producerList, composerList, actorList, artistList, getAssociatedArtist, assoArtistRemoveHandler, recordLabelList }) => {
    /* Local state and media query */
    const [open, setOpen] = React.useState(false)
    const smallViewport = useMediaQuery('(max-width:768px)');
    //set min date from seven working days later from now
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 7)
    //check if date is still valid or not
    const isDateInvalid = releaseDate && new Date(releaseDate) < new Date();

    const handleOpenDrawer = () => {
        setOpen(true)
    }
    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleChangeMuliselect = (name, newValue, isNewArtist) => {
        switch (name) {
            case artistCode.primary_artist:
                {
                    artistSelectChangeHandler(artistCode.primary_artist, newValue, isNewArtist)
                    break;
                }
            case artistCode.feature_artist:
                {
                    artistSelectChangeHandler(artistCode.feature_artist, newValue)
                    break;
                }
            case artistCode.lyricist:
                {
                    artistSelectChangeHandler(artistCode.lyricist, newValue)
                    break;
                }
            case artistCode.composer:
                {
                    artistSelectChangeHandler(artistCode.composer, newValue)
                    break;

                }
            case artistCode.producer:
                {
                    artistSelectChangeHandler(artistCode.producer, newValue)
                    break;
                }
            case artistCode.actor:
                {
                    artistSelectChangeHandler(artistCode.actor, newValue)
                    break;
                }
            case artistCode.remixers:
                {
                    artistSelectChangeHandler(artistCode.remixers, newValue)
                    break;
                }
            default:
                break;
        }

    }
    return (
        <>
            <div>
                <div className="d-flex justify-content-between">
                    <p className='cardheading'>Add Song Credits</p>
                    <Button
                        onClick={handleOpenDrawer}
                        sx={{ mr: 1 }}
                        variant="text"
                        color='error'
                        style={{ minWidth: '100px', height: '40px', padding: 0 }}
                    >
                        <strong>ADD ARTIST</strong>
                    </Button>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="primary-artist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.primary_artist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={primaryArtList}
                            renderInput={(params) => (
                                <TextField {...params} name="primiary_artists" label="Primary Artist/Singer*" placeholder="Primary Artists" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="featuring-artist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.feature_artist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={featureArtList}
                            renderInput={(params) => (
                                <TextField {...params} name="feature_artists" label="Featuring/Artist" placeholder="Featuring Artists" />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="composer-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.composer, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={composerList}
                            renderInput={(params) => (
                                <TextField {...params} name="composers" label="Composer*" placeholder="Composer" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="lyricist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.lyricist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={lyricistList}
                            renderInput={(params) => (
                                <TextField {...params} name="lyricists" label="Lyricist" placeholder="Lyricist" />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="producer-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.producer, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={producerList}
                            renderInput={(params) => (
                                <TextField {...params} name="producers" label="Producer(Optional)" placeholder="Producer" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <FormControl
                            name='record_label'
                            noValidate
                            autoComplete="off"
                            fullWidth={true}
                            size='small'
                        >
                            <InputLabel>Record-Label(Optional)</InputLabel>
                            <Select
                                name='record_labels'
                                value={data.record_labels ? data.record_labels : ''}
                                label="Record-Label(Optional)"
                                onChange={handleInputChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {recordLabelList ? recordLabelList.map(recordlabel => (
                                    <MenuItem key={recordlabel.label_id} value={recordlabel.label_id}>{recordlabel.label_name}</MenuItem>
                                )) : <MenuItem value='null'></MenuItem>}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="size-small-outlined-multi"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.actor, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={actorList}
                            renderInput={(params) => (
                                <TextField {...params} name="actors" label="Actor(Optional)" placeholder="Actor" />
                            )}
                        />
                    </div>

                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="size-small-outlined-multi"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.remixers, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={remixerArtList}
                            renderInput={(params) => (
                                <TextField {...params} name="remixer" label="Remixer(Optional)" placeholder="Remixer(Optional)" />
                            )}
                        />
                    </div>
                </div>
            </div>
            {/* Add associated artist drawer:start */}
            <Drawer
                sx={{
                    width: '400px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper':
                    {
                        width: smallViewport ? '300px' : '400px',
                        boxSizing: 'border-box',
                        backgroundColor: '#F6F6F6'
                    },
                }}
                anchor="right"
                open={open}
            >
                <AddAssociatedArtist artistList={artistList} handleDrawerClose={handleDrawerClose} getAssociatedArtist={getAssociatedArtist}
                    handleChangeMuliselect={handleChangeMuliselect} assoArtistRemoveHandler={assoArtistRemoveHandler} />
            </Drawer>
            {/* End */}
        </>
    )
}

export default SongCredits