// export function getDateAsPerFormat(cDate,dateFormat)
// {   
//     const localDate = new Date(cDate) //convert date to local timezone
//     const date = new Date(localDate.toUTCString().slice(0, -4)); //convert date to utc date type
//     const seconds = date.getSeconds();
//     const minutes = date.getMinutes();
//     const hours = date.getHours();
//     const month = date.getMonth();
//     const year = date.getFullYear();
//     const dayOfTheMonth = date.getDate(); 
//     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//     const monthName = months[date.getMonth()];
//     switch (dateFormat)
//     {
//         case 'dd/MM/yyyy':{
//             return   `${dayOfTheMonth}/${month+1}/${year}`
//         }
//         case 'MM/dd/yyyy':{
//             return `${month+1}/${dayOfTheMonth}/${year}`
//         }  
//         case "DD Mon, YYYY":{
//             return `${dayOfTheMonth} ${monthName},${year}`
//         }  
//         default:{
//             return   `${dayOfTheMonth}/${month+1}/${year}`
//         }    
//     }
// }


export function getDateAsPerFormat(cDate, dateFormat) {
    const localDate = new Date(cDate); // Convert date to local timezone
    const dayOfTheMonth = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();
  
    switch (dateFormat) {
      case 'dd/MM/yyyy':
        return `${dayOfTheMonth}/${month + 1}/${year}`;
      case 'MM/dd/yyyy':
        return `${month + 1}/${dayOfTheMonth}/${year}`;
      case 'DD Mon, YYYY':
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];
        return `${dayOfTheMonth} ${monthName}, ${year}`;
      default:
        return `${dayOfTheMonth}/${month + 1}/${year}`;
    }
  }
  
export function getTime(cDate)
{
    const timestamp = cDate;
    const localTime = new Date(timestamp).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const gmtTime = new Date(timestamp).toUTCString();
    const time = new Date(timestamp).toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit', hour12: true});
    return time;
}