import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {AlbumEndpoint} from '../_constants/api.constant'
export async function reorderSongsInAlbum(playlistId,dataObj) {
    const response = await axios.put(
        AlbumEndpoint.SONG_REORDER_INALBUM+playlistId,
        dataObj,
        {   
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}