import axios from "axios";
import { creatorUID } from "../_constants/api.constant";
import { getSelfToken } from "../../_helpers/auth/secureToken";
export async function verifyRequestCreatorUID(postData){

        const response=await axios.post(
        creatorUID.VERIFY_CREATOR_UID,
        postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error);
        return error.response;
        });
        return response;
    
}