import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Skeleton, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import StarIcon from '../../../../img/StarIcon.png'
import reviewRating from '../../../../img/reviewRating.png'
import SearchBox from '../../../../components/reusableComponents/SearchBox';
import RatingReviewDialog from './RatingReviewDialog';
import ApiService from '../../../../_services/ApiService';
import { useSelector } from 'react-redux';
import { default_limit, record_limits } from '../../../../_services/_constants/recordLimitset.constant';
import NoResults from '../../../../components/reusableComponents/NoResults';
import SnackbarContainer from '../../../Snackbar';
import { useHistory } from 'react-router-dom';
import Pagination from './Pagination';
import SkeletonCard from '../../../../_helpers/reusablefunctions/SkeletonCard';
import playlist from '../../../../img/Frame 48096170.png'

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
export default function Discover() {
    const userData = useSelector((state) => state.userData.userData);
    const history = useHistory()
    const [songList, setSongList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [activeChip, setActiveChip] = useState('0-0');
    const [openRatingDialog, setOpenRatingDialog] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [totalCount, setTotalCount] = useState()
    const [playlistId, setPlaylistId] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [activeLabel, setActiveLabel] = useState('getSongList');

    const filterSongs = [
        { label: 'getSongList', value: ["All Songs"] },
        { label: 'trending', value: ["Trending Songs"] },
        { label: 'artist', value: ["Trending Artist"] },
        { label: 'getRelatedSongs', value: ["Related Songs"] },
        { label: 'getMySong', value: ['My Songs'] },
    ]

    function songlistApi(url) {
        const premium_user = userData?.enable_distribution ? 'True' : 'False';
        const userId = url == 'getMySong' ? userData?.id : null
        setLoader(true)
        setSongList([])
        ApiService.get('allsongslist', { promotion: false, premium_users: premium_user, limit: 25, offset: page, userId: userId }, url).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setSongList(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
        });
    }

    function tredingMusicApi(value) {
        let searchValue = (value == 'artist') ? '' : value;
        let trending_artists = value == 'artist' ? 'true' : ''
        setLoader(true)
        setSongList([])
        ApiService.get('allsongslist', { promotion: false, search: searchValue, trending_artists, limit: 25, offset: page }, 'search_allsongs').then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setSongList(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
        });
    }

    function getSearchList() {
        const premium_user = userData?.enable_distribution ? 'True' : 'False'
        setLoader(true)
        ApiService.get('searchSong', { search: searchInput, limit: 25, offset: page, premium_users: premium_user }).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setSongList(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(error => {
            console.log(error);
            console.log(error.response);
        })
    }

    useEffect(() => {
        if (searchInput != null && searchInput.length > 0) {
            let delaySearch;
            delaySearch = setTimeout(() => {
                getSearchList()
            }, 1000);
            return () => clearTimeout(delaySearch)
        } else {
            if (userData) {
                songlistApi('getSongList')
            }
        }
    }, [userData, page, rowsPerPage, searchInput])

    const handleChipClick = (i, label, value) => {
        setActiveChip(i)
        setActiveLabel(label)
        if (label == "trending" || label == 'artist') {
            tredingMusicApi(label)
        } else {
            songlistApi(label)
        }
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 25);
        const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function openRatingHandler(id) {
        setOpenRatingDialog(true)
        setPlaylistId(id)
    }

    const handleSearchInput = (value) => {
        setSearchInput(value)
    }

    function openReviews(data) {
        history.push("/reviews", { dialogValue: "Song", reviewViewDetail: data, preUrl: '/discover' });
    }

    const openDetails = (data) => {
        history.push('/music-detail', { musicData: data })
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box>
                <h2>Discover-Music</h2>
            </Box>
            <Box className="d-flex align-items-center justify-content-between my-4 flex-wrap gap-2">
                <Stack direction="row" spacing={1}>
                    {filterSongs.map((d, i) =>
                        d.value.map((v, index) => (
                            <StyledChip
                                key={`${i}-${index}`}
                                label={v}
                                component="a"
                                variant="outlined"
                                clickable
                                onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                                className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                            />
                        ))
                    )}
                </Stack>
                {activeLabel == 'getSongList' && <SearchBox handleSearchInput={handleSearchInput} />}
            </Box>
            <Box className='my-4'>
                <Grid container spacing={2}>
                    {loader ?
                        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                            <SkeletonCard />
                        </Grid>))
                        :
                        loader === false && songList.length === 0 ? (
                            <NoResults />
                        )
                            :
                            songList.map((data, index) => (<Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                                <Card>
                                    <CardMedia
                                        sx={{ height: 150 }}
                                        image={activeLabel == 'artist' && data?.user_avatar
                                            ? process.env.REACT_APP_BASEURL_MEDIA + 'media/' + data?.user_avatar
                                            : data.songPoster ?
                                             process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data?.songPoster
                                            : playlist}
                                        title="song-thumbnail"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => openDetails(data)}
                                    />
                                    <CardContent>
                                        <Tooltip title={activeLabel == 'artist' ? data?.name : data.songTitle}
                                            placement="top"
                                            arrow> <Typography className='text-truncate'>
                                                {activeLabel == 'artist' ? data?.name : data?.songTitle || '--'}
                                            </Typography>
                                        </Tooltip>
                                        {activeLabel == 'artist' ? null : <Typography variant="body2" sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                                            {data?.artists || '--'}
                                        </Typography>}
                                        {activeLabel == 'artist' ? null : <Typography variant="body2" sx={{ color: 'text.secondary' }} component="div" className='d-flex align-items-center gap-2'>
                                            <img src={StarIcon} alt='StarIcon' />
                                            {data?.song_rating ? `${data.song_rating.average_rating}/5 (${data.song_rating.rating_count} Rating)` : '0/5 (0 Rating)'}
                                        </Typography>}
                                    </CardContent>
                                    {activeLabel == 'artist' ? null :
                                        <CardActions>
                                            <Button disabled={data.userId == userData?.id} size="small" color="primary" className='gap-2' onClick={() => data.userId == userData?.id ? null : openRatingHandler(data.id)}>
                                                <img src={reviewRating} alt='review&rating' />
                                                Rating & Review
                                            </Button>
                                            <Button size="small" color="primary" className='gap-2' onClick={() => openReviews(data)}>
                                                View Reviews
                                            </Button>
                                        </CardActions>}
                                </Card>
                            </Grid>))}
                    <Pagination
                        totalCount={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>
            <RatingReviewDialog dialogValue="Song" listApiCall={songlistApi} songId={playlistId} openRatingDialog={openRatingDialog} setOpenRatingDialog={setOpenRatingDialog} />
            <SnackbarContainer />
        </Box>
    )
} 