export const actionTypes = {
    FETCH_OVERVIEW_DATA_REQUEST: 'FETCH_OVERVIEW_DATA_REQUEST ',
    FETCH_OVERVIEW_DATA_FAILURE: 'FETCH_OVERVIEW_DATA_FAILURE ',
    FETCH_OVERVIEW_DATA_SUCCESS: 'FETCH_OVERVIEW_DATA_SUCCESS ',

    //  -------------------- TRACK TYPE --------------

    FETCH_TRACK_DATA_REQUEST: 'FETCH_TRACK_DATA_REQUEST ',
    FETCH_TRACK_DATA_FAILURE: 'FETCH_TRACK_DATA_FAILURE ',
    FETCH_TRACK_DATA_SUCCESS: 'FETCH_TRACK_DATA_SUCCESS ',

    //  -------------------- ALBUM TYPE --------------

    FETCH_ALBUM_DATA_REQUEST: 'FETCH_ALBUM_DATA_REQUEST ',
    FETCH_ALBUM_DATA_FAILURE: 'FETCH_ALBUM_DATA_FAILURE ',
    FETCH_ALBUM_DATA_SUCCESS: 'FETCH_ALBUM_DATA_SUCCESS ',

    //  -------------------- SINGLE_STORE TYPE --------------

    FETCH_SINGLE_STORE_DATA_REQUEST: 'FETCH_SINGLE_STORE_DATA_REQUEST ',
    FETCH_SINGLE_STORE_DATA_FAILURE: 'FETCH_SINGLE_STORE_DATA_FAILURE ',
    FETCH_SINGLE_STORE_DATA_SUCCESS: 'FETCH_SINGLE_STORE_DATA_SUCCESS ',

    //  -------------------- SINGLE_COUNTRY TYPE --------------

    FETCH_SINGLE_COUNTRY_DATA_REQUEST: 'FETCH_SINGLE_COUNTRY_DATA_REQUEST ',
    FETCH_SINGLE_COUNTRY_DATA_FAILURE: 'FETCH_SINGLE_COUNTRY_DATA_FAILURE ',
    FETCH_SINGLE_COUNTRY_DATA_SUCCESS: 'FETCH_SINGLE_COUNTRY_DATA_SUCCESS ',

    FETCH_SINGLE_ALBUM_DATA_REQUEST: 'FETCH_SINGLE_ALBUM_DATA_REQUEST ',
    FETCH_SINGLE_ALBUM_DATA_FAILURE: 'FETCH_SINGLE_ALBUM_DATA_FAILURE ',
    FETCH_SINGLE_ALBUM_DATA_SUCCESS: 'FETCH_SINGLE_ALBUM_DATA_SUCCESS ',

     //  -------------------- STORE TYPE --------------

     FETCH_STORE_DATA_REQUEST: 'FETCH_STORE_DATA_REQUEST ',
     FETCH_STORE_DATA_FAILURE: 'FETCH_STORE_DATA_FAILURE ',
     FETCH_STORE_DATA_SUCCESS: 'FETCH_STORE_DATA_SUCCESS ',

     FETCH_COUNTRY_DATA_REQUEST: 'FETCH_COUNTRY_DATA_REQUEST ',
     FETCH_COUNTRY_DATA_FAILURE: 'FETCH_COUNTRY_DATA_FAILURE ',
     FETCH_COUNTRY_DATA_SUCCESS: 'FETCH_COUNTRY_DATA_SUCCESS ',
     
     FETCH_MONTH_DATA_REQUEST: 'FETCH_MONTH_DATA_REQUEST ',
     FETCH_MONTH_DATA_FAILURE: 'FETCH_MONTH_DATA_FAILURE ',
     FETCH_MONTH_DATA_SUCCESS: 'FETCH_MONTH_DATA_SUCCESS ',


     FETCH_VIDEO_DATA_REQUEST: 'FETCH_VIDEO_DATA_REQUEST ',
     FETCH_VIDEO_DATA_FAILURE: 'FETCH_VIDEO_DATA_FAILURE ',
     FETCH_VIDEO_DATA_SUCCESS: 'FETCH_VIDEO_DATA_SUCCESS ',
     // ------------------------- SINGLE TRACK TYPE ----------------

     FETCH_SINGLE_TRACK_DATA_REQUEST: 'FETCH_SINGLE_TRACK_DATA_REQUEST',
     FETCH_SINGLE_TRACK_DATA_SUCCESS:'FETCH_SINGLE_TRACK_DATA_SUCCESS',
     FETCH_SINGLE_TRACK_DATA_FAILURE:'FETCH_SINGLE_TRACK_DATA_FAILURE',

     FETCH_SINGLE_VIDEO_DATA_REQUEST: 'FETCH_SINGLE_VIDEO_DATA_REQUEST',
     FETCH_SINGLE_VIDEO_DATA_SUCCESS: 'FETCH_SINGLE_VIDEO_DATA_SUCCESS',
     FETCH_SINGLE_VIDEO_DATA_FAILURE: 'FETCH_SINGLE_VIDEO_DATA_FAILURE'

}
export const SET_TAB_VALUE = 'SET_TAB_VALUE'
