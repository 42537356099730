import axios from "axios";
import {  getSelfToken } from '../../_helpers/auth/secureToken'
import { createrLink } from '../_constants/api.constant'
export async function deleteCreaterDetail(dataObj) {
    let localDataObj = dataObj;
    const response = await axios.delete(
        createrLink.POST/* +'?id=' +localDataObj.linkId, */,
        {
            params:{
                id:dataObj.linkId
            },
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        //window.location.reload()
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}