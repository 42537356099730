import React from 'react'
import DeleteModal from '../../components/reusableComponents/DeleteModal'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ExperienceCard = ({cardData,onDeleteHandler,handleDrawerOpen}) => {
  const ref = React.useRef()
  return (
    <div>
           <div className="border rounded p-2 bg-white my-1" key={cardData.id}>
                <div className='d-flex justify-content-between'>
                    {/* <h6>{d.title + " " + d.companyName} </h6> */}
                    <h6>{cardData.companyName} </h6>
                    <div>
                        {/* edit button  */}
                        <button title="Edit" className='gn-btn-icononly text-secondary' key={`edit_${cardData.id}`} onClick={()=>handleDrawerOpen(cardData.id)} > <EditIcon /> </button>  
                        {/* {open && props.saveExperienceLevel} */}
                        <button title="Delete" className='gn-btn-icononly text-secondary' key={`delete_${cardData.id}`}> {cardData && cardData.id!==undefined?<span id={cardData.id} onClick={()=>ref.current.handleOpen()}> <DeleteIcon /></span>:<span id={cardData.id}></span>}</button>
                    </div>
                </div>
                {/* <span className="text-secondary">{convertDateTime(d.startingYear) + " to " + convertDateTime(d.endYear)}</span> */}
                    {/* for delete modal */}
                    <DeleteModal cardId={cardData && cardData.id} deleteHandler={onDeleteHandler} ref={ref}/>
                    {/* --------End------- */}
            </div>
    </div>
  )
}

export default ExperienceCard