import { actionTypes, SET_TAB_VALUE } from './royaltyDataTypes';

const initialState = {
  loading: false,
  overviewData: "",
  tabValue: "",
  trackData: [],
  albumData: [],
  singlestoreData: [],
  singlecountryData: [],
  singlealbumData: [],
  singleTrackData: [],
  singlevideoData: [],
  storeData: [],
  countryData: [],
  monthData: [],
  videoData: [],
  error: '',
};

const reducer = (state = initialState, action) => {
  const actionHandlers = {
    [actionTypes.FETCH_OVERVIEW_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_OVERVIEW_DATA_SUCCESS]: { loading: false, overviewData: action.payload, error: '' },
    [actionTypes.FETCH_OVERVIEW_DATA_FAILURE]: { loading: false, overviewData: [], error: action.payload },

    [actionTypes.FETCH_TRACK_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_TRACK_DATA_SUCCESS]: { loading: false, trackData: action.payload, error: '' },
    [actionTypes.FETCH_TRACK_DATA_FAILURE]: { loading: false, trackData: [], error: action.payload },

    [actionTypes.FETCH_SINGLE_TRACK_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SINGLE_TRACK_DATA_SUCCESS]: { loading: false, singletrackData: action.payload, error: '' },
    [actionTypes.FETCH_SINGLE_TRACK_DATA_FAILURE]: { loading: false, singletrackData: [], error: action.payload },

    [actionTypes.FETCH_ALBUM_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_ALBUM_DATA_SUCCESS]: { loading: false, albumData: action.payload, error: '' },
    [actionTypes.FETCH_ALBUM_DATA_FAILURE]: { loading: false, albumData: [], error: action.payload },

    [actionTypes.FETCH_STORE_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_STORE_DATA_SUCCESS]: { loading: false, storeData: action.payload, error: '' },
    [actionTypes.FETCH_STORE_DATA_FAILURE]: { loading: false, storeData: [], error: action.payload },

    [actionTypes.FETCH_SINGLE_STORE_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SINGLE_STORE_DATA_SUCCESS]: { loading: false, singlestoreData: action.payload, error: '' },
    [actionTypes.FETCH_SINGLE_STORE_DATA_FAILURE]: { loading: false, singlestoreData: [], error: action.payload },

    [actionTypes.FETCH_SINGLE_VIDEO_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SINGLE_VIDEO_DATA_SUCCESS]: { loading: false, singlevideoData: action.payload, error: '' },
    [actionTypes.FETCH_SINGLE_VIDEO_DATA_FAILURE]: { loading: false, singlevideoData: [], error: action.payload },

    [actionTypes.FETCH_SINGLE_COUNTRY_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SINGLE_COUNTRY_DATA_SUCCESS]: { loading: false, singlecountryData: action.payload, error: '' },
    [actionTypes.FETCH_SINGLE_COUNTRY_DATA_FAILURE]: { loading: false, singlecountryData: [], error: action.payload },

    [actionTypes.FETCH_SINGLE_ALBUM_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SINGLE_ALBUM_DATA_SUCCESS]: { loading: false, singlealbumData: action.payload, error: '' },
    [actionTypes.FETCH_SINGLE_ALBUM_DATA_FAILURE]: { loading: false, singlealbumData: [], error: action.payload },

    [actionTypes.FETCH_COUNTRY_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_COUNTRY_DATA_SUCCESS]: { loading: false, countryData: action.payload, error: '' },
    [actionTypes.FETCH_COUNTRY_DATA_FAILURE]: { loading: false, countryData: [], error: action.payload },

    [actionTypes.FETCH_MONTH_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_MONTH_DATA_SUCCESS]: { loading: false, monthData: action.payload, error: '' },
    [actionTypes.FETCH_MONTH_DATA_FAILURE]: { loading: false, monthData: [], error: action.payload },

    [actionTypes.FETCH_VIDEO_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_VIDEO_DATA_SUCCESS]: { loading: false, videoData: action.payload, error: '' },
    [actionTypes.FETCH_VIDEO_DATA_FAILURE]: { loading: false, videoData: [], error: action.payload },
    
    [SET_TAB_VALUE]: {tabValue: action.payload, error: '' },
  };

  const handler = actionHandlers[action.type];
  return handler ? { ...state, ...handler } : state;
};

export default reducer;
