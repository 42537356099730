import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { studioAssociationURL } from '../_constants/api.constant'
export async function verifyRequestUID(postData){
    {
        const response=await axios.post(
        studioAssociationURL.VERIFY_UID,
        postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error);
        return error.response;
        });
        return response;
    }
}