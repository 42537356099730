import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {AlbumEndpoint} from '../../_services/_constants/api.constant'
export async function removeSongFromAlbum(playlistId,data) {
    console.debug("wcjbkcjkekbjkjb:songId", data)
    let paramObj =new FormData()
    paramObj.append("song_ids", data)
    const response = await axios.post(
        AlbumEndpoint.REMOVE_SONG_FROM_ALBUM+playlistId,paramObj,
        {   
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}