import React, { useState, useEffect } from 'react';
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { getCategory } from '../../_services/profile/getCategory.service'
import { updateCategory } from '../../_services/profile/updateCategory.service'
import { useSelector, useDispatch } from 'react-redux'
import { setLoader, setSnackBar, setSnackBarMessage,setSnackBarVariant } from '../../redux';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'

function Category(props) {
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);
    const [categories, setCategories] = useState([])
    const [userCatogory, setUserCatogory] = useState()
    const dispatch = useDispatch();

    useEffect(function () {
        setUserCatogory(userDetail ? userDetail.category : "")
    }, [userDetail]);

    useEffect(function () {
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
        }
    }, [userData.id]);
    useEffect(function () {
        //dispatch(setLoader(true))
        getCategory().then(function (response) {
            dispatch(setLoader(false))
            setCategories(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            // props.networkErrorDialogToggle(!props.dialogStatus);
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, []);

    function UpdateUserCategory(postData) {
        dispatch(setLoader(true))
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(formSnackbar.PRE_CATEGORY_UPDATE))
        dispatch(setSnackBarVariant('info'))
        updateCategory(postData).then(function (response) {
            dispatch(setLoader(false))
            if(response.status === 200)
            {
             dispatch(setSnackBarMessage(formSnackbar.POST_CATEGORY_UPDATE))
             dispatch(setSnackBarVariant('success'))
             setTimeout(() =>  dispatch(setSnackBar(false)), 2000);
            }
            else
            {   
                dispatch(setSnackBarVariant('error'))
                dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
            }
        })
    }

    function onChangeCategoryHandler(e, data) {
        if (userCatogory.find(d => e.target.id == d.id)) {
            let currentSelected = userCatogory.filter(r => r.id != e.target.id);
            setUserCatogory([...currentSelected])
            let selectedIds = currentSelected?currentSelected.length>0?currentSelected.map(obj=>obj.id):[]:[] 
            var postData = {}
            postData['user'] = userData.id;
            postData['category'] = selectedIds.join();
            UpdateUserCategory(postData)
        } else {
            setUserCatogory([...userCatogory, data])
            let selectedIds = [];
            selectedIds = userCatogory?userCatogory.length>0?userCatogory.map(obj=>obj.id):[]:[]
            selectedIds  = [...selectedIds,data.id]
            var postData = {}
            postData['user'] = userData.id
            postData['category'] = selectedIds.join(',')
            UpdateUserCategory(postData)
        }
    }
    return (
        <div className="py-2">
            <h4 className="mb-2">Category</h4>
                <div className='text-secondary'>
                    <p>We offer a range of services for musicians, singers, and other audio professionals</p>
                </div>
            {/* <div className="card h-auto account-bg"> */}
                {/* <div className=""> */}
                    {/* <p>Tell us about the work what you do?</p> */}
                    
                    <div className='py-1 row px-4  '>
                        {categories ? categories.map(d => (
                            <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id}>
                                <div className="form-check-inline d-inline-flex">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={(e) => onChangeCategoryHandler(e, d)}
                                                id={d.id.toString()}
                                                name={d.id.toString()}
                                                checked={userCatogory ? userCatogory.filter(v => v.id == d.id).length ? true : false : false}
                                            />}
                                            label={d.category} />
                                            
                                    </FormGroup>
                                </div>
                            </div>
                        )) : null}
                    </div>
                {/* </div> */}
            {/* </div> */}
        </div>
    );
}

export default Category;