import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PersonIcon from '@mui/icons-material/Person';

const musicicon = <QueueMusicIcon />
const audioicon = <AudiotrackIcon />
const videoicon = <VideoLibraryIcon />
const storeicon = <LibraryMusicIcon />
const monthicon = <CalendarTodayIcon />
const countryicon = <FmdGoodIcon />

export const tabDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease' },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track' },
    { id: 3, tabActiveId: "3",  tabTitle: 'STORES', tabType: 'stores' },
    { id: 4, tabActiveId: "4",  tabTitle: 'MONTHS', tabType: 'months' },
    { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country' }
]
export const trackDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease' },
    { id: 2, tabActiveId: "2",  tabTitle: 'STORES', tabType: 'stores' },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos' },
    // { id: 3, tabActiveId: "3", tabTitle: 'MONTHS', tabType: 'months' },
    { id: 4, tabActiveId: "4",  tabTitle: 'COUNTRIES', tabType: 'country' }
]

export const storeDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease' },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track' },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos' },
    // { id: 4, tabActiveId: "4",  tabTitle: 'MONTHS', tabType: 'months' },
    { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country' }
]
export const monthDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease' },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track' },
    { id: 3, tabActiveId: "3", tabTitle: 'VIDEOS', tabType: 'videos' },
    { id: 4, tabActiveId: "4",  tabTitle: 'STORES', tabType: 'stores' },
    { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country' }
]
export const countryDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease' },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track' },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos' },
    { id: 4, tabActiveId: "4",  tabTitle: 'STORES', tabType: 'stores' },
    // { id: 5, tabActiveId: "5",  tabTitle: 'MONTHS', tabType: 'months' },
]

export const albumDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'TRACKS', tabType: 'track' },
    { id: 2, tabActiveId: "2", tabTitle: 'VIDEOS', tabType: 'videos' },
    { id: 3, tabActiveId: "3",  tabTitle: 'STORES', tabType: 'stores' },
    // { id: 4, tabActiveId: "4", tabTitle: 'MONTHS', tabType: 'months' },
    { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country' }
]
