import React from 'react';
import { Autocomplete, FormControl, Radio, TextField } from '@mui/material';

const General = ({ data,errors,  handleInputChange, genre, language,demoType ,handleLanguageChange,handleGenreChange,selectedGenre,selectedLanguage}) => {
  const filteredLanguages = language.filter(lang => selectedLanguage.every(selLang => selLang.id !== lang.id));
  const filteredGenres = genre.filter(gen => selectedGenre.every(selGen => selGen.id !== gen.id));

  return (
    <>
      <div>
        <p className="cardheading">General</p>
        <div className="form-group mt-2 mb-3">
          <FormControl name="demo_name" noValidate autoComplete="off" fullWidth={true}>
            <TextField
              size="small"
              value={data.demo_name}
              onChange={handleInputChange}
              error={errors.demo_name}
              inputProps={{ maxLength: 255 }}
              helperText={errors.demo_name_helperTxt}
              name="demo_name"
              label="Song Name*"
              variant="outlined"
              className="w-100 rounded"
            />
          </FormControl>
        </div>
        <div className="form-group mt-2 mb-3">
        <Autocomplete
          multiple
          id="tags-outlined-genre"
          options={filteredGenres}
          size='small'
          value={selectedGenre}
          getOptionLabel={(option) => option.genre}
          filterSelectedOptions
          onChange={handleGenreChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Genre*"
              placeholder="Genre"
            />
          )}
        />
        </div>
        <div className="form-group mt-2 mb-3">
          <Autocomplete
            multiple
            id="tags-outlined-language"
            options={filteredLanguages}
            size='small'
            value={selectedLanguage}
            getOptionLabel={(option) => option.language}
            filterSelectedOptions
            onChange={handleLanguageChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Languages*"
                placeholder="Language"
              />
            )}
          />
        </div>

        <div className="form-group mt-2 mb-3">
          <FormControl className="mt-4" fullWidth>
            <TextField
              id="outlined-multiline-static"
              label="Describe your demo and interest in music*"
              name="demo_description"
              multiline
              type="text"
              error={errors.demo_description}
              helperText={errors.demo_description_helperTxt}
              value={data.demo_description}
              rows={6}
              sx={{ width: '100%' }}
              onChange={handleInputChange}
            />
          </FormControl>
        </div>
        <div className="form-group mt-2 mb-3">
          <p>Demo Version*</p>
          <Radio
            checked={demoType === 'True'}
            onChange={handleInputChange}
            value="True"
            name="demo_type"
            inputProps={{ 'aria-label': 'Yes' }}
          />
     
          Scratch
          <Radio
            checked={demoType === 'False'}
            onChange={handleInputChange}
            value="False"
            name="demo_type"
            inputProps={{ 'aria-label': 'No' }}
          />

          Final
        </div>
      </div>
    </>
  );
};

export default General;
