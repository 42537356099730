import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PlaylistCard from './AlbumCard';
// import PlaylistDrawer from './AlbumDrawer';
import { Link } from 'react-router-dom';
import SnackbarContainer from '../Snackbar';
import { getAllPlaylistOfUser } from '../../_services/playlist/getAllPlaylistOfUser';
import CardSekeleton from '../../components/reusableComponents/SkeletonCard';
import NoResults from '../../components/reusableComponents/NoResults';
import AlbumCard from './AlbumCard';
import AlbumDrawer from './AlbumDrawer';
import { getAllAlbumOfUser } from '../../_services/album/getAllAlbumOfUser';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { artistCode } from '../submitSong/associatedArtist/artistStaticCodes';
import { handleArtistData } from '../submitSong/helperComponent/helperComponent';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { fetchReleaseAlbum } from '../../redux/releaseAlbum/releaseAlbumDataActions';
import InfiniteScroll from 'react-infinite-scroll-component';

function AllAlbumSong(props) {
  const userData = useSelector((state) => state.userData.userData);
  const loader = useSelector((state) => state.loader.loader);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [artistList, setArtistList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [albumList, setAlbumList] = useState([]);
  const [searchInput, setSearchInput] = useState(null)
  const [hasMore, setHasMore] = useState(false)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [flag, setFlag] = useState(false);
  const numberOfSkeletonCards = 12
  const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSekeleton key={index} />
  ));

  const albumData = useSelector((state) => state.releaseAlbumReducer?.releaseAlbumData)
  useEffect(() => {
    if (userData.id != undefined) {
      if (page == 0) {
        dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: page }, [], null));
        getAssociatedArtist();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [userData.id]); // Make sure to add albumList and page as dependencies

  useMemo(() => {
    dispatch(setLoader(true))
    if (albumData.data) { // Check if albumList is empty before setting
      dispatch(setLoader(false))
      setAlbumList(albumData.data);
    }
  }, [albumData])

  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode);
        break;

      default:
        break;
    }
  }


  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    associatedArtistsRequestHandler({ 'userId': userData.id }, 'GET')
      .then(response => {
        //set the associated artist list
        setArtistList(response.data.results)
        isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist)  //call the handler when new artist has beed added
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const breadcrumbs = [
    { label: 'Release Song', link: '/releases' },
    { label: 'Album', link: '/releasealbum' },
  ];

  const addAlbum = (newAlbum) => {
    //to rerender the list
    // getAlbumList()
    let d = []
    dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: page }, d, null));
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const fetchMoreData = () => {
    setFlag(true);
    setPage(page + 1)
    const nextPage = page + 1; // Calculate the next page value
    if (albumList.length != albumData.totalCount) {
      setPage(nextPage); // Update the page state
      dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: nextPage }, albumData.data, null)); // Dispatch action with updated page value
    } else {
      setFlag(false);
      // dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: nextPage }, albumData.data));
    }
  };

  const handleSearchInput = (value) => {
    setSearchInput(value)

  }

  // useEffect(
  //   function () {
  //       if (searchInput != '') {
  //           let delaySearch;
  //           // dispatch(loaderData(true))
  //           delaySearch = setTimeout(() => {
  //             dispatch(fetchReleaseAlbum(null, null, [],searchInput))
  //           }, 1000);
  //           return () => clearTimeout(delaySearch);
  //       } else {
  //         dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: page }, [], null))
  //           // dispatch(removeSearchList([]));
  //           // dispatch(removeSearch(''));
  //       }
  //   },
  //   [searchInput]
  // );

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Release Song</h2>
          </div>
          {/* <div className='d-flex justify-content-between'>
            <div className='flex-grow-1' style={{maxWidth:'18rem'}}>
              <SearchBox handleSearchInput={handleSearchInput} value={searchInput}/>
            </div>
        </div> */}
          <>
            <div className="my-3">
              <button className="gn-actionbtn" onClick={openDrawer}>
                CREATE ALBUM
              </button>
              <div className="pt-4">
                {loader ?
                  <div className="wrapper-card">

                    {skeletonCards}
                  </div> :

                  albumList.length > 0 ?
                    <InfiniteScroll
                      dataLength={albumList != undefined ? albumList.length : []}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={flag == true ? <>Loading........</> : false}
                    >
                      <div className="wrapper-card">

                        {albumList.map((album, index) => (
                          <AlbumCard
                            key={album.album_id}
                            albumInfo={album}
                          />
                        ))}</div> </InfiniteScroll> :
                    <NoResults />
                }
              </div>
            </div>
            <AlbumDrawer
              isOpen={isDrawerOpen}
              onClose={closeDrawer}
              onCreateAlbum={addAlbum}
              artistSelectChangeHandler={artistSelectChangeHandler}
              primaryArtList={primaryArtList}
              artistList={artistList}
            />
          </>
        </div>
      </div>
      <SnackbarContainer />
    </div>
  );
}

export default AllAlbumSong;
