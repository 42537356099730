import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Badge,
  Avatar,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  setLoader,
  setSnackBar,
  setSnackBarVariant,
  setSnackBarMessage,
  fetchStudioAssociates,
} from "../../redux";
import { notificationRequestHandler } from "../../_services/notifications/notifications.service";
import { studioAssociation } from "../../_services/studioAssociation/studioAssociation.service";
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import { getTime } from "../../_helpers/reusablefunctions/dateSetter";
import Notification from "../../img/general/Notification.svg";
import NotificationLarge from "../../img/general/Notificationbig.svg";

const NotificationComponent = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationBox, setNotificationBox] = useState(null);
  const openMenuNotification = Boolean(notificationBox);
  const [notificationHitCount, setNotificationHitCount] = useState(0);
  let xsScreen = useMediaQuery('(max-width:380px)')

  useEffect(() => {
    if (userData.id) {
      if (notificationHitCount === 0) {
        notificationData(userData.id);
        setNotificationHitCount((prevState) => prevState + 1);
      }
      const getData = setInterval(() => {
        notificationData(userData.id);
      }, 30000);
      return () => clearInterval(getData);
    }
  }, [userData.id]);

  const handleClickListItem = (event) => {
    setNotificationBox(event.currentTarget);
  };

  const closeNotificationMenu = () => {
    setNotificationBox(null);
  };

  function notificationData(id) {
    notificationRequestHandler({ id: id }, "GET")
      .then(function (response) {
        setNotification(response.data.notifications);
        setNotificationCount(response.data.unread_notifications_count);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
      });
  }

  const acceptRequest = (notification) => {
    const postData = {
      sender_id: notification.sender_id,
      receiver_id: notification.receiver_id,
      status: "accepted",
    };
    studioAssociation(postData, "UPDATE")
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setLoader(false));
          //handleClose();
          dispatch(fetchStudioAssociates(userData.id));
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant("error"));
          dispatch(
            setSnackBarMessage(
              formSnackbar.serviceRequest.serviceRequestFailure
            )
          );
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const rejectedRequest = (notification) => {
    const postData = {
      sender_id: notification.sender_id,
      receiver_id: notification.receiver_id,
      status: "rejected",
    };
    studioAssociation(postData, "UPDATE")
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setLoader(false));
          dispatch(fetchStudioAssociates(userData.id));
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant("error"));
          dispatch(
            setSnackBarMessage(
              formSnackbar.serviceRequest.serviceRequestFailure
            )
          );
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateNotification = (notificationId) => {
    setNotificationBox(false);
    notificationRequestHandler(
      { notification_id: notificationId, is_read: true },
      "PUT"
    )
      .then(function (response) {
        notificationData(userData.id);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  function handleMarkAllAsRead() {
    setNotificationBox(false);
    notificationRequestHandler({ user_id: userData.id }, "MARK_ALL_READ")
      .then(function (response) {
        notificationData(userData.id);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Notification">
          <IconButton
            onClick={handleClickListItem}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={
              openMenuNotification ? "notification-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openMenuNotification ? "true" : undefined}
          >
            <Badge badgeContent={notificationCount}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      {/* list part goes here */}
      <Menu
  id="notification-menu"
  notificationBox={notificationBox}
  open={openMenuNotification}
  onClose={closeNotificationMenu}
  PaperProps={{
    elevation: 0,
    sx: {
      overflow: "auto",
      maxHeight: "350px",
      maxWidth: xsScreen ? '250px' : '350px',
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 80,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  }}
  anchorOrigin={{ horizontal: "right", vertical: "top" }}
>
  {[
    <div
      key="header"
      className="d-flex justify-content-between p-3 flex-wrap sticky-header"
      style={{ width: "100%" }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: "14px", fontWeight: 'bold' }}>Notification</span>
      </div>
      <div>
        <button
          className="text-danger"
          style={{
            fontSize: "14px",
            border: 0,
            background: "transparent",
          }}
          onClick={handleMarkAllAsRead}
        >
          <strong>Mark all as read</strong>
        </button>
      </div>
    </div>,
    notification && notification.length === 0 ? (
      <div
        key="no-notifications"
        className="d-flex align-items-center justify-content-center flex-column p-4"
      >
        <img
          src={NotificationLarge}
          alt="Image not found"
          style={{ width: '5rem', height: '5rem' }}
        />
        <h4>You have no notifications</h4>
      </div>
    ) : (
      notification.map((item) => (
        <div
          key={item.notification_id}
          onClick={() => updateNotification(item.notification_id)}
        >
          <div style={{ background: item.is_read ? "" : "#e9ecef" }}>
            <div className="d-flex notificationItem p-2">
              <div>
                <img
                  src={Notification}
                  style={{ width: "35px", height: "35px" }}
                  alt="Image not found"
                />
              </div>
              <div className="ps-2">
                <div style={{ fontSize: "0.8rem" }} className="mb-2">
                  {item.message}
                </div>
                {item.status &&
                  item.receiver_id &&
                  item.status === "pending" &&
                  item.receiver_id === userData.id ? (
                  <div className="d-flex">
                    <IconButton onClick={() => acceptRequest(item)}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      className="text-danger"
                      onClick={() => rejectedRequest(item)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ) : (
                  ""
                )}
                <div className="text-secondary" style={{ fontSize: "14px" }}>
                  {`at ${getTime(item.created_at)}`}
                </div>
              </div>
            </div>
            <div className="px-2" style={{ color: '#e9e5e5' }}>
              <Divider />
            </div>
          </div>
        </div>
      ))
    ),
  ]}
</Menu>

    </>
  );
};

export default NotificationComponent;
