import React from 'react'
import AddDemo from './AddDemo'
import DemoList from './DemoList'
import { useState } from "react";
import SnackbarContainer from '../Snackbar';

function DemoIndex() {
 const [demo, setDemo] = useState(true);

 function handleChange() {
  setDemo(!demo)
}
  return (<>
         {demo ? <DemoList handleChange={handleChange} /> : <AddDemo handleChange ={handleChange}/> }
         <SnackbarContainer/>
         </>
  )
}
export default DemoIndex