import React from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Radio, Select, TextField, FormHelperText } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import dayjs from 'dayjs';

const ReleaseInformation = ({ data, handleInputChange, errors, prereleaseDate, releasehistory, releaseDateChangeHandler, copyrights_c, handleInputChangeCopyright,
    releaseDate, releaseTime, callerTune1, callerTune2, releaseTimeChangeHandler, copyrighttype, copyrightList, copyrightp_name, copyrightc_name, copyrights_p }) => {
    const minDate = new Date()
    const maxDate = new Date()
    minDate.setDate(minDate.getDate() + 7)
    maxDate.setDate(maxDate.getDate())
    const isDateInvalid = releaseDate && new Date(releaseDate) < new Date();
    const releaseHistoryValue = releasehistory === false || releasehistory === "False"
    return (
        <>
            <div>
                <p className='cardheading'>Add Release Information</p>

                <div className='row'>
                    <div className="form-group col-md-12 mt-2 mb-3">
                        <FormControl
                            name='catalog'
                            noValidate
                            autoComplete="off"
                            fullWidth={true}>
                            <TextField
                                disabled={true}
                                size="small"
                                value={data.catalog}
                                // error={errors.catalog}
                                // helperText={errors.catalog_helperTxt}
                                name="catalog"
                                label="Catalog Id" variant="outlined" className="w-100 rounded "
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-12'>
                        <p className='m-0'>Has it been previously released? *</p>
                        <Radio
                            checked={releasehistory === "False"}
                            onChange={handleInputChange}
                            value="False"
                            name='release_history'
                            inputProps={{ 'aria-label': '0' }}
                        />
                        No, it's a new release
                        <Radio
                            checked={releasehistory === "True"}
                            onChange={handleInputChange}
                            value="True"
                            name='release_history'
                            inputProps={{ 'aria-label': 'Yes' }}
                        />
                        Yes (UPC/EAN/JAN required)
                    </div>
                    <div className="form-group mt-2 mb-3">
                        <FormControl fullWidth={true} name="previous_release_date">
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={`Previous Release Date${releaseHistoryValue ? '' : '*'}`}
                                    inputFormat="DD/MM/YYYY"
                                    value={prereleaseDate}
                                    name="previous_release_date"
                                    maxDate={maxDate}
                                    onChange={(date) => releaseDateChangeHandler(date, 'preReleaseDate')}
                                    renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                    disabled={releaseHistoryValue}
                                />
                            </LocalizationProvider>
                            {isDateInvalid && (<FormHelperText error> Please select a past date.</FormHelperText>)}
                        </FormControl>
                    </div>
                    <div className="form-group col-md-12 mb-3">

                        <FormControl
                            name='upc_code'
                            noValidate
                            autoComplete="off"
                            fullWidth={true}
                        >
                            <TextField
                                variant="outlined"
                                label={`UPC/EAN/JAN${releaseHistoryValue ? '' : '*'}`}
                                size="small"
                                type="text"
                                name='upc_code'
                                inputProps={{ maxLength: 12 }}
                                value={data.upc_code}
                                onChange={handleInputChange}
                                error={errors.upc_code}
                                helperText={errors.upc_code ? errors.upc_code_helperTxt : ' Please provide the 12-digit numeric code'}
                                disabled={releaseHistoryValue}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-12'>

                        <div className='row'>
                            <div className='col-md-6 '>
                                <FormControl
                                    name='prevone-time'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth={true}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            label="CallerTune Preview-1/Clip Start Time(optional)"
                                            value={callerTune1}
                                            onChange={(time) => releaseTimeChangeHandler(time, 'callerTune1')}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div className='col-md-6 '>
                                <FormControl
                                    name='pretwo-time'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth={true}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            label="CallerTune Preview-2/Clip Start Time(optional)"
                                            value={callerTune2}
                                            onChange={(time) => releaseTimeChangeHandler(time, 'callerTune2')}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                            <p className='m-0'>What’s the origin of the track? *</p>
                            <Radio
                                checked={copyrighttype === 'True'}
                                onChange={handleInputChange}
                                value="True"
                                name='copyright_type'
                                inputProps={{ 'aria-label': 'Yes' }}
                            />
                            Original Song
                            <Radio
                                checked={copyrighttype === 'False'}
                                onChange={handleInputChange}
                                value="False"
                                name='copyright_type'
                                inputProps={{ 'aria-label': '0' }}
                            />
                            Public Domain
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth size='small' name="copyrights_p" >
                                    <InputLabel>Ⓟ Copyright Year*</InputLabel>
                                    <Select
                                        name="copyrights_p"
                                        label="Ⓟ Copyright Year*"
                                        value={copyrights_p}
                                        onChange={handleInputChangeCopyright}
                                    >
                                        {copyrightList && copyrightList.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.copyright_year}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <FormControl
                                    name='copyrightp_name'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth={true}>
                                    <TextField
                                        size="small"
                                        value={copyrightp_name}
                                        onChange={handleInputChangeCopyright}
                                        name="copyrightp_name"
                                        label="Ⓟ Copyright*" variant="outlined" className="w-100 rounded "
                                        error={errors.copyrightp_name}
                                        helperText={errors.copyrightp_name ? errors.copyrightp_name_helperTxt : ''}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth size='small' name="copyrights_c">
                                    <InputLabel >© Copyright Year*</InputLabel>
                                    <Select
                                        name="copyrights_c"
                                        label="© Copyright Year*"
                                        value={copyrights_c}
                                        onChange={handleInputChangeCopyright}
                                    >
                                        {copyrightList && copyrightList.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.copyright_year}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <FormControl
                                    name='copyrightc_name'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth={true}>
                                    <TextField
                                        size="small"
                                        value={copyrightc_name}
                                        // value={data.copyrightc_name}
                                        onChange={handleInputChangeCopyright}
                                        name="copyrightc_name"
                                        label="© Copyright*" variant="outlined" className="w-100 rounded "
                                        error={errors.copyrightc_name}
                                        helperText={errors.copyrightc_name ? errors.copyrightc_name_helperTxt : ''}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <FormControl
                                name='isrc_code'
                                noValidate
                                autoComplete="off"
                                fullWidth={true}
                            >
                                <TextField
                                    variant="outlined"
                                    label="ISRC Code(Optional)"
                                    size="small"
                                    name='isrc_code'
                                    value={data.isrc_code}
                                    onChange={handleInputChange}
                                    type="text"
                                    error={errors.isrc_code}
                                    inputProps={{ maxLength: 12 }}
                                    helperText={errors.isrc_code ? errors.isrc_code_helperTxt : ' Please provide the 12-digit alphanumeric code'}
                                />
                            </FormControl>
                        </div>

                        <div className='row'>
                            <div className="col-md-6 mt-2 ">
                                <FormControl fullWidth={true}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Release Date*"
                                            inputFormat="DD/MM/YYYY"
                                            value={releaseDate}
                                            name="release_date"
                                            minDate={minDate}
                                            onChange={(date) => releaseDateChangeHandler(date, 'releaseDate')}
                                            renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {isDateInvalid && (<FormHelperText error> Please select a future date.</FormHelperText>)}
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>


                                <FormControl
                                    name='release_time'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth={true}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            label="Release Time(Optional)"
                                            value={releaseTime}
                                            // value={data.release_time}
                                            onChange={(time) => releaseTimeChangeHandler(time, 'releaseTime')}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />

                                    </LocalizationProvider>
                                </FormControl>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReleaseInformation