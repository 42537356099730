import React from 'react'
import Dashboard from '../dashboard/dashboard'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import Footer from '../../admin/footer';
import { Link } from 'react-router-dom';


function InvoiceList(props) {


  return (
            <div>
               <div className="bg-light">
               {/* <Dashboard> */}
                  <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-2">
                      <h4>Invoice List</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                        <table className="table border-0">
                          <thead className="bg-light">
                            <tr>
                             <th>Invoice Id</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-black-50">#67462</td>
                              <td className="text-black-50">Vishal</td>
                              <td className="text-black-50">vishal@thehigherpitch.com</td>
                              <td className="text-black-50">22-dec-2021</td>
                              <td><a href="#"><Link to="/invoices"><VisibilityIcon className="text-primary" /></Link></a><a href=""><DownloadIcon className="text-danger"/></a></td>
                            </tr>
                            <tr>
                            <td className="text-black-50">#67462</td>
                              <td className="text-black-50">Vishal</td>
                              <td className="text-black-50">vishal@thehigherpitch.com</td>
                              <td className="text-black-50">22-dec-2021</td>
                              <td><a href="#"><Link to="/invoices"><VisibilityIcon className="text-primary" /></Link></a><a href=""><DownloadIcon className="text-danger"/></a></td>
                            </tr>
                            <tr>
                            <td className="text-black-50">#67462</td>
                              <td className="text-black-50">Vishal</td>
                              <td className="text-black-50">vishal@thehigherpitch.com</td>
                              <td className="text-black-50">22-dec-2021</td>
                              <td><a href="#"><Link to="/invoices"><VisibilityIcon className="text-primary" /></Link></a><a href=""><DownloadIcon className="text-danger"/></a></td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                {/* </Dashboard> */}
               </div>
               {/* <Footer/> */}
            </div>
  )
}

export default InvoiceList