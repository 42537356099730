import axios from "axios";
// import {getSelfToken} from '../../_helpers/auth/secureToken'
import { connectWithSpotifyArtist } from "../_constants/api.constant";
export async function searchArtistOnSpotify(artistName){
    let prarmTempObj={};
    if(artistName){
        prarmTempObj.query=artistName;
    }
    const response=await axios.get(
    //   "https://gnapidev.gncreators.com/content_creator/search-artists/",
    connectWithSpotifyArtist.FETCH_SPOTIFY_ARTIST_LIST,
      {   params:prarmTempObj,
      },
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}