import YtMusic from '../../img/streaming-platform-images/yt-music.svg';
import JioSaavn from '../../img/streaming-platform-images/jio-saavn.svg';
import Wynk from '../../img/streaming-platform-images/wynk.svg';
import Spotify from '../../img/streaming-platform-images/spotify.svg';
import Hungama from '../../img/streaming-platform-images/hungama.svg';
import Gaana from '../../img/streaming-platform-images/gaana.svg';
import AmzazonMusic from '../../img/streaming-platform-images/amazon-music.svg';
import AppleMusic from '../../img/streaming-platform-images/apple-music.svg';
import YouTube from '../../img/streaming-platform-images/youtube.png';
import InstagramReel from '../../img/streaming-platform-images/instagram_1.svg';
import Deezer from '../../img/streaming-platform-images/Deezer.svg';
import Others from '../../img/streaming-platform-images/otherPlatform.png';
import imgThumbnail from '../../img/thumbnail.png';

export const getStrPlatformImg = (platform_key) => {
    if (platform_key) {
        switch (platform_key) {
            case 'jiosaavn': {
                return JioSaavn;
            }
            case 'wynk': {
                return Wynk;

            }
            case 'spotify': {
                return Spotify;

            }
            case 'hungama': {
                return Hungama;

            }
            case 'gaana': {
                return Gaana;

            }
            case 'amazonmusic': {
                return AmzazonMusic;

            }
            case 'applemusic': {
                return AppleMusic;

            }
            case 'youtubemusic': {
                return YtMusic;

            }
            case 'youtube': {
                return YouTube;

            }
            case 'Instagrammakereels': {
                return InstagramReel

            }
            case 'others': {
                return Others;

            }
            case 'deezer': {
                return Deezer;

            }
            case undefined: {
                return imgThumbnail;
            }
            default: {
                return imgThumbnail;
                break
            }
        }
    } else {
        return imgThumbnail;
    }
};