import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { notificationsURL, studioAssociationURL } from '../_constants/api.constant'
export async function notificationRequestHandler(postData,actiontype){
    switch(actiontype)
    {
        case 'GET':
            {
                const response = await axios.get(
                notificationsURL.GET,
                    {   
                        params:{user:postData.id},
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    return error.response;
                });
                return response;
            }
        case 'PUT':
            {
                const response = await axios.put(
                notificationsURL.ALL,
                    {is_read:postData.is_read},
                    {   
                        params:{notification_id:postData.notification_id},
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    return error.response;
                });
                return response;
            }
        case 'MARK_ALL_READ':
            {
                const response = await axios.post(
                notificationsURL.MARK_ALL_READ, postData,
                    {   
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    return error.response;
                });
                return response;
            }
        default:
            return;
    }
}