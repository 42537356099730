import axios from "axios";
import { getThumbnailLink } from '../_constants/api.constant'
import { getUserTypesLink } from '../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'
//import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function getLinkThumbnails() {
    const response = await axios.get(
        getThumbnailLink.GET,
        {
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        console.log('Inside thumnail', response)
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}