import React from 'react'

export const getSongStatusBgColor = (songStatus) => {
    songStatus = songStatus.replaceAll(' ','_').toLowerCase();
    if(songStatus){
        switch(songStatus)
        { 
        case 'unverified' :
            {
                return '#FF0000'
            }  
        case 'in_review':
            {
                return "#DAA827"
            }
        case 'ready_for_release':
            {
            return "#FCAC62"
            }
        case 'released':
            {
            return ' #3EBD02'
            }
        case 'rejected':
            {
            return '#E92726'
            }
            case 'completed':
                {
                return ' #3EBD02'
                }
        default:
            return '#6c757d' 
        }
    }
}
