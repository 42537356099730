import axios from "axios";
import {artistEnquiryURL} from '../_constants/api.constant'
import {  getSelfToken } from '../../_helpers/auth/secureToken'
export async function handleEnquiry(dataObj,actionType,id) {

    switch(actionType)
    {
        case 'GET':{
            const response = await axios.get(
                artistEnquiryURL.GET,
                {   params:{search:dataObj.userId},
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        case 'DELETE':{
            const response = await axios.delete(
                artistEnquiryURL.DELETE + "?id=" +dataObj.itemId,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        case 'POST':{
            const response = await axios.post(
                artistEnquiryURL.POST,
                dataObj,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        default:{return}
    }

}