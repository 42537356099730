import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, FormControl, TextField, useMediaQuery, InputAdornment } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import defaultImge from '../../img/userAdmin.png'
import { useDispatch, useSelector } from 'react-redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Typography from '@mui/material/Typography';
import AvatarEditor from 'react-avatar-editor';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import CropIcon from '@mui/icons-material/Crop';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Fade from '@mui/material/Fade';
import SnackbarContainer from '../Snackbar';
import { verifyRequestCreatorUID } from '../../_services/studioAssociation/verifyCreatorUid.service';

function AddAssociateArtist({ handleChange, getAssociatedArtist }) {
  const mdScreen = useMediaQuery('(max-width:1024px)');
  const smScreen = useMediaQuery('(max-width:600px)');
  const xsSmScreen = useMediaQuery('(max-width:375px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: smScreen ? '100%' : 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const [artistAvatar, set_Artist_Avatar] = useState("");
  let params = useParams();
  let history = useHistory();
  const { assoId } = params
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const editorRef = React.useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [scale, setScale] = useState(1);

  const [errors, setErrors] = useState({
    artist_name: false,
    artist_name_helperTxt: null,
    name: false,
    name_helperTxt: null,
    artist_bio: false,
    about_helperTxt: null,
    website: false,
    website_helperTxt: null,
    facebook: false,
    facebook_helperTxt: null,
    soundcloud: false,
    soundcloud_helperTxt: null,
    spotify: false,
    spotify_helperTxt: null,
    youtube: false,
    youtube_helperTxt: null,
    apple_music_id: false,
    apple_music_id_helperTxt: null,
    twitter: false,
    twitter_helperTxt: null,
    instagram: false,
    instagram_helperTxt: null,
    tiktok: false,
    tiktok_helperTxt: null,
    creator_gnid: false,
    creator_gnid_helperTxt: null,
  })
  const [data, setData] = useState(
    {
      artist_name: '',
      name: '',
      artist_bio: '',
      artist_avatar: '',
      apple_music_id: '',
      facebook: '',
      instagram: '',
      soundcloud: '',
      spotify: '',
      tiktok: '',
      twitter: '',
      website: '',
      youtube: '',
      creator_gnid: '',
    }
  )
  /* delete data.artist_type; */

  const previousPage = () => {

    if (assoId !== undefined) {
      history.push("/associatedartist");
    }
    else {
      handleChange()
    }
  }


  useEffect(() => {
    if (userData.id) {
      assoId && getAllData()
    }
  }, [userData.id])

  const getAllData = () => {
    associatedArtistsRequestHandler({ artist_id: assoId }, 'GETARTIST')
      .then(response => {
        setData(response.data)
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    // Validation: Allowed file formats
    const allowedFormats = ['image/png', 'image/jpeg'];
    if (!allowedFormats.includes(file.type)) {
      // Show error message or handle invalid format
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage('Invalid file format. Only PNG and JPEG files are allowed.'))
      setTimeout(() => dispatch(setSnackBar(false)), 3000);
      return;
    }

    // Validation: Maximum file size
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSize) {
      // Show error message or handle oversized file
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage('File size exceeds the maximum limit (10MB).'))
      setTimeout(() => dispatch(setSnackBar(false)), 3000);
      return;
    }

    // Validation: Allowed resolution
    const allowedResolution = { width: 500, height: 500 };
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      // All validations passed, proceed with handling the file
      handleOpen(); // Open the modal
      setPreviewImage(URL.createObjectURL(file));
      // Reset the input element's value to allow selecting the same file again
      e.target.value = null;
    };
  };

  const handleCrop = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const croppedImageUrl = canvas.toDataURL();
      set_Artist_Avatar(croppedImageUrl)
      canvas.toBlob((blob) => {
        setData({
          ...data,
          artist_avatar: blob
        });
      }, 'image/jpeg');
    }
    handleClose() //close the modal
  };


  const handleScaleChange = (e) => {
    const scaleValue = parseFloat(e.target.value);
    setScale(scaleValue);
  };

  function getOriginalImageName(blobFile) {
    const file = new File([blobFile], 'temp.jpg', { type: blobFile.type });
    return file.name;
  }

  const verifyGNID = (value) => {
    return new Promise((resolve, reject) => {
      verifyRequestCreatorUID({ uid: value })
        .then(function (response) {
          if (response.status === 200) {
            if (response && response.data && response.data.data === true)
              resolve(true);
            else {
              resolve(false);
              dispatch(setLoader(false));
              dispatch(setSnackBar(true));
              dispatch(setSnackBarVariant('error'));
              dispatch(setSnackBarMessage(formSnackbar.errors.InvalidGNID));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBar(true));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            reject(new Error('API call failed')); // Reject the promise with an error if the API call fails
          }
        })
        .catch(function (err) {
          dispatch(setLoader(false));
          dispatch(setSnackBar(true));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          reject(err); // Reject the promise with the error caught from the API call
        });
    });
  };

  const saveArtist = async (e, key) => {
    e.preventDefault();
    const postData = { ...data }; // Create a copy of data to avoid mutation
    postData['associated_with_creator'] = userData && userData.id;

    if (data.creator_gnid) {
      const isValid = await verifyGNID(data.creator_gnid);
      if (!isValid) {
        return;
      }
    }
    if (data.artist_id !== undefined && data.artist_id !== null && data.artist_id !== '') {
      if (typeof postData.artist_avatar === "string" && postData.artist_avatar.includes('media/')) {
        delete postData.artist_avatar;
      }
    }

    dispatch(setSnackBar(true));
    dispatch(setSnackBarVariant('info'));
    dispatch(setSnackBarMessage(formSnackbar.associatedArtist.artistUpload));
    dispatch(setLoader(true));
    dispatch(setSnackBar(true));

    const formData = new FormData();
    for (const key in postData) {
      if (key === 'artist_avatar') {
        data.artist_avatar !== '' && data.artist_avatar != null && formData.append(key, data.artist_avatar, getOriginalImageName(data.artist_avatar));
      } else {
        formData.append(key, postData[key] === null ? '' : postData[key].toString().trim());
      }
    }

    if (data.artist_id !== undefined) {
      associatedArtistsRequestHandler(formData, 'UPDATE').then(response => {
        if (response.status === 200 || response.status === 201) {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('success'));
          previousPage();
          dispatch(setSnackBarMessage(formSnackbar.associatedArtist.artistUpdateSuccess));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
          dispatch(fetchUserDetail());
          getAllData();
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      });
    } else {
      associatedArtistsRequestHandler(formData, 'POST').then(response => {
        if (response.status === 200 || response.status === 201) {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('success'));
          previousPage();
          dispatch(setSnackBarMessage(formSnackbar.associatedArtist.success));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
        } else {
          if (response.data.data === false) {
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.associatedArtist.duplicateArtist));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            dispatch(setLoader(false));
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        }
      });
    }
  };



  // tanya
  function checkUrlString(string) {
    let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let regex = new RegExp(expression);
    try {
      if (string.match(regex)) {
        return true
      } else {
        return false
      }
    }
    catch (err) {
      return false
    }
  }

  function checkUrlProtocol(string) {
    try {
      const url = new URL(string);
      if (url.protocol === 'http:' || url.protocol === 'https:') {

        if (url.hostname.substring(0, 1) !== '.')
          return { isTrue: true, modifiedURl: url.href }
        else
          return { isTrue: false, modifiedURl: url.href }
      }
      else {
        return { isTrue: false, modifiedURl: url.href }
      }
    }
    catch (err) {
      return { isTrue: false, modifiedURl: undefined }
    }
  }
  // end


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
    switch (event.target.name) {

      case 'artist_name': {
        const isValid = regexValidator(value, 'alphanumspecial')
        if (!isValid) {
          setErrors({ ...errors, ['artist_name']: true, ['artist_name_helperTxt']: formSnackbar.errors.validInput });
        }
        else if (value.length > 100) {
          setErrors({ ...errors, ['artist_name']: true, ['artist_name_helperTxt']: formSnackbar.errors.characterCountErrorlow });

        }
        else {
          setErrors({ ...errors, ['artist_name']: false, ['artist_name_helperTxt']: null });
        }
        break;
      }

      case 'name': {
        const isValid = regexValidator(value, 'text')
        if (!isValid) {
          setErrors({ ...errors, ['name']: true, ['name_helperTxt']: formSnackbar.errors.validInput });
        }
        else if (value.length > 100) {
          setErrors({ ...errors, ['name']: true, ['name_helperTxt']: formSnackbar.errors.characterCountErrorlow });

        }
        else {
          setErrors({ ...errors, ['name']: false, ['name_helperTxt']: null });
        }
        break;
      }
      case 'artist_bio': {
        if (value.length > 1000) {
          setErrors({ ...errors, ['artist_bio']: true, ['artist_bio_helperTxt']: formSnackbar.errors.characterCountErrorMaxOnek });
        }
        else {
          setErrors({ ...errors, ['artist_bio']: false, ['artist_bio_helperTxt']: null });
        }
        break;
      }
      case 'website': {
        if (checkUrlString) {
          let validProtocol = checkUrlProtocol(value)
          if (!validProtocol.isTrue && value.trim() !== "") {
            setErrors({ ...errors, ['website']: true, ['website_helperTxt']: formSnackbar.errors.urlError });

          }
          else {
            setErrors({ ...errors, ['website']: false, ['website_helperTxt']: null });

          }
        }

        break;
      }
      case 'facebook': {
        if (checkUrlString) {
          let validProtocol = checkUrlProtocol(value)
          if (!validProtocol.isTrue && value.trim() !== "") {
            setErrors({ ...errors, ['facebook']: true, ['facebook_helperTxt']: formSnackbar.errors.urlError });

          }
          else {
            setErrors({ ...errors, ['facebook']: false, ['facebook_helperTxt']: null });

          }
        }

        break;
      }
      case 'soundcloud': {
        if (checkUrlString) {
          let validProtocol = checkUrlProtocol(value)
          if (!validProtocol.isTrue && value.trim() !== "") {
            setErrors({ ...errors, ['soundcloud']: true, ['soundcloud_helperTxt']: formSnackbar.errors.urlError });
          }
          else {
            setErrors({ ...errors, ['soundcloud']: false, ['soundcloud_helperTxt']: null });
          }
        }
        break;
      }
      case 'spotify': {
        const isValid = regexValidator(value, 'spotifyUri');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['spotify']: true, ['spotify_helperTxt']: formSnackbar.errors.spotifyIdExample });
        }
        else {
          setErrors({ ...errors, ['spotify']: false, ['spotify_helperTxt']: null });
        }
        break;
      }
      case 'youtube': {
        if (value.length > 100) {
          setErrors({ ...errors, ['youtube']: true, ['youtube_helperTxt']: formSnackbar.errors.characterCountErrorlow });

        }
        else {
          setErrors({ ...errors, ['youtube']: false, ['youtube_helperTxt']: null });
        }
        break;
      }
      case 'apple_music_id': {
        const isValid = regexValidator(value, 'appleId');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, apple_music_id: true, apple_music_id_helperTxt: formSnackbar.errors.appplyIdError });
        } else {
          setErrors({ ...errors, apple_music_id: false, apple_music_id_helperTxt: null });
        }
        break;
      }
      case 'twitter': {
        const isValid = regexValidator(value, 'twitter');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['twitter']: true, ['twitter_helperTxt']: formSnackbar.errors.socialPlatformHandle });
        }
        else {
          setErrors({ ...errors, ['twitter']: false, ['twitter_helperTxt']: null });
        }
        break;
      }
      case 'instagram': {
        const isValid = regexValidator(value, 'instagram');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['instagram']: true, ['instagram_helperTxt']: formSnackbar.errors.validInput });
        }
        else {
          setErrors({ ...errors, ['instagram']: false, ['instagram_helperTxt']: null });
        }
        break;
      }
      case 'tiktok': {
        const isValid = regexValidator(value, 'tiktok');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['tiktok']: true, ['tiktok_helperTxt']: formSnackbar.errors.socialPlatformHandle });
        }
        else {
          setErrors({ ...errors, ['tiktok']: false, ['tiktok_helperTxt']: null });
        }
        break;
      }
      case 'creator_gnid': {
        const isValid = regexValidator(value, 'alphanumeric')
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['creator_gnid']: true, ['creator_gnid_helperTxt']: 'Enter Only Number' });
        }

        else {
          setErrors({ ...errors, ['creator_gnid']: false, ['creator_gnid_helperTxt']: null });
        }
        break;
      }
      default:
        {
          return;
        }
    }

  }



  return (
    <div>
      <div className='p-3'>
        <button className="icononly-btn" onClick={previousPage}><ArrowBackIcon /> Back</button>
      </div>
      <Box sx={{ width: '95%', margin: 'auto', }} className="pb-5">
        <div className="card account-bg  ">
          <div className="card-body rounded" style={{ backgroundColor: '#fff' }}>
            <h4>Add New Artist </h4>
            <span className='text-secondary'> Add some basic information about artist</span>
            <div className="avatar-upload mt-3" title="Upload your image (Resolution: 250px*250px)">
              <div className="avatar-edit">
                <input type='file' name="artist_avatar" onChange={handleFileSelect} id="imageUpload" accept=".png, .jpg, .jpeg" />
                <label for="imageUpload">
                  <PhotoCameraIcon />
                </label>
              </div>
              <div className="avatar-preview">
                <div id="imagePreview">
                  <img src={artistAvatar ? artistAvatar : data.artist_avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data.artist_avatar : defaultImge} className="h-75 rounded-circle w-75" />
                </div>
              </div>
            </div>
            <div className='text-secondary'>
              <span style={{ fontSize: '0.8rem' }}>Allowed file formats are PNG and JPEG, and the maximum file size is 10MB.</span>
            </div>

            <div>
              {/*                     <input type="file" accept="image/*" onChange={handleFileSelect} />
                            <Button onClick={handleOpen}>Open modal</Button> */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  onClick: null, // Disable close on background click
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <div className='d-flex'>
                      <div>
                        <Typography id="transition-modal-title" variant="h6" component="h2">Crop and Save Image</Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                          Adjust the crop area to select the desired portion of the image. Click the 'Save' button to save the cropped image.
                        </Typography>
                      </div>
                      <div>
                        <IconButton onClick={handleClose} aria-label="Close" color="secondary">
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                    {/*  {previewImage && (
                                        <div>
                                        <h3>Image Preview</h3>
                                        <img src={previewImage} alt="Preview" />
                                        </div>
                                    )} */}
                    {previewImage && (
                      <div>
                        <div className="m-2" style={{ width: '100%', maxWidth: '100%', backgroundColor: 'transparent', border: 'solid 2px', borderRadius: '8px', borderStyle: 'dashed', textAlign: 'center' }}>
                          <AvatarEditor
                            ref={editorRef}
                            image={previewImage}
                            width={xsSmScreen ? 190 : 230}
                            height={xsSmScreen ? 190 : 230}
                            border={50}
                            color={[255, 255, 255, 0.6]}
                            scale={scale}
                            borderRadius={115}
                            rotate={0}
                            style={{ touchAction: "manipulation" }}
                          />
                        </div>
                        <div>
                          <div className='d-flex align-items-center'>
                            <div style={{ width: '100px' }}>
                              <Typography variant="body1"><ZoomInIcon /> Zoom In</Typography>
                            </div>
                            <div style={{ width: '10px' }}></div>
                            <div style={{ flexGrow: '1', maxWidth: '12rem', marginTop: '5px' }}>
                              <Slider
                                min={1}
                                max={2}
                                step={0.1}
                                value={scale}
                                onChange={handleScaleChange}
                              />
                            </div>
                          </div>
                          {/*                                             <IconButton onClick={handleCrop} aria-label="Crop Image">
                                            <CropIcon />
                                            </IconButton> */}
                          <button className='gn-actionbtn' onClick={handleCrop} ><CropIcon /> Crop</button>
                        </div>
                      </div>
                    )}

                    {/* {croppedImage && (
                                    <div>
                                    <h3>Cropped Image</h3>
                                    <img src={croppedImage} alt="Cropped" />
                                    </div>
                                )} */}
                  </Box>
                </Fade>
              </Modal>
            </div>
            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Stage Name*"
                variant="outlined"
                size='small'
                value={data.artist_name}
                name='artist_name'
                inputProps={{ maxLength: 40 }}
                error={errors.artist_name}
                helperText={errors.artist_name_helperTxt}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                size='small'
                value={data.name}
                name='name'
                inputProps={{ maxLength: 40 }}
                error={errors.name}
                helperText={errors.name_helperTxt}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl fullWidth className='mt-4'>
              <TextField
                id="outlined-multiline-static"
                label="About"
                multiline
                type='text'
                rows={3}
                sx={{ width: '100%' }}
                value={data.artist_bio}
                name='artist_bio'
                onChange={handleInputChange}
                helperText={errors.artist_bio_helperTxt}
                error={errors.artist_bio}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="YouTube Channel"
                variant="outlined"
                size='small'
                type='text'
                sx={{ width: '100%' }}
                value={data.youtube}
                name='youtube'
                onChange={handleInputChange}
                helperText={errors.youtube_helperTxt}
                error={errors.youtube}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Website URL"
                variant="outlined"
                size='small'
                value={data.website}
                name='website'
                onChange={handleInputChange}
                helperText={errors.website_helperTxt}
                error={errors.website}
              />


            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Facebook URL"
                variant="outlined"
                size='small'
                value={data.facebook}
                name='facebook'
                onChange={handleInputChange}
                helperText={errors.facebook_helperTxt}
                error={errors.facebook}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Apple Music Id"
                variant="outlined"
                size='small'
                type='text'
                value={data.apple_music_id}
                name='apple_music_id'
                onChange={handleInputChange}
                helperText={errors.apple_music_id_helperTxt}
                error={errors.apple_music_id}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Spotify Id"
                variant="outlined"
                size='small'
                value={data.spotify}
                name='spotify'
                onChange={handleInputChange}
                helperText={errors.spotify_helperTxt}
                error={errors.spotify}
                InputProps={{
                  startAdornment: <InputAdornment position="start">spotify:artist:</InputAdornment>,
                }}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Twitter Handle"
                variant="outlined"
                size='small'
                value={data.twitter}
                name='twitter'
                onChange={handleInputChange}
                helperText={errors.twitter_helperTxt}
                error={errors.twitter}
                InputProps={{
                  startAdornment: <InputAdornment position="start">@</InputAdornment>,
                }}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="Instagram Handle"
                variant="outlined"
                size='small'
                value={data.instagram}
                name='instagram'
                onChange={handleInputChange}
                helperText={errors.instagram_helperTxt}
                error={errors.instagram}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="SoundCloud URL"
                variant="outlined"
                size='small'
                value={data.soundcloud}
                name='soundcloud'
                onChange={handleInputChange}
                helperText={errors.soundcloud_helperTxt}
                error={errors.soundcloud}

              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="TikTok Username"
                variant="outlined"
                size='small'
                value={data.tiktok}
                name='tiktok'
                onChange={handleInputChange}
                helperText={errors.tiktok_helperTxt}
                error={errors.tiktok}
                InputProps={{
                  startAdornment: <InputAdornment position="start">@</InputAdornment>,
                }}
              />
            </FormControl>

            <FormControl className='mt-4' fullWidth>
              <TextField
                id="outlined-basic"
                label="GNID"
                variant="outlined"
                size='small'
                value={data.creator_gnid}
                name='creator_gnid'
                onChange={handleInputChange}
                helperText={errors.creator_gnid_helperTxt}
                error={errors.creator_gnid}

              />
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '30px' }}>
              <button
                className='gn-actionbtn '
                onClick={saveArtist}
                disabled={!data.artist_name || !data.name || errors.artist_name || errors.name || errors.artist_bio || errors.website || errors.facebook || errors.soundcloud || errors.spotify || errors.youtube || errors.apple_music_id || errors.twitter || errors.instagram || errors.tiktok || errors.creator_gnid}
              >
                SAVE
              </button>
            </Box>
          </div>
        </div>
      </Box>
      <SnackbarContainer />
    </div>
  )
}

export default AddAssociateArtist