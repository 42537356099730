import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import profileImge from '../img/profileImage.png'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Badge, Typography, styled } from '@mui/material';
import NotificationComponent from '../module/notification/Notifications';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ApiService from '../_services/ApiService';
import { getUserLocation } from '../_helpers/reusablefunctions/geoLocation';
import { fetchUserDetail } from '../redux';

function User(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  useEffect(() => {
    dispatch(fetchUserDetail());
    const fetchLocation = () => {
      getUserLocation()
        .then((coords) => {
          setLocation({ latitude: coords.latitude, longitude: coords.longitude });
        })
        .catch((error) => {
          setLocation({ latitude: null, longitude: null, error: error });
        });
    };

    fetchLocation();
    
    const intervalId = setInterval(() => {
      fetchLocation(); 
    }, 10000);

    return () => clearInterval(intervalId);
  }, []); 


  useEffect(function () {
    if (location?.latitude && location.longitude && userData) {
      let payload = {
        latitude: location.latitude,
        longitude: location.longitude
      }
      ApiService.put('getlatandlongUser', payload, { id: userData.id }).then(res => {
        console.log(res)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <NotificationComponent />
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, px: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableRipple
          >
            <Avatar src={userData ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + userData.avatar : profileImge} onError={(e) => { e.target.src = profileImge }}>
            </Avatar>
          </IconButton>
        </Tooltip>

      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '200px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" className='text-decoration-none text-black mx-3 text-capitalize' sx={{ fontSize: '1rem', padding: '0.5rem', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600 }}>{userData.userHandle ? userData.name : ''}</Typography>
        <Typography variant="h6" className='text-decoration-none text-secondary mx-4' sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600 }}>GNID:{userData.creator_gnid ? userData.creator_gnid : ''}</Typography>
        <div className="class-name"></div>
        <Link to="/profile" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <div >Account</div>
          </MenuItem>
        </Link>
        <Link to="/enquires" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <ErrorIcon fontSize="small" />
            </ListItemIcon>
            <div >Enquiries</div>
          </MenuItem>
        </Link>
        {userData && userData.isVerified !== true ? (
          <Link to="/artist-verification" className='text-decoration-none text-black '>
            <MenuItem>
              <ListItemIcon>
                <VerifiedIcon fontSize="small" />
              </ListItemIcon>
              Verification
            </MenuItem>
          </Link>) : (
          <Link to="/artist-verification" className='text-decoration-none text-black '>
            <MenuItem>
              <ListItemIcon>
                <VerifiedIcon fontSize="small" /*sx={{color:'#458EFF'}}*/ />
              </ListItemIcon>
              Verified Account
            </MenuItem>
          </Link>)
        }
        <Link to="/FAQs" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <LiveHelpIcon fontSize="small" />
            </ListItemIcon>
            <div >FAQs</div>
          </MenuItem>
        </Link>
        <Link to="/support" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <ContactSupportIcon fontSize="small" />
            </ListItemIcon>
            <div >Support</div>
          </MenuItem>
        </Link>
        <div className="class-name"></div>
        <Link to="/logout" className='text-decoration-none text-black '>
          <div className='p-2 mx-3 pt-3'> <strong>LOG OUT</strong></div>
        </Link>
      </Menu>

    </div>

  );
}
// }
export default User;