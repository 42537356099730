import {
  SET_LOADER
} from './loaderTypes'


export const setLoader = loader => {
  return {
    type: SET_LOADER,
    payload: loader
  }
}
