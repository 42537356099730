import axios from "axios";
import {ordersApi} from '../_constants/api.constant'
import {getSelfToken} from '../../_helpers/auth/secureToken'
export async function getOrders(){
    const response=await axios.get(
    ordersApi.ORDERS,
        {
            headers:{
                Authorization: 'Bearer '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}