import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { studioAssociationURL } from '../_constants/api.constant'
export async function studioAssociation(postData,actiontype,limit,offset){
    switch(actiontype)
    {
        case 'POST':
            {
                const response=await axios.post(
                studioAssociationURL.POST,
                postData,
                {
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                return error.response;
                });
                return response;
            }
            case 'VERIFY_UID':
                {
                    const response=await axios.post(
                    studioAssociationURL.VERIFY_UID,
                    postData,
                    {
                        headers:{
                            Authorization: 'Token '+getSelfToken(),
                        },
                    }
                    ).then(function(response) {
                    return response;
                    }).catch(function (error) {
                    console.log(error);
                    return error.response;
                    });
                    return response;
                }
        case 'UPDATE':
            {   
                const response=await axios.put(
                studioAssociationURL.UPDATE,
                postData,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                return error.response;
                });
                return response;
            }
            case 'GETLIST':
                {
                    const response = await axios.get(
                        studioAssociationURL.GETLIST,
                        {  
                            params:{creator:postData.id,limit:postData.limit,offset:postData.offset},
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        return error.response;
                    });
                    return response;
                }
        case 'DELETE':{
            const response = await axios.post(
            studioAssociationURL.DELETE ,
                postData,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                return error.response;
            });
            return response;
        }
    default:{return}
    }
}