import React, { useState } from 'react'
import { Box, Card, CardContent, CardMedia, Grid, Stack } from '@mui/material'
import defaultImage from  '../../img/default image.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useHistory } from 'react-router-dom'
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';


const cardStyle={
    display: 'flex',
    minWidth: '18rem',
    minHeight:'18rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
    borderRadius:'6px',
    '&:first-of-type': {
      marginLeft: '-10px',
    },
  }

const CardLayout = ({cardData,moduleName}) => {
  const history = useHistory()
  const [Status,setStatus]=useState('')
  const routeChangeHandler = () =>{
    history.push(`/releases/${cardData.song_id}`)
  }
  
    
  return (
    <div>
    <Grid container   direction={'row'} alignItems="center"  justify="center" className='d-flex align-items-center' style={{cursor:'pointer',margin:'15px'}} onClick={routeChangeHandler}>
       <Grid item xl={6} md={6} sm={12} xs={12}  style={{maxWidth:'18rem',maxHeight:'18rem',paddingRight:'30px',paddingTop:'20px',}} >
                <Card variant="outlined" sx={cardStyle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%',}} >
                            <Stack direction='row'  spacing={1} alignItems='center'>
                              <div >
                                <div className="item p-0" >
                                    <CardMedia
                                        component="img"
                                        sx={{ width:'18rem', height:'9rem'}}
                                        image={cardData.resized_covers?process.env.REACT_APP_SONGAPIURL_MEDIA+cardData.resized_covers.thumbnail300x300:defaultImage}
                                        onError={(e) => {
                                          e.target.src = defaultImage; // Set the source to the default image on error
                                        }}
                                    />
                                    <div style={{position:'absolute',bottom:'10px',left:'5%'}}> 
                                      {cardData.song_status!=''?<div style={{backgroundColor:getSongStatusBgColor(cardData.song_status)}} className='status text-light'>{cardData.song_status.slice(0,1).toUpperCase()+cardData.song_status.slice(1)}</div>:''}
                                    </div>   
                                </div>
                              </div>
                            </Stack>
                       <CardContent className='p-2' sx={{ flex: '1 0 auto'}} >
                                <div>
                                  <div className='text-truncate'><strong title={cardData.song_name}>{cardData.song_name}</strong></div>
                                  <div className='text-truncate'><span title={cardData.album}>{cardData.album}</span></div>
                                  <div><span>{cardData.genre}</span></div>
                                  <div><span>{cardData.content_types}</span></div>
                                  <div className='text-secondary'><strong>Release Date: </strong><span>{cardData.release_date && getDateAsPerFormat(cardData.release_date,"DD Mon, YYYY")}</span></div> 
                                </div>
                        </CardContent>
                    </Box>  
                </Card>
            </Grid>  
    </Grid>
    </div>
  )
}

export default CardLayout