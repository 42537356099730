import { Add } from '@mui/icons-material'
import React from 'react'
import SearchBox from '../../components/reusableComponents/SearchBox'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux'
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { Box, TablePagination, useMediaQuery } from '@mui/material'
import NoResults from '../../components/reusableComponents/NoResults'
import ClickableCardLayout from '../../components/reusableComponents/ClickableCardLayout'
import { default_limit, record_limits } from '../../_services/_constants/recordLimitset.constant'
import { useTheme } from '@emotion/react'


function AssociateArtistList(props) {
  const userData = useSelector(state => state.userData.userData)
  const [associateList, setAssociateList] = useState([])
  const dispatch = useDispatch()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchInput, setSearchInput] = useState(null)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [totalCount, setTotalCount] = useState(0)
  const isNextButtonDisabled = associateList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;

  useEffect(() => {
    if (userData.id) {
      setPage(0);
      getAssociatedArtist(userData.id, 0, rowsPerPage);
    }
  }, [userData.id, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.min(Math.floor((page * rowsPerPage) / newRowsPerPage), Math.ceil(1000 / newRowsPerPage) - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    getAssociatedArtist(userData.id, newPage, newRowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newOffset = newPage;
    getAssociatedArtist(userData.id, newPage, rowsPerPage, newOffset);
  };

  console.log('50 assoList', associateList)

  const getAssociatedArtist = (userId, page, rowsPerPage) => {
    const offset = page ? page : 0
    const limit = rowsPerPage ? rowsPerPage : 0;
    dispatch(setLoader(true))
    associatedArtistsRequestHandler({ 'userId': userId }, 'GET', limit, offset)
      .then(response => {
        dispatch(setLoader(false))
        setAssociateList(response.data.results)
        setTotalCount(response.data.total_count)
      })
      .catch(err => {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const getSearchList = (searchText, userId) => {
    dispatch(setLoader(true))
    associatedArtistsRequestHandler({ 'userId': userId, searchData: searchText }, 'SEARCH', rowsPerPage, page)
      .then(response => {
        dispatch(setLoader(false))
        setAssociateList(response.data.results)
        setTotalCount(response.data.total_count)
      })
      .catch(err => {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const handleSearchInput = (value) => {
    setSearchInput(value)
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      searchInput != null && searchInput.length > 0 ? getSearchList(searchInput, userData.id) : userData.id && getAssociatedArtist(userData.id)
    }, 400)

    return () => clearTimeout(getData)
  }, [searchInput])


  return (
    <div className={'p-4'}>
      <h2 >Associated Artist</h2>
      <div className="d-flex justify-content-between">
        <div className="flex-grow-1" style={{ maxWidth: '18rem' }}>
          <SearchBox handleSearchInput={handleSearchInput} />
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-between">
        <div>
          <button className="gn-actionbtn" onClick={props.handleChange}>
            <Add />ADD ARTIST
          </button>
        </div>
      </div>
      <div className=" mt-4">
        {associateList && associateList.length > 0 ? (
          !isSmallScreen ? (
            <>
              <Box className="card-container">
                {associateList.map((item, index) => (
                  <div key={item.artist_id}>
                    <ClickableCardLayout
                      cardData={item}
                      moduleName={'associateList'}
                      dataList={associateList}
                    />
                  </div>
                ))}
              </Box>
              <TablePagination
                rowsPerPageOptions={record_limits}
                colSpan={3}
                sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                className='cardPagination'
                component='div'
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                nextIconButtonProps={{ disabled: isNextButtonDisabled }}
              />
            </>
          ) : (
            <React.Fragment >
              <Box className='card-container'>
                {associateList.map((item, index) => (
                  <div key={item.id} >
                    <ClickableCardLayout
                      cardData={item}
                      moduleName={'associateList'}
                      dataList={associateList}
                    />
                  </div>
                ))}
              </Box>
              <div
                className="d-flex pagination-container  mb-5"
                style={{ width: '100%', marginTop: '20px' }}
              >
                <div style={{ width: '50%' }}>
                  <button
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={isPreviousButtonDisabled}
                    className={`pagination-button ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Previous
                  </button>
                </div>
                <div style={{ width: '20px' }}></div> {/* This creates a gap */}
                <div style={{ width: '50%' }}>
                  <button
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={isNextButtonDisabled}
                    className={`pagination-button ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Next
                  </button>
                </div>
              </div>
            </React.Fragment>
          )
        ) : (
          <NoResults />
        )}
      </div>
    </div>


  )
}
export default AssociateArtistList