import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {platformUpdateSequence} from "../_constants/api.constant";
export async function updatePlateformSequence(userId,postData){
    const response=await axios.put(
        platformUpdateSequence.SETSONGSEQUENCE,
       postData,
        {
            params:{
                userId:userId
            },
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}