import { ConstructionOutlined } from '@mui/icons-material';
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoginUser } from '../../../redux/loginUser/loginUserActions';
import { getCreaterLink } from "../../../_services/creater/creater.service";
import Logo from "../../../img/groovenexusLogo2.png"
import '../../../style/linkTreeView.css'
import gnBgHeader from '../../../img/linkTreeImages/gn_artist_header.png'
import gnBgContent from '../../../img/linkTreeImages/gn_artist_content.png'
import ProfileSocial from './ProfileSocial';
const LinkTreeView = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const [links, setLinks] = React.useState([]);

  //constants for the background and font:start
  const [headerstyle, setStyle] = useState(
    {backgroundImage:`url(${gnBgHeader})`,
     fontFamily:`'Scada', sans-serif`

    })
  const [contentStyle, setConentStyle] = useState({
    backgroundImage:`url(${gnBgContent})`,
    fontFamily:'calibri',
    height: '32.8vh'
  })  

  const [artistNameStyle, setArtistNameStyle] = useState(
    {
      fontFamily:`'Inknut Antiqua', sans-serif`
    }
  );
  const [artistDescriptionStyle, setDescriptionStyle] = useState(
    {
      fontFamily:`'Scada', sans-serif`,
      padding:'0px 5px'
    }
  );
  //constants for the background and font:end

  /* const [count, setCount] = useState(0);
  const increaseCount = () =>{
    setCount(prevalue=>prevalue+1)
  } */
  //console.log('5',links)
  
  useEffect(
    function () {
      // dispatch(fetchUserDetail());
      if (userData.id) {
        getCreaterLink(userData.id)
          .then(function (response) {
            if (response.data) {
              setLinks(response.data ? response.data.data : []);
              //squenceValue = response.data.data;
            // setLinks(userDetail.link ? userDetail.link : []);
            }
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
          });
          //setLinks(userDetail.link ? userDetail.link : []);
      }
    },
    [userData.id]
  );
  const renderCard = useCallback((link, index) => {
    return (
      link.status==1?
      <div key ={link.id} className="artists-link sm-font">
        <a  href={link.url}><p>{link.name}</p></a>
      </div>:null
    );
  }, []);
  return (
    <div>
      <div className="micro-view">
        <div className="micro-view-head" style={headerstyle}>
            <div className="userAvtar">
              {userDetail.avatar?<img src={userDetail.avatar}/>:<div>Default Image</div>}
            </div>
            <div>
              <div className="artist-name reg-font" style={artistNameStyle}>
               {userDetail.name?<strong>{userDetail.name}</strong>:<strong>Artist Name</strong>}
              </div>
              <div style={artistDescriptionStyle}>

                <div className="artist-description xs-font">
                {userDetail.about?<p>{userDetail.about}</p>:<p>Artist Description</p>}
                </div>

                <div className="artist-genre xs-font">
                  <ul className="genre-list">
                  {userDetail.genre?userDetail.genre.map((genre)=>{return(<li key={genre.id}>{genre.genre}</li>)}):null}
                  </ul>
                </div>

                <div className="artist-email xs-font">
                  {userDetail.email?<strong>{userDetail.email}</strong>:null}
                </div>
             
                <div className="social-handles">
                  {userDetail.social?userDetail.social.map((sociallink)=>{return(<a key ={sociallink.id} href={sociallink.url}><ProfileSocial socialTypeId = {sociallink.socialType.toString()} /></a>)}):null}
                </div>
              </div>
            </div>
        </div>
        <div  /* style={contentStyle} */>
        <div className='micro-view-body'>
                {
              /*   userDetail.link.length>0?  
                userDetail.link.map((item, index)=>{return (
                  <div key={item.id}> {item.name}</div>
                )}):<h1>Nothing Found</h1> */

                links.map((link, i) => renderCard(link, i))
              
                }
        </div>
        <div className="micro-view-footer">
          <div>
            <img src={Logo}/>
          </div>

        </div>  
        </div>              
      </div>
      {/* <h1>Count = {count}</h1>
      <button onClick={increaseCount}>Click Me</button> */}
    </div>
  )
}

export default LinkTreeView
