import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded, VolumeDownRounded, VolumeUpRounded } from '@mui/icons-material';
import {
  ThemeProvider,
  IconButton,
  Slider,
  Typography,
  Stack,
  styled,
  Button,
  SwipeableDrawer,
  CircularProgress
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import { useState } from 'react';

const WallPaper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  overflow: 'hidden',
  background: 'radial-gradient(104.04% 1616.8% at 0% 11.96%, #644343 0%, #675F86 100%)',
  transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
  '&:before': {
    content: '""',
    width: '140%',
    height: '140%',
    position: 'relative',
  },
  '&:after': {
    content: '""',
    width: '140%',
    height: '140%',
    bottom: '-50%',
    transform: 'rotate(30deg)',
  },
});

const Widget = styled('div')(({ theme }) => ({
  padding: 4,
  borderRadius: 4,
  position: 'relative',
  zIndex: 1,
  background: 'radial-gradient(104.04% 1616.8% at 0% 11.96%, #644343 0%, #675F86 100%)',
  backdropFilter: 'blur(40px)',
}));

const CardWidget = styled('div')(({ theme }) => ({
  // padding: 10,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
  backdropFilter: 'blur(40px)',
  padding: '1.2rem'
}));

const CircularSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    width: 24,
    height: 24,
    borderRadius: '50%',
    color: 'white',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.16)',
    },
  },
});


export default function AudioPlayer({ type, audioTitle, audioDescription, poster, audioSrc, cardData, setAudioPausedTime, setAudioStatus, audioStatus }) {
  const theme = useTheme();
  const [state, setState] = useState({ bottom: false });
  const audioRef = React.useRef();
  const smallScreen = useMediaQuery('(max-width:600px)')
  const extraSmallScreen = useMediaQuery('(max-width:300px)')
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(100);
  const [isOpen, setIsOpen] = useState(false);
  const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
  const lightIconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : '#fff';
  const [isVolumeOn, setVolumeOn] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const [loadedTime, setLoadedTime] = useState(0);

  // set format time
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    } else {
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  };
  /* full bottom audtio player */
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    handlePauseOnly();
  };
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }
  const demoAudioPlayer = (anchor) => (
    <Box sx={{ width: anchor === 'bottom' ? 'auto' : '250' }} role="presentation">
      <CardWidget>
        <div sx={{ display: 'flex' }} className='text-light text-center'>
          <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
            <button title="Close" onClick={toggleDrawer(anchor, false)} className="icononly-btn text-white" >
              <CloseIcon />
            </button>
          </div>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Typography variant="caption" fontWeight={500}>
              Your Clip
            </Typography>
            <Typography noWrap>
              <b>{audioTitle}</b>
            </Typography>
            <Typography noWrap letterSpacing={-0.25}>
              {audioDescription && `${audioDescription.slice(0, 30)}...`}
            </Typography>
          </Box>
        </div>
        <div style={{ width: '99%' }}>
          <Slider
            size="small"
            aria-label="Audio track progress"
            value={currentTime}
            max={duration}
            onChange={handleSeek}
            sx={{
              color: '#fff',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 8,
                height: 8,
                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                '&:before': {
                  boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                    ? 'rgb(255 255 255 / 16%)'
                    : 'rgb(0 0 0 / 16%)'
                    }`,
                },
                '&.Mui-active': {
                  width: 20,
                  height: 20,
                },
              },
            }}
          />
        </div>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: -2,
          }}>
          <Typography variant="subtitle1" color="#fff" component="div" sx={{ minWidth: '100px', marginLeft: '15px' }}>
            {formatTime(currentTime)} / {formatTime(duration)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

          }}>
          <IconButton aria-label="previous song" className='text-white' >
            <FastRewindRounded fontSize="large" onClick={handleBackward} />
          </IconButton>
          <IconButton aria-label="play/pause" onClick={handlePlayPause} className='text-white'>
            {isPlaying ? isBuffering ? <CircularProgress color="inherit" size={48} /> : <PauseRounded sx={{ fontSize: '3rem' }} /> : <PlayArrowRounded sx={{ fontSize: '3rem' }} />}
          </IconButton>
          <IconButton aria-label="next song" className='text-white'>
            <FastForwardRounded fontSize="large" onClick={handleForward} />
          </IconButton>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack spacing={smallScreen ? 0 : 2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center" width={`${smallScreen ? '50%' : '25%'}`}>
            <IconButton onClick={decreaseVolume}><VolumeDownRounded htmlColor={lightIconColor} /> </IconButton>
            <Slider
              aria-label="Volume"
              value={volume}
              onChange={handleChange}
              sx={{
                color: '#fff',
                '& .MuiSlider-track': {
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  width: 16,
                  height: 16,
                  backgroundColor: '#fff',
                  '&:before': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                  },
                  '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
            <IconButton onClick={increaseVolume}> <VolumeUpRounded htmlColor={lightIconColor} /></IconButton>
          </Stack>
        </div>
      </CardWidget>
      <WallPaper />
    </Box>
  );

  /* full bottom audtio player */
  function handlePlayPause() {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
      if (setAudioPausedTime) {
        setAudioPausedTime(audio.currentTime);
      }
      setIsPlaying(false)
    } else {
      audio.play();
      setIsPlaying(true);
    }
    if (setAudioStatus) {
      setAudioStatus(!audioStatus)
    }
  }

  function handlePauseOnly() {
    const audio = audioRef.current;
    setIsPlaying(false);
    audio.pause();
  }

  const handleSliderToggle = () => {
    setIsOpen(!isOpen);
  };
  /*   function handleTimeUpdate() {
      const audio = audioRef.current;
      setCurrentTime(Math.floor(audio.currentTime));
      setDuration(Math.floor(audio.duration));
      if(audio.currentTime ===audio.duration)
      {
        audio.pause();
        setIsPlaying(false);
      }
    } */
  const handleForward = () => {
    console.log(currentTime)
    console.log(audioRef.current)
    audioRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    console.log(currentTime)
    audioRef.current.currentTime -= 10;
  };

  function handleSeek(event, newValue) {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setCurrentTime(newValue);
  }


  const increaseVolume = () => {
    audioRef.current.volume = 1.0; // Set volume to 100%
    setVolume(audioRef.current.volume * 100);
  };
  const decreaseVolume = () => {
    audioRef.current.volume = 0.0; // Mute the audio
    setVolume(audioRef.current.volume * 100);
  };

  const handleChange = (event, newValue) => {
    setVolume(newValue)
    audioRef.current.volume = newValue / 100
  };
  const handleVolumeUpClick = () => {
    setVolumeOn(!isVolumeOn);
  };

  const muteVolume = () => {
    if (audioRef.current.volume > 0) {
      audioRef.current.volume = 0;
      setVolume(audioRef.current.volume)
    }
  }
  const VolumeUp = () => {
    if (audioRef.current.volume < 1) {
      audioRef.current.volume = 1;
      setVolume(audioRef.current.volume)
    }
  }

  React.useEffect(() => {
    const audio = audioRef.current;
    const handleTimeUpdate = () => {
      setCurrentTime(Math.floor(audio.currentTime));
      setDuration(Math.floor(audio.duration));
      if (audio.currentTime === audio.duration) {
        audio.pause();
        setIsPlaying(false);
      }
    }
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', () => {
      setDuration(Math.floor(audio.duration));
    });
    // Inside your useEffect for setting up the audio element
    audio.addEventListener('progress', () => {
      if (audioRef?.current?.buffered.length > 0) {
        const bufferedTime = audioRef.current.buffered.end(0);
        setLoadedTime(bufferedTime);
      }
    });
    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', () => {
        setDuration(Math.floor(audio.duration));
      });
      audio.removeEventListener('progress', handleTimeUpdate);
    };
  }, [audioRef]);

  function getPlayer(typeD) {
    switch (typeD) {
      case 'x-small':
        {
          return (
            <ThemeProvider theme={theme}>
              <IconButton aria-label="play/pause" onClick={handlePlayPause} sx={{ backgroundColor: '#6C6C6C', color: '#fff', '&:hover': { backgroundColor: '#6C6C6C8c' } }}>
                {isPlaying ? <PauseIcon sx={{ height: 38, width: 38 }} /> : <PlayArrowIcon sx={{ height: 38, width: 38 }} />}
              </IconButton>
            </ThemeProvider>
          )
        }
      case 'small':
        {
          return (
            <div className='audioBorder'>
              <Widget >
                <Stack spacing={1} direction="row" alignItems="center">
                  <IconButton
                    aria-label="play/pause" onClick={handlePlayPause}
                    style={{ padding: '1px', color: '#fff' }}

                  >
                    {isPlaying ? isBuffering ? <CircularProgress color="inherit" size={24} /> :
                      <PauseRounded className='text-light' />
                      : <PlayArrowRounded className='text-light' />}
                  </IconButton>
                  {!extraSmallScreen ?
                    <Typography variant="subtitle1" className='text-light mt-1 ' component="div" sx={{ minWidth: '65px', fontSize: '0.6rem' }}>
                      {formatTime(currentTime)} / {formatTime(duration)}
                    </Typography> : ''
                  }
                  <Slider
                    className='text-light'
                    size="small"
                    aria-label="Audio track progress"
                    value={currentTime}
                    max={duration}
                    onChange={handleSeek}
                    background='white'


                    sx={{
                      height: 4,
                      '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8,
                        margin: 0,
                        marginLeft: 0,
                        color: 'white',
                        '&:hover, &.Mui-focusVisible': {
                          boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                            ? 'rgb(255 255 255 / 16%)'
                            : 'rgb(0 0 0 / 16%)'
                            }`,
                        },

                      },

                    }}
                  />
                  <IconButton className='text-light' onClick={handleVolumeUpClick}>
                    {isVolumeOn ? <VolumeUpRounded onClick={muteVolume} style={{ padding: '2px' }} /> : <VolumeOffIcon onClick={VolumeUp} style={{ padding: '2px' }} />}
                  </IconButton>

                </Stack>
              </Widget>
            </div>
          )
        }
      case 'medium':
        {
          return (
            <>
              <Card sx={{ display: 'flex', margin: '1rem 0rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      {audioTitle}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      {audioDescription}
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <IconButton aria-label="previous">
                      {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                    </IconButton>
                    <IconButton aria-label="play/pause" onClick={handlePlayPause}>
                      {isPlaying ? isBuffering ? <CircularProgress sx={{ color: '#ff0000cf' }} size={38} /> : <PauseIcon sx={{ height: 38, width: 38, color: '#ff0000cf' }} /> : <PlayArrowIcon sx={{ height: 38, width: 38, color: '#ff0000cf' }} />}
                    </IconButton>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ minWidth: '100px' }}>
                      {formatTime(currentTime)} / {formatTime(duration)}
                    </Typography>
                    <Slider
                      value={currentTime}
                      max={duration}
                      onChange={handleSeek}
                      aria-label="Audio track progress"
                      sx={{ color: '#ff0000cf', minWidth: '150px', maxWidth: '60%' }}
                    />
                    <IconButton aria-label="next">
                      {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {poster &&
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image="/static/images/cards/live-from-space.jpg"
                    alt="Live from space album cover"
                  />}
              </Card>
            </>
          )
        }
      case 'large':
        {

          return (
            <div className='audioBorder'>
              <Widget >
                <Stack spacing={2} direction="row" sx={{ p: 1 }} alignItems="center">
                  <IconButton
                    aria-label="play/pause" onClick={handlePlayPause}
                    style={{ background: 'white', borderRadius: '100%', padding: '2px' }}

                  >
                    {isPlaying ? isBuffering ? <CircularProgress color="inherit" size={24} /> :
                      <PauseRounded htmlColor={mainIconColor} />
                      : <PlayArrowRounded htmlColor={mainIconColor} />}
                  </IconButton>
                  <Typography variant="subtitle1" className='text-light' component="div" sx={{ minWidth: '130px' }}>
                    {formatTime(currentTime)} / {duration ? formatTime(duration) : '0:00'}
                  </Typography>
                  <Slider
                    className='text-light'
                    size="small"
                    aria-label="Audio track progress"
                    value={currentTime}
                    max={duration}
                    onChange={handleSeek}
                    background='white'


                    sx={{
                      // color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                      height: 4,
                      '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8,
                        color: 'white',
                        // transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                        '&:hover, &.Mui-focusVisible': {
                          boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                            ? 'rgb(255 255 255 / 16%)'
                            : 'rgb(0 0 0 / 16%)'
                            }`,
                        },

                      },

                    }}
                  />
                  {/* <VolumeUpRounded  className='text-light'   htmlColor={lightIconColor}  onClick={ increaseVolume } /> */}

                  <VolumeUpRounded htmlColor={lightIconColor} onClick={handleSliderToggle} />
                  {isOpen && (
                    <Box sx={{ width: 100, height: 30, widthpadding: 0, margin: 0 }} >
                      <Slider
                        sx={{
                          color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                          // height: 4,
                          '& .MuiSlider-thumb': {
                            width: 15,
                            height: 15,
                            // color:'gray',
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',

                            '&:hover, &.Mui-focusVisible': {
                              boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                                ? 'rgb(255 255 255 / 16%)'
                                : 'rgb(0 0 0 / 16%)'
                                }`,
                            },

                          },

                        }}
                        orientation="horizontal"
                        aria-labelledby="vertical-slider"
                        // onClose={handleSliderClose}
                        aria-label="Volume"
                        className='text-light'
                        value={volume}
                        onChange={handleChange}
                      />
                    </Box>
                  )}

                </Stack>
              </Widget>
            </div>
          )
        }
      case 'demoAudio':
        {
          return (
            <div>
              {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <div className='playBtn'>
                    <Button onClick={toggleDrawer(anchor, true)} size='small' className='text-danger '>
                      <PlayArrowIcon /> Play
                    </Button>
                  </div>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={() => { toggleDrawer(anchor, false); handlePlayPause() }}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {demoAudioPlayer(anchor)}
                  </SwipeableDrawer>

                </React.Fragment>
              ))}
            </div>
          )
        }
      default:
        {
          return (
            <>
              <IconButton aria-label="play/pause" onClick={handlePlayPause}>
                {isPlaying ? <PauseIcon sx={{ height: 38, width: 38, color: '#ff0000cf' }} /> : <PlayArrowIcon sx={{ height: 38, width: 38, color: '#ff0000cf' }} />}
              </IconButton>
            </>
          )
        }
    }
  }
  return (
    <div>
      {getPlayer(type)}
      <audio ref={audioRef} src={audioSrc} style={{ display: 'none' }} preload="metadata"
        onWaiting={() => setIsBuffering(true)}
        onPlaying={() => setIsBuffering(false)} />
    </div>

  );
}
