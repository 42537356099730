
import {
  SET_SNACKBAR,
  SET_SNACKBAR_MESSAGE,
  SET_SNACKBAR_VARIANT
} from './snackBarTypes'
const initialState = {
  snackBarToggle: false,
  // vertical: "",
  // horizontal: "", 
  snackBarMessage: "",
  snackBarVariant:'',
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      console.debug("snackbar reducer: case1",action.payload)
      return {
        ...state,
        snackBarToggle: action.payload,
        // vertical:action.payload,
        // horizontal:action.payload
      }
    case SET_SNACKBAR_MESSAGE:
      console.debug("snackbar reducer: case2",action.payload)
      return {
        ...state,
        snackBarMessage: action.payload,
      }
    case SET_SNACKBAR_VARIANT:
      console.debug("snackbar reducer: case2",action.payload)
      return {
        ...state,
        snackBarVariant: action.payload,
      }  
    default: return state
  }
}


export default reducer
