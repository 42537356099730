import axios from "axios";
import { commonMusicLink } from '../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function handleSongList(data,requestType,limit,offset) {
    switch(requestType)
    {
        case 'GETBYUSER':{
            const response = await axios.get(
                commonMusicLink.GETBYUSER+'/?search='+data.userId,
                { 
                //       params: {
                //     limit: limit,
                //     offset: offset
                //   },
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
        
        
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }

        case 'POST':{
            let localDataObj = data;
            const response = await axios.post(
                commonMusicLink.POST,localDataObj,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
        
        
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }

        case 'UPDATE':{
            let localDataObj = data;
            let songId;
            for (const pair of localDataObj.entries()) {
                console.log(`${pair[0]}, ${pair[1]}`);
                if(pair[0]==='songId')
                   songId = pair[1]
              }
            const response = await axios.put(
                commonMusicLink.UPDATE+'?id='+songId,localDataObj,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        case 'DELETE':{
            let localDataObj = data;
            const response = await axios.delete(
                commonMusicLink.DELETE+'?id='+localDataObj.songId,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }

        default:{
            return;
        }
    }

    


}