import axios from "axios";
import { getSelfToken } from '../../_helpers/auth/secureToken'
import { submitAsongURL, submitDemoURL } from '../_constants/api.constant'
export async function getSingleDemoDetails(dataObj, actiontype) {
    switch (actiontype) {
        case 'GET_SPECIFIC':
            {
                const response = await axios.get(
                    submitAsongURL.GETSINGLESONG + '/' + dataObj.userId + '/',
                    {
                        params: { id: dataObj.id },
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });
                return response;
            }
        case 'GET_SPECIFIC_DISPLAY':
            {
                const response = await axios.get(
                    submitDemoURL.GETSINGLEDEMODISPLAY + '/' + dataObj.userId + '/',
                    {
                        params: { id: dataObj.id },
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });
                return response;
            }
        case 'GET_DEMO_PROGRESS':
            {
                const response = await axios.get(
                    submitDemoURL.GETDEMOPUBLISHER,
                    {
                        params: { demo: dataObj.id },
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });
                return response;
            }
        default: { return }
    }
}