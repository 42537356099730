import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {playistEndpoint} from '../_constants/api.constant'
export async function getSongListOfUser(userId) {
    const response = await axios.get(
        playistEndpoint.GET_ALL_SONGS_OF_USER,
        {   params:{search:userId},
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}