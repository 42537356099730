import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {AlbumEndpoint} from '../_constants/api.constant'
export async function deleteAlbum(dataObj) {
    const response = await axios.delete(
        AlbumEndpoint.DELETE_ALBUM+dataObj,
        {  
            //  params:dataObj,
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}