import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Skeleton, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import SearchBox from '../../../../components/reusableComponents/SearchBox';
import RatingReviewDialog from './RatingReviewDialog';
import ApiService from '../../../../_services/ApiService';
import { useSelector } from 'react-redux';
import { default_limit, record_limits } from '../../../../_services/_constants/recordLimitset.constant';
import NoResults from '../../../../components/reusableComponents/NoResults';
import SnackbarContainer from '../../../Snackbar';
import { useHistory } from 'react-router-dom';
import SkeletonCard from '../../../../_helpers/reusablefunctions/SkeletonCard';
import playlist from '../../../../img/Frame 48096170.png'
import GetMoodGenreLanguage from '../../../../_helpers/reusablefunctions/getMoodGenreLanguage';

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    flex: 'wrap',
    gap: 2,
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
export default function OfficialPlaylist() {
    const userData = useSelector((state) => state.userData.userData);
    const history = useHistory()
    const [playlistLists, setplaylistLists] = useState([]);
    const [loader, setLoader] = useState(true);
    const [activeChip, setActiveChip] = useState('0-0');
    const [openRatingDialog, setOpenRatingDialog] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [totalCount, setTotalCount] = useState()
    const [songId, setSongId] = useState('')
    const [searchInput, setSearchInput] = useState('')

    const filterSongs = [
        { label: 'playlists/', value: ["All Playlist"] },
        { label: 'official', value: ["Official Playlist"] },
        { label: 'trending', value: ["Trending Playlist"] },
        { label: 'getRelatedPlaylist/', value: ["Playlist for you"] },
    ]

    function playlistListsApi(url) {
        const premium_user = userData?.enable_distribution ? 'True' : 'False'
        ApiService.get('allsongslist', { premium_users: premium_user, limit: 25, offset: page }, url).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setplaylistLists([])
                setplaylistLists(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
        });
    }

    function officialtredingPlaylistApi(value) {
        let payload = { search: value, limit: 25, offset: page };
        setLoader(true)
        ApiService.get('allPlaylist', payload)
            .then((response) => {
                if (response.status === 200) {
                    setplaylistLists([])
                    let arrayData = response.data.results;
                    const data = arrayData.sort(function (a, b) {
                        return parseInt(a.playlist_order) - parseInt(b.playlist_order);
                    });
                    setplaylistLists(data);
                    setTotalCount(response?.data?.total_count)
                    setLoader(false)
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    function getSearchList() {
        const premium_user = userData?.enable_distribution ? 'True' : 'False'
        setLoader(true)
        ApiService.get('searchplaylist', { search: searchInput, limit: 25, offset: page, premium_users: premium_user }).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setplaylistLists(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(error => {
            console.log(error);
            console.log(error.response);
        })
    }

    useEffect(() => {
        if (searchInput != null && searchInput.length > 0) {
            let delaySearch;
            delaySearch = setTimeout(() => {
                getSearchList()
            }, 1000);
            return () => clearTimeout(delaySearch)
        } else {
            if (userData) {
                playlistListsApi('playlists/')
            }
        }
    }, [userData, page, rowsPerPage, searchInput])

    const handleChipClick = (i, label, value) => {
        setActiveChip(i)
        if (label == "trending" || label == "official") {
            officialtredingPlaylistApi(label)
        } else {

            playlistListsApi(label)
        }
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function openRatingHandler(id) {
        setOpenRatingDialog(true)
        setSongId(id)
    }

    const handleSearchInput = (value) => {
        setSearchInput(value)
    }

    function openReviews(data) {
        history.push("/reviews", { dialogValue: "Playlist", reviewViewDetail: data, preUrl: '/official-playlist' });
    }

    const openDetails = (data) => {
        history.push('/playlist-detail', { playlistData: data })
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box>
                <h2>Discover-Playlist</h2>
            </Box>
            <Box className="d-flex align-items-center justify-content-between my-4 flex-wrap gap-2">
                <Stack direction="row" spacing={1}>
                    {filterSongs.map((d, i) =>
                        d.value.map((v, index) => (
                            <StyledChip
                                key={`${i}-${index}`}
                                label={v}
                                component="a"
                                variant="outlined"
                                clickable
                                onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                                className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                            />
                        ))
                    )}
                </Stack>
                {activeChip == '0-0' && <SearchBox handleSearchInput={handleSearchInput} />}
            </Box>
            <Box className='my-4'>
                <Grid container spacing={2} alignItems="stretch">
                    {loader ?
                        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                            <SkeletonCard />
                        </Grid>))
                        :
                        loader === false && playlistLists.length === 0 ? (
                            <NoResults />
                        )
                            :
                            playlistLists.map((data, index) => (
                                data?.privacy_status ? <Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                                    <Card className='h-100'>
                                        <CardMedia
                                            sx={{ height: 150 }}
                                            image={data.playlist_cover_image ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data.playlist_cover_image : playlist}
                                            title="song-thumbnail"
                                            onClick={() => openDetails(data)}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        />

                                        <CardContent>
                                            <Tooltip title={data?.playlist_name}
                                                placement="top"
                                                arrow>
                                                <Typography variant="body2" className='text-truncate'><strong>Name:</strong> {data?.playlist_name || '-'}</Typography>
                                            </Tooltip>
                                            <Typography variant="body2" sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                                                {data?.user_name ? 'By-' + data?.user_name || '-' : ''}
                                            </Typography>
                                            <GetMoodGenreLanguage label="Genre" items={data?.playlist_genre || []} />
                                            <GetMoodGenreLanguage label="Mood" items={data?.mood || []} />
                                            <GetMoodGenreLanguage label="Language" items={data?.language || []} />
                                            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} component="div" className='d-flex align-items-center gap-2'>
                                                <img src={StarIcon} alt='StarIcon' />
                                                {data?.playlist_rating ? `${data.playlist_rating.average_rating}/5 (${data.playlist_rating.rating_count} Rating)` : '0/5 (0 Rating)'}
                                            </Typography> */}
                                        </CardContent>
                                        {/* <CardActions>
                                            <Button disabled={data.user == userData?.id} size="small" color="primary" className='gap-2' onClick={() => data.user == userData?.id ? null : openRatingHandler(data.playlist_id)}>
                                                <img src={reviewRating} alt='review&rating' />
                                                Rating & Review
                                            </Button>
                                            <Button size="small" color="primary" className='gap-2' onClick={() => openReviews(data)}>
                                                View Reviews
                                            </Button>
                                        </CardActions> */}
                                    </Card>
                                </Grid> : null))}
                    <TablePagination
                        rowsPerPageOptions={record_limits}
                        colSpan={3}
                        sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                        className='cardPagination'
                        component='div'
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>
            <RatingReviewDialog dialogValue="Playlist" listApiCall={playlistListsApi} songId={songId} openRatingDialog={openRatingDialog} setOpenRatingDialog={setOpenRatingDialog} />
            <SnackbarContainer />
        </Box>
    )
} 