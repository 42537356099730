import React from 'react'

const onlyEndSpaceAllowed = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*\s*$/;// to allow only chracters and disallow space at start
const startEndNotSpaceNotAllowed = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*$/; // to allow only chracters and disallow space at start and end
const numberAndTextAllowed = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/; // to allowe numbers and text only and disallow start and end
const allowAll = /(?!^ +$)^.+$/; //to allow all except all spaces and blancks
const allowAllWithLineBreak = /(?!^ +$)^.+$/s; // tp allow all including line breaks
const allowAlaphaNumericWithEndSpace = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*\s*$/; // to allowe numbers and text only and allow end space
const onlyNumber = /^[0-9]+$/;
const alphaNumSpecial = /[^a-zA-Z0-9\s\-._]/g
// tanya code start
const upcRegex = /^[0-9]+$/;
// const spotifyUriRegex = /^spotify:(track|album|playlist|artist):[a-zA-Z0-9]+$/;
const spotifyUriRegex = /^[a-zA-Z0-9]+$/;
const appleMusicIDRegex = /^[0-9]{1,64}$/;
const twitterHandleRegex = /^[A-Za-z0-9_]{1,15}$/;
const instagramHandleRegex = /^[A-Za-z0-9_.]{1,30}$/;
const tiktokUsernameRegex = /^[a-zA-Z0-9_.]{2,24}$/;

// end

export const regexValidator = (strInput, fieldType) => {
   
   switch(fieldType)
   {
        case 'text':{
          const regex = onlyEndSpaceAllowed;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'number':{
          const regex = onlyNumber;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumeric':{
          const regex =  numberAndTextAllowed
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumericEndSpace':{
          const regex =  allowAlaphaNumericWithEndSpace
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumspecial':{
          const regex =  alphaNumSpecial
          const isValid = !regex.test(strInput)
          return isValid;
        }
        case 'descriptionText':{
          const regex = allowAllWithLineBreak;
          const isValid = regex.test(strInput)
          return isValid;
        }
        // tanya code start
        case 'upc':{
          const regex = upcRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
      
        case 'spotifyUri':{
          const regex = spotifyUriRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'appleId':{
          const regex = appleMusicIDRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'twitter':{
          const regex = twitterHandleRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'instagram':{
          const regex = instagramHandleRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'tiktok':{
          const regex = tiktokUsernameRegex;
          const isValid = regex.test(strInput)
          return isValid;
        }
        // end
        default:{
          return false;
        }
   }
}

