import {
  FETCH_ASSOCIATES_DATA_REQUEST,
  FETCH_ASSOCIATES_DATA_SUCCESS,
  FETCH_ASSOCIATES_DATA_FAILURE,
  } from './studioAssociatesDataTypes'
import {getLoginUser} from '../../_services/user/getLoginUser.service'
import {getUserId} from '../../_helpers/auth/secureToken' //to pass the user id 
import { studioAssociation } from '../../_services/studioAssociation/studioAssociation.service'
import { useDispatch } from 'react-redux'
import { setLoader } from '../loader/loaderActions'

export const fetchStudioAssociates = (userId, rowsPerPage, page) => {
  return (dispatch) => {
    dispatch(fetchStudioAssociatesRequest());

    studioAssociation({ id: userId, limit: rowsPerPage, offset: page }, 'GETLIST')
      .then(response => {
        const associateData = response.data;
        dispatch(fetchStudioAssociatesSuccess(associateData));
        dispatch(setLoader(false));
      })
      .catch(error => {
        dispatch(fetchStudioAssociatesFailure(error.message));
        dispatch(setLoader(false));
      });
  };
};
  
  export const fetchStudioAssociatesRequest = () => {
    return {
      type: FETCH_ASSOCIATES_DATA_REQUEST
    }
  }
  
  export const fetchStudioAssociatesSuccess = userData => {
    return {
      type: FETCH_ASSOCIATES_DATA_SUCCESS,
      payload: userData
    }
  }
  
  export const fetchStudioAssociatesFailure = error => {
    return {
      type: FETCH_ASSOCIATES_DATA_FAILURE,
      payload: error
    }
  }
  