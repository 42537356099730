import axios from "axios";
import { updateGenreApi } from '../_constants/api.constant'
import {getSelfToken } from '../../_helpers/auth/secureToken'
export async function updateGenre(postData) {
    const postDetail = {genre_ids :postData.genre} //updated code to send only category selected by the user
    const response = await axios.put(
        updateGenreApi.UPDATE+'/'+postData.user +'/',
        postDetail,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}