import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { contentTypeURL } from '../_constants/api.constant'
export async function contentTypeRequest(postData,actiontype){
    switch(actiontype)
    {
        case 'GET':
        {
            const response = await axios.get(
                contentTypeURL.GET,
                {   params:{search:postData.userId},
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
            
        default:{return}
    }
}