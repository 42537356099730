import { Box,Card,CardContent, Grid, useMediaQuery,Button} from '@mui/material'
import React, { useRef } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import Grid2 from '@mui/material/Unstable_Grid2';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


 
function DemoCard({cardData,handleDeleteItem,id,index,deleteHandler}) {
    const ref = useRef()
    const smScreen = useMediaQuery('(max-width:600px)');
    const cardStyle={
        display: 'flex',
        minWidth: smScreen?'100%':'19rem',
        // width:'364.67px',
        minHeight:smScreen?'100%':'10rem',
        // height:'222px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
        borderRadius:'6px',
  }

  return (
            <Grid item xl={6} md={6} sm={12} xs={12}  style={{maxWidth:smScreen?'100%':'22rem',minWidth:smScreen?'auto':'22rem',marginRight:smScreen?'0':'1.5rem', marginBottom:'1.5rem'}}>
               <div>
                <Card variant="outlined" sx={cardStyle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%'}} >
                        <CardContent className='p-3' sx={{ flex: '1 0 auto'}} >
                            <div>
                                <div className='text-truncate'><strong style={{fontSize:'0.8rem'}} className='text-danger' >
                                    Upload date: <span>{getDateAsPerFormat(cardData.created,"DD Mon, YYYY")}</span>
                                    </strong>
                                </div>
                                <div className='mt-2 text-truncate'>
                                    <h5 title={cardData.audio_name} > {cardData.audio_name && cardData.audio_name.length>25?`${cardData.audio_name.slice(0,25)}...`:cardData.audio_name}</h5>
                                </div>
                                <div className='py-2' style={{minHeight:'50px',maxHeight:'50px'}}>
                                <div>
                                        <p title={cardData.description} className="text-secondary"> {cardData.description && cardData.description.length>50?`${cardData.description.slice(0,50)}...`:cardData.description}</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content mt-4'>
                                    {cardData.audio_clip!==null && cardData.demo_link===null || cardData.demo_link==='' || cardData.demo_link===undefined?<AudioPlayer type="demoAudio" audioTitle={cardData.audio_name} audioDescription={cardData.description} audioSrc={cardData.audio_clip?`${process.env.REACT_APP_BASEURL_MEDIA}/media/${cardData.audio_clip}`:''}/>:( <div className='playBtn'>  
                                        <Button size='small' onClick={()=>(window.open(cardData.demo_link, '_blank'))}className='text-danger '>
                                            <OpenInNewIcon /> View
                                        </Button>
                                        </div> )}
                                    <div className='text-secondary  my-1 mx-2'>
                                        <button title="Delete" className='gn-btn-icononly text-secondary' key={`delete_${cardData.id}`}> {cardData && cardData.id!==undefined?<span id={cardData.id} onClick={()=>ref.current.handleOpen()}> <DeleteIcon /></span>:<span id={cardData.id}></span>}</button>
                                    </div>
                                </div>

                            </div>

                        </CardContent>
                    </Box>  
                </Card>
                </div>
                <DeleteModal cardId={cardData && cardData.id} deleteHandler={handleDeleteItem} ref={ref}/>
            </Grid>  
  )
}

export default DemoCard