import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Box, Button, Modal, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { studioAssociation } from '../../_services/studioAssociation/studioAssociation.service';
import {  setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import PlaceholderImg from '../../img/general/PlaceholderImg.webp'

export default function StudioAssociateListView({cardData, moduleName, fetchAssociatedStudiosList}) {
  const [open, setOpen] = React.useState(false);
  const userData = useSelector(state => state.userData.userData)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [buttonText, setButtonText] = useState();
  const mediumViewport = useMediaQuery('(max-width:720px)');
  const dispatch = useDispatch();
  const [showUnlink,setShowUnlink] = useState(false);
  const [textAsPerStatus, setTextAsPerStatus] = useState('') //just to set the text as per status if not any action

  const handleClick = () => {
    handleOpen();
  };

  React.useEffect(()=>{
    if(cardData.connection_info.status==='pending' && cardData.connection_info.sender_id===userData.id){
       setButtonText('Revoke Request')
       setShowUnlink(true)
    }
    else if(cardData.connection_info.status==='pending' && cardData.connection_info.sender_id!==userData.id){
      setTextAsPerStatus('Request Pending')  
      setShowUnlink(false)
    }
    else if (cardData.connection_info.status==='accepted'){
      setButtonText('Remove Connection');  
      setShowUnlink(true)
    }
    else if (cardData.connection_info.status==='rejected'){
      setTextAsPerStatus('Request rejected')  
      setShowUnlink(false)
    }
    else{
      setButtonText(''); 
      setShowUnlink(false) 
    }
  },[cardData]) //code to handle the action button to revoke, unlink the connection

  const handleUnlink = () => {
    dispatch(setLoader(true))
    const postData = {
      sender_id: cardData.connection_info.sender_id,
      receiver_id: cardData.connection_info.receiver_id,
      status:"unlinked",
    };
    studioAssociation(postData, 'DELETE').then(function (response) {
      if (response.status === 200 || response.status === 201) {
        fetchAssociatedStudiosList(userData.id) //to refresht the list
        dispatch(setLoader(false))
      } else {
        dispatch(setLoader(false))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    })

  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport ? '95%' : '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxWidth: 600,
    zIndex: 2
  };

  const handleConfirm = () => {
    handleUnlink()
    handleClose(false);
  };

  const showInListView = (module) => {
    switch (module) {
      case 'studioList': {
        return (
          <div>
            <div className='my-3 responsive-list' style={{ cursor: 'pointer' }} >
              <Card sx={{ maxWidth: '500px', width: '100%' }}>
                <div className='d-flex w-100'>
                  <div style={{ padding: '0.5rem' }}>
                    <CardMedia
                      component="img"
                      sx={{ width: '70px', height: '70px', borderRadius: '15px' }}
                      image={cardData.avatar?process.env.REACT_APP_BASEURL_STUDIO_MEDIA+cardData.avatar:PlaceholderImg}
                      onError={(e) => {
                        e.target.src = PlaceholderImg;
                      }}
                    />
                  </div>
                  <div className='d-flex justify-content-between' style={{ flexGrow: 1,padding: '0.3rem 0px' }}>
                    <div>
                      <div>
                        <strong className='cardheadingFont overflow-text'>
                          {cardData.studio_name}
                        </strong>
                      </div>
                      <div>
                        <strong className='cardheadingFont cardLine'>GNID:{cardData.studio_gnid}</strong>
                      </div>
                      <div>
                        <span className='cardheadingFont cardLine' style={{color:'grey'}}>{cardData.connection_info.status}</span>
                      </div>
                    </div>
                    <div className='mt-4'>
                      {showUnlink?(
                        <Button
                        sx={{ mr: 1 }}
                        variant="text"
                        color='error'
                        style={{ minWidth: '75px', height: '40px', padding: 0 }}
                        onClick={handleClick}>
                          <strong style={{ fontSize: '0.7rem' }}>{buttonText}</strong>
                        </Button>
                      ):(
                        <Button
                        sx={{ mr: 1 }}
                        variant="text"
                        color='error'
                        style={{ minWidth: '75px', height: '40px', padding: 0 }}
                        /* onClick={()=>alert('Accept Or Reject')} */>
                          <strong style={{ fontSize: '0.7rem' }}>{textAsPerStatus}</strong>
                        </Button>
                      )}
                      
                    </div>
                  </div>
                </div>
              </Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <section className="header mb-4">
                    <h4 style={{ fontWeight: '900' }}>{buttonText}</h4>
                  </section>
                  <section className="body">
                    <p style={{ fontWeight: '400', marginBottom: '0.5rem' }}>Are you sure you want to {buttonText} with <b>{cardData.studio_name}</b>?</p>
                    <p style={{ color: '#3333337a', fontWeight: '500' }}>You cannot undo this action.</p>
                  </section>
                  <section className="footer d-flex justify-content-end mt-4">
                    <button className='gn-actionbtn outlined dark me-3' onClick={handleClose}>Cancel</button>
                    <button rea-label="Done" title="Done" className='gn-actionbtn' onClick={handleConfirm}>{buttonText}</button>
                  </section>
                </Box>
              </Modal>
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div>
      {showInListView(moduleName)}
    </div>
  )
}
