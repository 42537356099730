import axios from "axios";
import { tagsListURL } from '../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'

export async function handleTagList(data,requestType) {
    switch(requestType)
    {
        case 'GET':{
            const response = await axios.get(
                tagsListURL.GET,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }

        case 'POST':{
            let localDataObj = data
            const response = await axios.post(
                tagsListURL.POST, localDataObj,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }
        default:
        break;
    }
}