import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitAsongURL } from '../_constants/api.constant'
export async function submitSong(postData,actiontype,limit,offset){
    switch(actiontype)
    {
        case 'POST':
            {
                const response=await axios.post(
                submitAsongURL.POST,
                postData,
                {
                    // params:prarmTempObj,
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                console.log(error.response);
                return error.response;
                });
                return response;
            }
        case 'UPDATE':
                {
                    const response=await axios.put(
                    submitAsongURL.UPDATE+postData.get('song_id')+'/',
                    postData,
                    {
                       /*  params:{id:postData.get('song_id')}, */
                        headers:{
                            Authorization: 'Token '+getSelfToken(),
                        },
                    }
                    ).then(function(response) {
                    return response;
                    }).catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                    });
                    return response;
                }    
            case 'GET':
                {
                    const response = await axios.get(
                        submitAsongURL.GETFORSPECIFC+postData.userId+'/',
                        {  /*  params:{search:postData.userId}, */
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
            case 'GETFORSEPECICARTIST':
                {
                    const response = await axios.get(
                        submitAsongURL.GETFORSEPECICARTIST+postData.userId+'/',
                        {  /*  params:{search:postData.userId}, */
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                            params:{
                                limit:limit,offset:offset
                            }
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }    
                case 'SEARCH':
                    {
                        const response = await axios.get(
                            submitAsongURL.SEARCH,
                            {   params:{search:postData.searchValue,
                                publisher_type:postData.publisherType,
                                publisher_id:postData.publisherId,
                                limit:limit,
                                offset:offset},
                                headers: {
                                    Authorization: 'Token ' + getSelfToken(),
                                },
                            }
                        ).then(function (response) {
                            return response;
                        }).catch(function (error) {
                            // handle error
                            console.log(error);
                            console.log(error.response);
                            return error.response;
                        });
                        return response;
                    }    
                case 'DELETE':{
                    const response = await axios.delete(
                        submitAsongURL.DELETE + "?id=" +postData.itemId,
                        {
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
                case 'GETASSOCIATEDARTISTINFO':{
                    const response = await axios.get(
                        submitAsongURL.GETASSOCIATEDARTISTINFO+postData.artistCode+'/',
                        {
                          params:{
                            limit:limit,
                            offset:offset
                          },
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
                case 'ADVANCESEARCH':{
                    postData.limit = limit
                    postData.offset = offset
                    const response = await axios.get(
                        submitAsongURL.ADVANCESEARCH,
                        {   
                            params:postData,
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
                 default:{return}
    }
}