import React, { useRef, useState, useEffect } from 'react';
import { Autocomplete, Checkbox, Drawer, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getGenre } from '../../_services/profile'
import { useDispatch, useSelector } from 'react-redux';
import { fetchReleaseAlbum, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { updateAlbum } from '../../_services/album/updateAlbum';
import { createAlbum } from '../../_services/album/createAlbum';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { handleArtistData } from '../submitSong/helperComponent/helperComponent';
import { artistCode } from '../submitSong/associatedArtist/artistStaticCodes';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AlbumDrawer({ isOpen, onClose, onCreateAlbum, albumData, artistList, type }) {
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [selectedFile, setSelectedFile] = useState(albumData ? albumData.cover_image : null);
  const [albumName, setAlbumName] = useState(albumData ? albumData.album_name : '');
  const [albumNameErr, setAlbumNameErr] = useState(null)
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [albumNameErrMsg, setAlbumNameErrMsg] = useState('')
  const [featureArtList, setFeatureArtList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])

  // const [selectedPrivacy, setSelectedPrivacy] = useState(albumData ? albumData.privacy_status === true ? 'public' : 'private' : 'public');
  const [selectedGenres, setSelectedGenres] = useState(albumData ? albumData.genre : null);
  const [genres, setGeners] = useState([])
  const [fileError, setFileError] = useState('')
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.userData);
  const [disableBtn, setBtnDisable] = useState(albumData ? albumData.album_name && albumData.album_name.length > 0 ? false : true : true)
  const [fileSizeError, setFileSizeError] = useState(false);
  // const [albumSlug, setAlbumSlug] = useState(albumData ? albumData.slug : '');
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.svg', '.gif'];
console.debug("albummmmmm: artistlist",artistList)
  const handleClose = () => {
    setSelectedFile(null);
    setAlbumName('');
    setSelectedGenres([])
    onClose();
  }

  const [data, setData] = useState(
    {
      primary_artists: '',
      featuring_artists: '',
      composers: '',
      lyricists: '',
      producers: '',
      record_labels: '',
      actors: '',
      remixers: '',
    }
  )


  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.feature_artist:
        handleArtistData(name, featureArtList, setFeatureArtList, 'featuring_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
        case artistCode.producer:
          handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode, data, setData);
          break;
      default:
        break;
    }
  }


  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const fileValidation = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    if (file && fileType.startsWith('image/')) {
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.height === 3000 && img.width === 3000) {
            if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
              dispatch(setSnackBar(true));
              dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
              dispatch(setSnackBarVariant('error'));
              setTimeout(() => dispatch(setSnackBar(false)), 3000)
              return false
            }
            else {
              if (file.size > 10000000) {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSizeImage));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
              } else {
                setSelectedFile(file);

                return true
              }
            }
          } else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return false
          }
        }
      }

    } else {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return false
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileValidation(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      fileValidation(file)
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    getGenreList()
  }, [])


  const getGenreList = () => {
    getGenre().then(function (response) {
      setGeners(response.data.data)
      // console.debug("setSelectedGenres:albumData", albumData)
      if (albumData && albumData.genre) {
        // console.debug("setSelectedGenres:response.data.data", response.data.data)
        const matchedGenre = response.data.data.find(item => item.id === albumData.genre);
        // console.debug("matchedGenre:", matchedGenre);
        if (matchedGenre) {
          setSelectedGenres(matchedGenre.id);
        }
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }

  const handleGenreChange = (event) => {
    setSelectedGenres(event.target.value);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'album_name') {
      setAlbumName(value);
      const charCount = value.length;
      // Handle minimum length error
      if (charCount < 3) {
        setBtnDisable(true);
        setAlbumNameErr(true);
        setAlbumNameErrMsg('Minimum three characters are required.');
      } else if (charCount > 150) { // Handle maximum length error
        setBtnDisable(true);
        setAlbumNameErr(true);
        setAlbumNameErrMsg('Max limit is 150 characters.');
      } else {
        setBtnDisable(false);
        setAlbumNameErr(false);
        setAlbumNameErrMsg('');
      }
    }
  };
  const handleSubmit = () => {
    // Validate album details, and create the card if valid
    if (albumName && selectedGenres) {
      const selectedGenresId = selectedGenres
      const albumDetails = {
        album_name: albumName,
        // slug: albumSlug ? albumSlug:null,
        primary_artists: data.primary_artists,
        featuring_artists: data.featuring_artists,
        // genre: selectedGenresId.length ? selectedGenresId.join(',') : null,
        genre: selectedGenresId,
        publisher_id: userData.id,
        publisher_type: "creator",
        cover_image: type != 'edit' ? selectedFile : albumData && albumData.cover_image !== selectedFile ? selectedFile : null,
      };
      var formData = new FormData()
      if (type != 'edit') {
        for (const key in albumDetails) {
          if (albumDetails.hasOwnProperty(key) && albumDetails[key] !== null) {

            formData.append(key, albumDetails[key]);
          }
        }
      } else {
        const albumEditDetails = {
          album_name: albumName,
          // slug: albumSlug ? albumSlug:null,
          primary_artists: data.primary_artists,
          featuring_artists: data.featuring_artists,
          // genre: selectedGenresId.length ? selectedGenresId.join(',') : null,
          genre: selectedGenresId,
          cover_image: type != 'edit' ? selectedFile : albumData && albumData.cover_image !== selectedFile ? selectedFile : null,
        };
        for (const key in albumEditDetails) {
          if (albumEditDetails.hasOwnProperty(key) && albumEditDetails[key] !== null) {

            formData.append(key, albumEditDetails[key]);
          }
        }
      }

      // Call the onCreateAlbum function to add the new album
      //call api to create the album
      dispatch(setLoader(true))
      if (type !== 'edit') {
        createAlbum(formData)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              let d = []
              dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: 0 }, d, null));
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.album.albumCreate_Success))
              dispatch(setLoader(false))
              setPrimaryArtList([])
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }
      else {
        updateAlbum(albumData.album_id, formData)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              let d = []
              dispatch(fetchReleaseAlbum(userData.id, { limit: 25, offset: 0 }, d, null));
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.album.albumUpdate))
              dispatch(setLoader(false))
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }

      // Close the drawer
      handleClose();
    } else {
      // Handle validation errors or display a message
    }
  };

  const handleChangeMuliselect = (name, newValue, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        {
          artistSelectChangeHandler(artistCode.primary_artist, newValue, isNewArtist)
          break;
        }
      case artistCode.feature_artist:
        {
          artistSelectChangeHandler(artistCode.feature_artist, newValue, isNewArtist)
          break;
        }
        case artistCode.producer:
          {
              artistSelectChangeHandler(artistCode.producer, newValue, isNewArtist)
              break;
          }
      default:
        break;
    }

  };

  return (
    <Drawer
      sx={{
        width: '400px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: smallViewport ? '300px' : '400px',
          boxSizing: 'border-box',
          backgroundColor: '#ffffff',
        },
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <div>
        <div className="p-2 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <div style={{ marginLeft: '16px' }}><h4>{type !== 'edit' ? 'Add Album' : 'Edit Album'}</h4></div>
          <div style={{ position: 'relative', top: '-5px' }}>
            <button title="Close" className="icononly-btn" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className='p-4'>
        <div
            className="image-uploader "
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none', }}
              ref={fileInputRef}
            />
            <div onClick={() => fileInputRef.current.click()} className="audio-uploader custom-height ">
              <p className='text-primary'><UploadFileIcon style={{ height: '1rem', width: '1rem' }} /></p>
              <div className='d-flex'>
                <div style={{ marginRight: '10px' }}>
                  <span className='text-primary'>Click to upload</span>
                </div>
                <div>
                  <span>or drag and drop *</span>
                </div>
              </div>
              <p className='text-secondary' style={{ fontSize: '0.8rem' }}>JPG, JPEG, PNG (max. 10MB) with 3000x3000 pixels</p>
            </div>
            <div className="text-danger">
              {fileError}
            </div>
            {selectedFile && !fileSizeError && (
              <div className=" mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={type != 'edit' ? URL.createObjectURL(selectedFile) : albumData && albumData.cover_image !== selectedFile ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + selectedFile} alt="Selected" style={{ width: '14rem', height: '14rem' }} />
              </div>
            )}
          </div>
          <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="album_name"
              label="Album name*"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={albumName}
              onChange={inputChangeHandler}
              error={albumNameErr}
              helperText={albumNameErr ? albumNameErrMsg : null}
            />
          </div>
          {/* <div className='mb-3 mt-3'>
            <Autocomplete
              multiple
              id="primary-artist-input"
              size="small"
              options={artistList}
              onChange={(event, newValue) => handleChangeMuliselect(artistCode.primary_artist, newValue)}
              limitTags={2}
              getOptionLabel={(option) => option.artist_name}
              value={primaryArtList}
              renderInput={(params) => (
                <TextField {...params} name="primiary_artists" label="Primary Artist/Singer*" placeholder="Primary Artists" />
              )}
            />
          </div> */}
          {/* <div className='mb-3'>
            <Autocomplete
              multiple
              id="featuring-artist-input"
              size="small"
              options={artistList}
              onChange={(event, newValue) => handleChangeMuliselect(artistCode.feature_artist, newValue)}
              limitTags={2}
              getOptionLabel={(option) => option.artist_name}
              value={featureArtList}
              renderInput={(params) => (
                <TextField {...params} name="feature_artists" label="Featuring/Artist" placeholder="Featuring Artists" />
              )}
            />
          </div>
          <div className='mb-3'>
            <Autocomplete
              multiple
              id="featuring-artist-input"
              size="small"
              options={artistList}
              onChange={(event, newValue) => handleChangeMuliselect(artistCode.producer, newValue)}
              limitTags={2}
              getOptionLabel={(option) => option.artist_name}
              value={producerList}
              renderInput={(params) => (
                <TextField {...params} name="producer" label="Producer" placeholder="Producer" />
              )}
            />
          </div> */}
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Genre</InputLabel>
              <Select
                labelId="demo-single-checkbox-label"
                id="demo-single-checkbox"
                value={selectedGenres}
                onChange={handleGenreChange}
                input={<OutlinedInput label="Select Genre" />}
                renderValue={(selected) => genres.find(genre => genre.id === selected).genre}
                MenuProps={MenuProps}
              >
                {genres && genres.length > 0 && genres.map((genre) => (
                  <MenuItem key={genre.id} value={genre.id}>
                    <ListItemText primary={genre.genre} />
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </div>
          <div className='mt-4 d-flex justify-content-end '>
            <button className='gn-actionbtn' onClick={handleSubmit} disabled={fileInputRef?.current != null && fileInputRef?.current?.value && !disableBtn ? false : true}>Submit</button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default AlbumDrawer;
