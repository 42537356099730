
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function EnquiriesDetail() {
    const location = useLocation();
    const { data } = location.state;
    const history = useHistory();
    const backtoEnwuiry = () => {
        history.push('/enquires');
    }
    return (
        <div className="container-fluid py-4 creator-playlist-panel">
            <button className='gn-actionbtn' onClick={backtoEnwuiry}><ArrowBackIcon /> Back</button>
            <div className=' d-flex gap-2'>
                <div className="shadow-sm bg-white  border rounded-2 mt-4 w-50  ">
                    <div className="p-3">
                        <div>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 text-secondary">
                                    General</span>
                                <hr className="ms-2 w-100" />
                            </div>
                            <div>
                                <div className="value-pair color-secondory ">
                                    <strong>Name: </strong>
                                    <span className="text-capitalize">{data.first_name + " " + data.last_name}</span>
                                </div>
                                <div className="value-pair color-secondory ">
                                    <strong>Email: </strong>
                                    <span >{data.email}</span>
                                </div>
                                <div className="value-pair color-secondory ">
                                    <strong>Mobile Number: </strong>
                                    <span className="text-capitalize"> {data.mobile_number} </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='shadow-sm bg-white  border rounded-2 mt-4 w-50  '>
                    <div className='p-3'>
                        <div className="d-flex align-items-center">
                            <span className="fs-6 text-secondary">
                                Enquiry Message</span>
                            <hr className="ms-2 w-100" />
                        </div>
                        <div>
                            <div className="value-pair color-secondory ">
                                <strong>Message: </strong>
                                <span className=""> {data.message} </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}