import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Avatar, Box, Button, Card, CardContent, Checkbox, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Radio, Slider, Step, StepLabel, Stepper, styled, TextField, Typography } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Check } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { fetchStreamingPlatformList } from '../../../../redux';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RazorpayPayment from './PaymentGateway';
import ApiService from '../../../../_services/ApiService';
import SnackbarContainer from '../../../Snackbar';
import useSnackDispatcher from '../../../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';

const steps = ['Promote Song', 'Budget & Audience', 'Review', 'Payment'];
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));
function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const numbers = {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {completed ? (
                <Check />
            ) : (
                <div>{numbers[String(props.icon)]}</div>
            )}
        </ColorlibStepIconRoot>

    );
}
// const paymentMethods = [
//     { label: 'Other payment method', value: 'b', info: 'Add Payment method' },
//     // { label: 'Pay through wallet', value: 'a', info: '1000' },
// ];

export default function ArtistCampaign({ songData, setOpenMusicModal, openCampaignModal, setOpenCampaignModal }) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const dispatch = useDispatch()
    const streamingPlatform = useSelector((state) => state.streamingPlatform.streamingPlatform);
    const [activeStep, setActiveStep] = useState(0);
    const [stremingPlatform, setStremingPlatform] = useState([]);
    const heading1 = 'Select Platform where you want other artist to hear your song.'
    const subheading1 = 'Note- ensure that all the platform links are correct on your song micro-link'
    const heading2 = 'How many people are you planning to include?'
    const subheading2 = 'Keep in mind, the more people you invite, the higher the budget will be.'
    const heading3 = 'Everything’s awesome'
    const [artistCount, setArtistCount] = useState(0);
    const [artistAmount, setArtistAmount] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [nextDisable, setNextDisable] = useState(true);
    const [amounterror, setAmounterror] = useState(false);
    const [promotionData, setPromotionData] = useState([]);

    const reviewList = [
        { id: 1, img: process.env.REACT_APP_BASEURL_MEDIA + '/media/' + songData?.songPoster, name: songData?.songTitle, label: songData?.artists },
        { id: 2, img: '', name: 'Goal', label: 'Total no. of artist review' },
        { id: 3, img: '', name: 'Audience', label: 'Artist whose genre are similar to the song' },
        { id: 4, img: '', name: 'Amount', label: artistAmount + ' Rs/-' },
    ]

    useEffect(() => {
        if (promotionData) {
            setSelectedPlatform(promotionData?.platforms || [])
            setArtistCount(promotionData?.budget_amount || 0)
            setArtistAmount(promotionData?.number_of_artists || '')
        }
    }, [promotionData])

    useEffect(() => {
        if ((artistCount > 0 && artistAmount) || activeStep == 2) {
            setNextDisable(false)
        }
        //  else {
        //     setNextDisable(true)
        // }

    }, [activeStep, artistCount, artistAmount])

    const handleArtistChange = (event, newValue) => {
        setArtistCount(newValue);
    };

    useEffect(() => {
        dispatch(fetchStreamingPlatformList())
    }, [])

    useMemo(() => {
        if (streamingPlatform.length) {
            setStremingPlatform(streamingPlatform)
        }
    }, [streamingPlatform])

    const toggleDrawer = (newOpen) => () => {
        setOpenMusicModal(newOpen);
    };

    function handleNext() {
        if (activeStep == 2) {
            successSnack(formSnackbar.campaign.campaignPomotion_sucess)
            setOpenCampaignModal(false)
        } else {
            artistcampaignpromotion()
            setActiveStep((preActiveStep) => {
                setNextDisable(true)
                return preActiveStep + 1
            })
        }
    }

    function handlePrevious() {
        setActiveStep((preActiveStep) => {
            setNextDisable(false)
            return preActiveStep - 1
        })
    }

    // --------------- ONCHANGE AND ONCLICK HANDLER -----------

    const onchangeAmountHandler = (e) => {
        const { value } = e.target;
        setArtistAmount(value);
        if (e.type == 'blur') {
            if (value > 100000) {
                setNextDisable(true)
                setAmounterror(true);
            } else {
                setAmounterror(false);
            }
        }
    };

    const onchangePlatformHandler = (e, data) => {
        e.preventDefault();
        setNextDisable(false)
        setSelectedPlatform((prevSelectedPlatform) => {
            if (prevSelectedPlatform.includes(data.platformId)) {
                return prevSelectedPlatform.filter(id => id !== data.platformId);
            } else {
                return [...prevSelectedPlatform, data.platformId];
            }
        });

    }

    // ------------------ API PROMOTION -----------------

    function artistcampaignpromotion() {
        let postData = new FormData()
        let digits = artistAmount / artistCount;
        postData.append('song', songData.id)
        postData.append('platforms', selectedPlatform.join(','))
        postData.append('promotion', 'True')
        if ((artistCount > 0 && artistAmount) || activeStep == 2) {
            postData.append('number_of_artists', artistCount)
            postData.append('budget_amount', artistAmount)
            postData.append('per_person_budget', digits.toFixed(4))
        }
        if (artistAmount !== promotionData?.budget_amount || artistCount !== promotionData?.number_of_artists || JSON.stringify(selectedPlatform) !== JSON.stringify(promotionData?.platforms)) {
            ApiService.post('songPromotion', postData).then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setPromotionData(res?.data?.data[0]?.fields)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const headingCampaign = () => {
        return (
            <Box>
                <Typography gutterBottom variant="h6" component="div">
                    {activeStep == 0 ? heading1 : activeStep == 1 ? heading2 : activeStep == 2 ? heading3 : ''}
                </Typography>
                <Typography>
                    {activeStep == 0 ? subheading1 : activeStep == 1 ? subheading2 : ''}
                </Typography>
            </Box>
        )
    }

    return (
        <div>
            <Drawer PaperProps={{
                sx: {
                    width: '100%',
                    padding: '20px'
                },
            }}
                anchor={'right'}
                open={openCampaignModal}
                onClose={() => toggleDrawer(false)}>
                <Box className='d-flex align-items-center justify-content-between px-3'>
                    <IconButton aria-label="back" className='fs-5 gap-2 bg-transparent' onClick={() => setOpenCampaignModal(false)}>
                        <ArrowBackIcon />
                        {songData?.songTitle}
                    </IconButton>
                    <IconButton aria-label="close" onClick={() => setOpenCampaignModal(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}  {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Card sx={{ margin: '20px', height: '90vh', overflow: 'auto' }}>
                    <CardContent>
                        {headingCampaign()}
                        {activeStep == 0 ?
                            <List>
                                {stremingPlatform.length && stremingPlatform.map(d => (
                                    <ListItem key={d.platformId} disablePadding >
                                        <ListItemButton>
                                            <ListItemText primary={d?.platformName} />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedPlatform.includes(d.platformId)}
                                                    onChange={(e) => onchangePlatformHandler(e, d)}
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>))}
                            </List>
                            :
                            activeStep == 1 ?
                                <Box sx={{ width: "80%", marginTop: '30px' }}>
                                    {/* Number of artist Slider */}
                                    <Typography gutterBottom>Number of artist</Typography>
                                    <Slider
                                        value={artistCount}
                                        onChange={handleArtistChange}
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="auto"
                                        sx={{ color: '#ED1C24' }}
                                    />
                                    <Grid container justifyContent="space-between">
                                        <Typography variant="body2">{artistCount}</Typography>
                                        <Typography variant="body2">100</Typography>
                                    </Grid>

                                    {/* Amount Slider */}
                                    <Box marginTop={2}>
                                        <Typography gutterBottom>Amount</Typography>
                                        <TextField
                                            onChange={(e) => onchangeAmountHandler(e)}
                                            onBlur={(e) => onchangeAmountHandler(e)}
                                            placeholder="Amount"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            type="number"
                                            value={artistAmount}
                                            error={amounterror}
                                            helperText={amounterror ? "Amount cannot exceed 100,000" : ""}
                                        />

                                    </Box>

                                    {/* Per person budget section */}
                                    <Box marginTop={5}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    Per person budget
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Estimated daily review
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} textAlign="right">
                                                <Typography variant="body1" fontWeight="bold">
                                                    {artistAmount && artistCount ? artistAmount / artistCount : 0} Rs/-
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    10 reviews/day
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                :
                                activeStep == 2 ?
                                    <Box marginTop={3}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    {reviewList.map((data, index) => (
                                                        <ListItem key={index} alignItems="flex-start" sx={{ paddingTop: '0px', alignItems: 'center' }}>
                                                            {data?.img && <ListItemAvatar sx={{ width: '80px', height: '80px', marginRight: '10px' }}>
                                                                <img alt={data?.name || '-'} src={data?.img} className='w-100 rounded-1' />
                                                            </ListItemAvatar>}
                                                            <ListItemText
                                                                primary={data?.name || '-'}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            sx={{ color: 'text.primary', display: 'inline' }}
                                                                        >
                                                                        </Typography>
                                                                        {data?.label || '-'}
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>))}
                                                </List>
                                            </Grid>
                                            {/* {paymentMethods.map(({ label, value, info }) => (
                                                <React.Fragment key={value}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {label}
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ color: '#F14950', mt: 1 }}>
                                                            {info}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} textAlign="right">
                                                        <Radio
                                                            checked={selectedValue === value}
                                                            onChange={handleChange}
                                                            value={value}
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': value }}
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            ))} */}
                                        </Grid>
                                    </Box>
                                    :
                                    <Box >
                                        {/* <RazorpayPayment /> */}
                                        {/* Available Balance */}
                                        {/* <Typography variant="body1" fontWeight="bold">Available Balance</Typography>
                                        <Typography variant="h6" color="red" fontWeight="bold">
                                            1000
                                        </Typography> */}

                                        {/* Input for adding money */}
                                        {/* <Box marginTop={2}>
                                            <Typography variant="body1" fontWeight="bold">
                                                How much would you like to add?
                                            </Typography>
                                            <TextField
                                                placeholder="Add money"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                            />
                                        </Box> */}

                                        {/* Amount options */}
                                        {/* <Grid container spacing={2} marginTop={1}>
                                            {[100, 500, 1000, 2000].map((d) => (
                                                <Grid item key={d}>
                                                    <Button
                                                        variant={selectedAmount === d ? 'contained' : 'outlined'}
                                                        sx={{
                                                            backgroundColor: selectedAmount === d ? '#ED1C24' : 'transparent',
                                                            color: selectedAmount === d ? '#fff' : '#ED1C24',
                                                            borderColor: selectedAmount === d ? '#ED1C24' : '#928D95',
                                                            '&:hover': {
                                                                backgroundColor: selectedAmount === d ? '#D11A20' : 'transparent',
                                                                borderColor: '#ED1C24',
                                                            },
                                                        }}
                                                        onClick={() => handleAmountClick(d)}
                                                    >
                                                        {d}
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid> */}
                                    </Box>
                        }
                    </CardContent>

                </Card>
                <Box className='d-flex justify-content-between mx-3'>
                    <Button className='' onClick={handlePrevious} disabled={activeStep == 0} >Previous</Button>
                    <Button className='gn-actionbtn' onClick={handleNext} disabled={nextDisable || selectedPlatform.length == 0} >{activeStep == 2 ? 'Promote Song' : 'Next'}</Button>
                </Box>
            </Drawer>
            <SnackbarContainer />
        </div >
    );
}
