import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Links from "./link/link";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoginUser } from "../../redux/loginUser/loginUserActions";
import { fetchUserDetail } from "../../redux/user/userDataActions";
import ArtistBackgroundImg from "../../img/bg_image_texture4.png";
function Creater(props) {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.userData.userData);

    useEffect(
      function () {
        dispatch(fetchUserDetail());
        if (userData.id) {
          dispatch(fetchLoginUser(userData.id));
        }
      },
      [userData.id]
    );
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box>
            <Typography variant="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container-fluid py-2 vh-100 creator-link-panel">
      <div className="row m-0">
        <Box
          sx={{
           // width: "100%",
          //  height: "auto",
           // backgroundReapeat: "no-repeat",
           // backgroundSize: "contain",
           // backgroundImage: `url(${ArtistBackgroundImg})`,
           // background:`linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), url(${ArtistBackgroundImg})`,
          }}
        >
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}

          {/* <Tab label="Setting" {...a11yProps(1)} /> */}

          {/* </Box> */}
          <TabPanel value={value} index={0}>
            <Links />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            Setting
          </TabPanel> */}
        </Box>
      </div>
    </div>
  );
}

export default Creater;
