import React from 'react'
import { useState } from "react";
import SnackbarContainer from '../Snackbar';
import AssociateArtistList from './AssociateArtistList';
import AddAssociateArtist from './AddAssociateArtist';

function AssociateArtistIndex({getAssociatedArtist}) 
{
    const [associate, setAssociate] = useState(true);
   
    function handleChange() 
    {
      setAssociate(!associate)
    }
 
  return (
  <>
    {associate ? <AssociateArtistList handleChange={handleChange} /> : <AddAssociateArtist getAssociatedArtist={getAssociatedArtist} handleChange ={handleChange}/> }
    <SnackbarContainer/>
  </>
  )
}
export default AssociateArtistIndex