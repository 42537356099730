import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRazorpay from "react-razorpay";

const RazorpayPayment = () => {
    const userData = useSelector((state) => state.userData.userData);
    const [Razorpay, isLoaded] = useRazorpay();

    const handleRazorPayment = React.useCallback(async () => {
        try {
            const options = {
                key: 'rzp_test_1iDJqOZBegGvRh', // Test Key
                amount: 100, // Amount in paise
                currency: "INR",
                name: "GNTunes",
                description: "Paying for Song Promotion",
                // image: process.env.REACT_APP_SONGAPIURL_MEDIA + "/media/media/logoGntunes.svg",
                // order_id: order,
                // callback_url: `${process.env.REACT_APP_CALLBACK_URL}?album=${paramsData?.release?.album_id}&planId=${planId}`,
                redirect: true,
                allow_rotation: true,
                prefill: {
                    name: userData.studio_name,
                    email: userData.email,
                    contact: userData.phone_number,
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#C61414",
                },
                modal: {
                    ondismiss: function () {
                        // Handle payment dismissal (optional)
                        console.log("Payment modal dismissed");
                    },
                },
            };

            const rzpay = new Razorpay(options);
            rzpay.open();
        } catch (error) {
            console.error("Error processing payment:", error);
            // Handle payment errors gracefully (e.g., display an error message to the user)
        }
    }, [Razorpay]);

    useEffect(()=>{
        handleRazorPayment()
    },[])

};

export default RazorpayPayment;
