import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { Card, CardContent, IconButton } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchLoginUser, fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import SnackbarContainer from '../Snackbar';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import DeleteModal from '../../components/reusableComponents/DeleteModal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'Song Name',
    label: 'Song Name',
    width:250,
  },
  {
    id: 'Describe',
    label: 'Describe',
    width:250,
  },
  {
    id:'Play',
    label:'Play',
    width:250,
  },
  {
    id: 'Action',
    label: 'Action',
    width:250,
  },
 
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>  
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function DemoDataTable({dataList,getDataList}) {
  /* for delete confirmation box:start */
  const ref = React.useRef();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rows,setRows]=useState(dataList?dataList:[]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const userData = useSelector((state) => state.userData.userData);
  const [songDemo, setSongDemo] = useState()
  const path='https://gncreatorsdev.gncreators.com/media'

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const removeData=(data)=>
  {
    if(data!==undefined )
    {
      dispatch(setSnackBarVariant('info'))
      dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDelete))
      setTimeout(()=>dispatch(setSnackBar(false)),2000)
      dispatch(setSnackBar(true))
      let itemId  = data;
      submitDemo({'itemId':itemId},'DELETE').then(function (response) 
      {
          if(response.status ===200 || response.status ===204) 
          {
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDeleteSuccess))
            dispatch(setLoader(false))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            getDataList(); // reset the demo data
            setSelected([]);

          }
          else
          {
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
              dispatch(setSnackBarVariant('error'))
          }  
      })   
    }  
 
  }


  const handleDeleteItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
 
   /* if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    } */

    //removeData(newSelected[0])
    /* if (selectedIndex === -1) {
      newSelected = newSelected.concat(['Tanya'], id);
      console.log('219',newSelected)
    } */
    setSelected(id);
    removeData(id)
  
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  return (
    <div>
    <Card sx={{ minWidth: '100%', height:'auto', boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.18)',    borderRadius:'6px'}}>        
        <CardContent sx={{padding:'0px !important'}}>
          <TableContainer className='p-2'>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length?selected.length:0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                      return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left"  >{row.audio_name}</TableCell>
                        <TableCell align="left" >{row.description}</TableCell>
                        <TableCell align="left" width="10px" ><audio src={row.audio_clip?`${process.env.REACT_APP_BASEURL_MEDIA}/media/${row.audio_clip}`:''}
                        controls><source  type="audio/mp3" />  </audio> </TableCell>
                        <TableCell align="left" ><div className='text-secondary'>
                          <IconButton /* onClick={(event) => {handleClick(event, row.id);}} */ onClick={()=>ref.current.handleOpen()}><DeleteIcon/></IconButton></div></TableCell>
                          <DeleteModal cardId={row && row.id} ref={ref} deleteHandler={handleDeleteItem}/>
                      </TableRow>
                    );
                  })}
              </TableBody>
          </Table>
          </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </CardContent>
    </Card>
    </div>
  );
}