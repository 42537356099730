
import React, { useState, useEffect,useRef } from 'react';
import { Add } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import { Edit } from '@mui/icons-material'
import AddExperience from './addExperience';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import { getExperience } from '../../_services/profile/getExperience.service'
import { deleteExperience } from '../../_services/profile/deleteExperience.service'
import { fetchUserDetail } from '../../redux/user/userDataActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExperienceCard from './ExperienceCard';
import { useMediaQuery } from '@mui/material';

function Experience(props) {
    const userData = useSelector((state) => state.userData.userData);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    var [experienceData, setExperience] = useState([]);
    const [actionType, setActionType] = useState(null) //to handle the edit and add within the same drawer
    const [currentRowData, setCurrentRowData] = useState(null)
    let xsScreen = useMediaQuery('(max-width:280px)')

    useEffect(function () {
        if(userData.id){
            getExperienceHandler();
        }
    }, [userData.id]);

    const getExperienceHandler = ()=>{
        if(userData.id){
            getExperience(userData.id).then(function (response) {
                dispatch(setLoader(false))
                setExperience(response.data.data)
            }).catch(function (error) {
                dispatch(setLoader(false))
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        }
    }

    //to open the drawer on experience edit
    function handleDrawerOpen(itemId) {
        const selectedItem = experienceData.find(item => item.id === itemId); // to get the current experince data from the experince object
        setOpen(true);
        setCurrentRowData(selectedItem)
        setActionType('edit')
        }

    function onDeleteHandler(id) {
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBarMessage(formSnackbar.PRE_EXPERIENCE_DELETE))
        dispatch(setSnackBar(true))
        deleteExperience(id).then(function (response) {
            dispatch(setLoader(false)) 
            if(response.status === 200 || response.status === 204){
                dispatch(setSnackBarVariant('success'))
                dispatch(setSnackBarMessage(formSnackbar.POST_EXPERIENCE_DELETE))
                setTimeout(() =>  dispatch(setSnackBar(false)), 2000)
                dispatch(setLoader(false))
                getExperienceHandler()
            }
            else
            {
                dispatch(setSnackBarVariant('error'))
                dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
            }
        })
      
    }
   

    return (
        <div>
            <div className=' bg-rgb(249 249 249)'>
                <div className='d-flex justify-content-between'> 
                <div>
                    <h4 className='mb-2'>Experience</h4>
                </div>
                <div>
                {!xsScreen?
                        <button title='Add Experience' className="gn-actionbtn" onClick={() =>{ setOpen(true);setActionType(null)}}>
                            <div className='d-flex'>
                                <span> <Add /> </span>
                                <span style={{display:'flex',alignItems:'center'}}>EXPERIENCE</span>
                            </div>
                        </button>:
                        <button className="icononly-btn" onClick={() =>{ setOpen(true);setActionType(null)}}>
                            <Add />
                        </button>  

                }     
                </div>

                </div> 
                <div className='text-secondary'><p>  Add your past work experience</p></div>

                   
                    </div>
                    <div >
                        {experienceData ? experienceData.map((item,index) => (
                            <ExperienceCard key={`expCard_${item.id}`}cardData={item} onDeleteHandler={onDeleteHandler} handleDrawerOpen={handleDrawerOpen}/>
                        )) : null}
                        
                        <AddExperience open={open} setOpen={setOpen} getExperienceHandler={getExperienceHandler} actionType={actionType} currentRowData={currentRowData} experienceData={experienceData} setCurrentRowData={setCurrentRowData}/>
                    </div>
              
        </div>
    );
}

export default Experience;